export const LINKS = {
  //external
  kpContactUs: "https://knowledge-pillars.com/contact-us/",
  shop: "https://knowledge-pillars.com/shop/",
  //
  main: "/",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  updateUserAccount: "/update-user-account",
  authWithSSO: "/auth",
  authWithMasteryCoding: "/auth/mastery-coding",
  //
  activeExamInfo: "/user/active-exam-info",
  examsOnboard: "/user/exams-onboard",
  examsSelection: "/user/exams-selection",
  examsConfirmPrivacy: "/user/exams-confirm-privacy",
  examsPaymentMethod: "/user/exams-payment-method",
  examsSelectGroup: "/user/exams-select-group",
  examsVerify: "/user/exams-verify",
  examsVoucher: "/user/exams-voucher",
  examsInfo: "/user/exams-info",
  examsProctorVerifi: "/user/exams-verify-proctor",
  examWindow: "/user/examination-window",
  //
  adminPanelDashboard: "/admin/panel/dashboard",
  adminPanelOrgan: "/admin/panel/organization-profile",
  adminPanelExamGroup: "/admin/panel/exam-groups",
  adminPanelLicense: "/admin/panel/license",
  adminPanelVouchers: "/admin/panel/vouchers",
  adminPanelReports: "/admin/panel/reports",
  adminPanelProctors: "/admin/panel/proctors",
  adminPanelSettings: "/admin/panel/settings",
  //
  faqs: "/faqs",
  privacyPolicy: "/privacy-policy",
  termsAndCond: "/terms-and-conditions",
  results: "/results",
  //

  smeSelectAction: "/sme",
  smeExams: "/sme/exams",
  smeTasksList: "/sme/tasks",
  smeReviewTasks:"/sme/tasks/review",
  smeScoreTasks:"/sme/tasks/score",
  // new tasks routes. Delete old, if ok
  smeScoreTaskItem: "/sme/tasks/score/:examCode/:questionId",
  smeReviewTaskItem: "/sme/tasks/review/:examCode",
  smeDynamicTasksList: "/sme/tasks/list/:selectedOption/:examCode"
}
