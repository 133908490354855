import React, { useState, useEffect, useCallback } from "react";

import { groupModal } from "./styles";

import { checkSelect, groupsKeys } from "utils/validation";

import { TableSelect as CustomSelect } from "components/Inputs";
import { Typography, Grid, TextField } from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { format, add } from "date-fns";

const selectStatusOpt = [
  {
    value: "active",
    label: "Active"
  },
  {
    value: "inactive",
    label: "Inactive"
  }
];

const selectGroupOpt = [
  {
    value: "Offline",
    label: "Examus Offline proctoring"
  },
  {
    value: "Onsite",
    label: "Onsite proctoring"
  },
  {
    value: "LiveProctoring",
    label: "Examus Live proctoring"
  },
  {
    value: "LiveOwnProctoring",
    label: "Examus Live proctoring - own proctors"
  }
];

const EXAM_DURATION = 45;

const GroupUpperSection = (props) => {
  const { handleChange, group, mode, handleClose, attempt } = props;

  const [bufferedEndTime, setBufferedEndTime] = useState(null);
  const [reload, setReload] = useState(false);

  const classes = groupModal(group.asyncProctoring);

  const formatDate = useCallback((date) => {
    return `${format(new Date(date), "yyyy-MM-dd")}T${format(
      new Date(date),
      "H:mm"
    )}`;
  }, []);

  const setAndSubmitTime = useCallback(
    (e) => {
      e.preventDefault();

      handleChange({
        target: { name: "startTime", value: e.target.value }
      });

      handleChange({
        target: {
          name: "endTime",
          value: add(new Date(e.target.value), { minutes: EXAM_DURATION })
        }
      });

      setBufferedEndTime(() =>
        formatDate(add(new Date(e.target.value), { minutes: EXAM_DURATION }))
      );
    },

    [handleChange, formatDate, setBufferedEndTime]
  );

  useEffect(() => {
    setReload(() => true);
    setTimeout(() => setReload(() => false), 1);
  }, [bufferedEndTime]);

  return (
    <>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={6} style={{ textAlign: "left" }}>
          <Typography component="h1" variant="h5">
            {mode === "add" ? "Create Exam Group" : "Edit Exam Group"}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <HighlightOffIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={3} sm={3}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Group Name:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.select}
            name="name"
            placeholder="group name"
            autoFocus
            value={group.name}
            style={{ width: "266px" }}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            helperText={
              attempt && checkSelect(group[groupsKeys.GROUP_NAME.key])
                ? checkSelect(group[groupsKeys.GROUP_NAME.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.GROUP_NAME.key])}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={3} sm={2}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Status:{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            className={classes.select}
            variant="outlined"
            align={"center"}
            name="status"
            label="select"
            value={group.status}
            handleChange={(e) => {
              handleChange(e);
            }}
            arrSelectOptions={selectStatusOpt}
            helperText={
              attempt && checkSelect(group[groupsKeys.STATUS.key])
                ? checkSelect(group[groupsKeys.STATUS.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.STATUS.key])}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={3} sm={3}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Proctoring:{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            className={classes.select}
            variant="outlined"
            align={"center"}
            name="typeProctoring"
            label="select"
            value={group.typeProctoring}
            handleChange={(e) => {
              handleChange(e);
            }}
            arrSelectOptions={selectGroupOpt}
            helperText={
              attempt && checkSelect(group[groupsKeys.PROCTORING.key])
                ? checkSelect(group[groupsKeys.PROCTORING.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.PROCTORING.key])}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
        className={classes.time}
      >
        <Grid item xs={3} sm={2}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Start Time:{" "}
          </Typography>
        </Grid>
        <Grid item className={classes.datePicker}>
          <TextField
            id="datetime-local"
            label="Next appointment"
            type="datetime-local"
            defaultValue={
              mode === "add"
                ? formatDate(new Date())
                : formatDate(group.startTime)
            }
            onChange={(e) => setAndSubmitTime(e)}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
      />

      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems="center"
        justifyContent="flex-start"
        className={classes.time}
      >
        <Grid item xs={3} sm={2}></Grid>
        <Grid item className={classes.datePicker}>
          {reload ? (
            <Grid item style={{ minHeight: "48px" }} />
          ) : (
            <TextField
              id="datetime-local"
              label="End Time:"
              type="datetime-local"
              defaultValue={
                bufferedEndTime
                  ? bufferedEndTime
                  : formatDate(
                      group.endTime
                        ? group.endTime
                        : add(new Date(), {
                            minutes: EXAM_DURATION
                          })
                    )
              }
              disabled
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GroupUpperSection;
