/* eslint-disable no-use-before-define */
import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export default function SelectAutocomplete({
  value,
  handleChange,
  helperText,
  className,
  id = "standard-select-currency",
  label = "",
  autoComplete = "off",
  align = "left",
  arrSelectOptions,
  variant = "outlined",
  margin,
  error
}) {
  return (
    <Autocomplete
      options={arrSelectOptions}
      onChange={handleChange}
      defaultValue={arrSelectOptions.find((el) => el.value === value)}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          margin={margin}
          helperText={helperText}
          error={error}
          className={className}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);

        return (
          <div>
            {parts?.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
}
