import React from 'react';
// plugins
import { Droppable } from 'react-beautiful-dnd';
// components
import CodeList from '../CodeList/CodeList';
// styles
import './Column.styles.css';

// component start
const Column = ({ column, codeLines }) => {

  // render one column
  return (
    <div className={`kp-pqcol-container par-col-container`}>
      <h2 className={`kp-pqcol-title par-col-title`}>{column.title}</h2>
      <Droppable
        droppableId={column.id}
      >
        {(provided, snapshot) => (
          <CodeList
            codeLines={codeLines}
            provided={provided}
            snapshot={snapshot}
            columnId={column.id}
          />
        )}
      </Droppable>
    </div>
  );
};

export default Column;