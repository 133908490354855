import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import { CustomTable } from "../../../components";
import {
  fetchVouchers,
  updateVoucherStatus
} from "../../../store/slices/vouchers";

const selectOpt = [
  {
    value: "active",
    label: "Active"
  },
  {
    value: "inactive",
    label: "Inactive"
  }
];

const columns = [
  { id: "code", label: "Voucher Code", minWidth: 100, align: "center" },
  { id: "description", label: "Description", minWidth: 100, align: "center" },
  {
    id: "select",
    label: "Status",
    minWidth: 120,
    align: "center"
  },
  { id: "examCode", label: "Exam", minWidth: 100, align: "center" }
];

const useStyles = makeStyles({
  rootRow: {
    maxWidth: "100%"
  },
  title: {
    padding: "0 0 1rem"
  }
});

function Vouchers() {
  const classes = useStyles();
  const { allVoucher } = useSelector((state) => state.vouchersData.vouchers);
  const { loading: statusUpdating } = useSelector(
    (state) => state.vouchersData.currentVoucher
  );
  const [voucherIdChanging, setVoucherIdChanging] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchVouchers());
  }, [dispatch]);

  const handleChangeStatus = (event, id) => {
    const status = event.target.value;
    setVoucherIdChanging(id);
    dispatch(updateVoucherStatus({ id, status })).then(() => {
      dispatch(fetchVouchers());
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={7}
      className={classes.rootRow}
    >
      <Grid item container direction="column" md={8} sm={12} xl={6}>
        <Typography variant="h5" className={classes.title}>
          Vouchers:
        </Typography>
        <CustomTable
          idChanging={voucherIdChanging}
          statusUpdating={statusUpdating}
          rows={allVoucher}
          columns={columns}
          handleChangeSelect={handleChangeStatus}
          selectOpt={selectOpt}
          className={classes.rootTable}
        />
      </Grid>
    </Grid>
  );
}

export default Vouchers;
