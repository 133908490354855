import React, {memo, useState, useCallback, useEffect} from "react"
import {makeStyles} from "@mui/styles"
import {useNavigate} from "react-router-dom"

import {
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material"

import DashboardIcon from "@mui/icons-material/Dashboard"
import RecentActorsIcon from "@mui/icons-material/RecentActors"
import GroupWorkIcon from "@mui/icons-material/GroupWork"
import DescriptionIcon from "@mui/icons-material/Description"
import PostAddIcon from "@mui/icons-material/PostAdd"
import AssessmentIcon from "@mui/icons-material/Assessment"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import SettingsIcon from "@mui/icons-material/Settings"

import {LINKS} from "../../constants/links"

const drawerWidth = 240

const sideBarItems = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    link: LINKS.adminPanelDashboard
  },
  {
    name: "Organization Profile",
    icon: <RecentActorsIcon />,
    link: LINKS.adminPanelOrgan
  },
  {
    name: "Exam Groups",
    icon: <GroupWorkIcon />,
    link: LINKS.adminPanelExamGroup
  },
  {
    name: "Licenses",
    icon: <DescriptionIcon />,
    link: LINKS.adminPanelLicense
  },
  {name: "Vouchers", icon: <PostAddIcon />, link: LINKS.adminPanelVouchers},
  {name: "Reports", icon: <AssessmentIcon />, link: LINKS.adminPanelReports},
  {
    name: "Proctors",
    icon: <SupervisorAccountIcon />,
    link: LINKS.adminPanelProctors
  },
  {name: "Settings", icon: <SettingsIcon />, link: LINKS.adminPanelSettings}
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative"
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
    paddingTop: "1rem"
  },
  drawerContainer: {
    overflow: "auto"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  activeListItem: {
    backgroundColor: "#0f75bc3b",
    fontWeight: "600 !important",
    "&:hover": {
      backgroundColor: "#0f95bc7b"
    }
  }
}))

function AdminPanel({children}) {
  const classes = useStyles()
  const [barItems, setBarItems] = useState(sideBarItems)
  const navigate = useNavigate()

  useEffect(() => {
    const updatedActiveItem = barItems.map((item) => {
      if (item.link === history.location.pathname) {
        return {...item, isActive: true}
      }
      return item
    })
    setBarItems(updatedActiveItem)
  }, [barItems, history.location.pathname])

  const redirectToChild = useCallback(
    (index) => {
      navigate(sideBarItems[index].link)
    },
    []
  )

  const handleClick = useCallback(
    (index) => {
      const arr = barItems.map((it, ind) => {
        if (ind === index) {
          return {...it, isActive: true}
        } else {
          return {...it, isActive: false}
        }
      })
      redirectToChild(index)
      setBarItems(arr)
    },
    [barItems, redirectToChild]
  )

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {barItems.map((item, index) => (
              <ListItem
                button
                key={item.name}
                onClick={() => handleClick(index)}
                className={item.isActive && classes.activeListItem}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  )
}

export default memo(AdminPanel)
