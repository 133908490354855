import React from 'react';

const IconItem = ({ iconName, onClick }) => {

  const iconChooser = () => {
    switch (iconName) {
      case 'ChevronRightIcon':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
               role="img"
               viewBox="0 0 320 512"
               className="svg-inline--fa fa-chevron-right fa-w-10 fa-3x kps-collapse-icon">
            <path fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  className=""/>
          </svg>
        );
      case 'ChevronRightSpace':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
               role="img"
               viewBox="0 0 320 512"
               className="svg-inline--fa fa-chevron-right fa-w-10 fa-3x kps-pqcl-space-button par-b-sp-btn par-b-left-right">
            <path fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  className=""/>
          </svg>
        );
      case 'ArrowBackIcon':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
               role="img"
               viewBox="0 0 448 512"
               className="svg-inline--fa fa-arrow-left fa-w-14 fa-2x kps-hide-icon">
            <path fill="currentColor"
                  d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
                  className=""/>
          </svg>
        );
      case 'PlayArrowOutlinedIcon':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" role="img"
               viewBox="0 0 448 512" className="svg-inline--fa fa-play fa-w-14 fa-2x">
            <path fill="currentColor"
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                  className=""/>
          </svg>
        );
      case 'KeyboardArrowDownIcon':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-down"
               role="img"
               viewBox="0 0 448 512"
               className="svg-inline--fa fa-arrow-down fa-w-14 fa-2x kps-collapse-icon kp-ans-col-icon">
            <path fill="currentColor"
                  d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
                  className=""/>
          </svg>
        );
      case 'FullscreenIcon':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="expand" role="img"
               viewBox="0 0 448 512"
               className="svg-inline--fa fa-expand fa-w-14 fa-2x kps-full-icon ">
            <path fill="currentColor"
                  d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
                  className=""/>
          </svg>
        );
      case 'ChevronLeft':
        return (
          <svg onClick={onClick} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left"
               role="img"
               viewBox="0 0 320 512"
               className="svg-inline--fa fa-chevron-left fa-w-10 fa-2x kps-pqcl-space-button par-b-sp-btn par-b-left-icon">
            <path fill="currentColor"
                  d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                  className=""/>
          </svg>
        );
    }
  };
  return (
    <React.Fragment>
      {iconChooser()}
    </React.Fragment>
  );
};

export default IconItem;