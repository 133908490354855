import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles/ReviewTasks.styles";
import { useQuestionIndex } from "../../hooks/tasksHooks";
// actions
import { fetchTask, taskEvaluationReview } from "../../store/slices/sme";
// components
import ReviewQuestionForm from "./components/ReviewQuestionForm";
import { BackdropLoad } from "../../components";
// private npm package
import {
  MultipleChoiceQuestion,
  KpEngine,
  DnDQuestion
} from "@knowledge-pillars-education-inc/kp-fe-lib";
import BackButton from "../../components/Buttons/BackButton";
import CustomPagination from "../../components/CustomPagination";
import useKpEditor from "../../hooks/tasksHooks/useKpEditor";
import ParsonsQuestion from "../../parsonsTest/ParsonsQuestion";
import { getSyntaxLanguage } from "utils/syntax-highlighter";

function ReviewTasks() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const urlParams = useParams();

  // redux state variables
  const { loading, questions, question, selectedExam } = useSelector(
    (state) => {
      const { smeSlice } = state;
      const { selectedQuestion, examQuestions, loading, selectedExam } =
        smeSlice;
      return {
        loading,
        questions: examQuestions,
        question: selectedQuestion,
        selectedExam
      };
    }
  );

  // ! KpEngine variables
  const { compileAnswer, runDisabled, onRunCode } = useKpEditor(question);

  // ! variables to avoid errors in question type, if data structure changed
  const QuestionCodeString = useMemo(() => {
    if (!question?.multipleDetails?.codeSnippet) return "";
    return question?.multipleDetails?.codeSnippet["code"];
  }, [question]);

  const [feedback, setFeedback] = useState(null);
  const [comments, setComments] = useState(null);

  // current question index
  const index = useQuestionIndex(questions, question);

  useEffect(() => {
    // go away if reloaded
    if (!question) {
      navigate("/sme/", {replace: true});
      return;
    }

    setFeedback(question.feedback);
    setComments(question.comment);
  }, [dispatch, question]);

  const onNextButtonClick = async () => {
    if (index >= questions?.length - 1) return navigate("/sme", {replace: true});
    await dispatch(fetchTask(questions[index + 1].id));
  };

  const onPreviousButtonClick = async () => {
    await dispatch(fetchTask(questions[index - 1].id));
  };

  const handleCommentChange = useCallback((comment) => {
    setComments(comment);
  }, []);

  const onCommentInputBlur = () => {
    dispatch(
      taskEvaluationReview({ taskCode: question?.code, comments, feedback })
    );
  };

  const handleRatingChange = useCallback(
    (rating) => {
      setFeedback(rating);
      dispatch(
        taskEvaluationReview({
          taskCode: question?.code,
          comments,
          feedback: rating
        })
      );
    },
    [comments, dispatch, question]
  );

  const handleBackToQuestions = useCallback(() => {
    navigate(`/sme/tasks/list/ReviewQuestions/${urlParams.examCode}`);
  }, [ urlParams.examCode]);

  const MultipleChoicesHandler = (changedOptions) => {
    // console.log(changedOptions);
  };

  const changesHandler = useCallback((dndAnswers) => {
    // dispatch(setMultiTasksAnswers(dndAnswers));
  }, []);

  const ParsonsCodeChanges = useCallback((code) => {}, []);

  const DndQuestionPlugin = useMemo(() => {
    return (
      <DnDQuestion
        questionLines={question?.questions}
        answerLines={question?.answers}
        description={question?.description}
        displayName={question?.displayName}
        changesHandler={changesHandler}
      />
    );
  }, [
    changesHandler,
    question?.answers,
    question?.description,
    question?.displayName,
    question?.questions
  ]);

  const ParsonsQuestionPlugin = useMemo(() => {
    return (
      <div className={classes.parsonsWrapper}>
        <ParsonsQuestion
          questionLines={question?.answers}
          description={question?.description}
          displayName={question?.displayName}
          codeSnippet={question?.multipleDetails?.codeSnippet?.code || null}
          codeSnippetLanguage={getSyntaxLanguage(
            question?.multipleDetails?.codeSnippet?.language
          )}
          language="javascript"
          handleCodeChanges={ParsonsCodeChanges}
        />
      </div>
    );
  }, [
    classes.parsonsWrapper,
    question?.answers,
    question?.description,
    question?.displayName,
    ParsonsCodeChanges
  ]);

  const getTaskContainer = () => {
    if (question?.problem) {
      return (
        <div className={classes.kpsphereWrap}>
          <KpEngine
            displayName={question.displayName}
            editorLanguage="python"
            infoLanguage="js"
            codeEditorData={question?.practicalDetails?.template || " "}
            infoEditorData={question.problem.body}
            onRunCode={onRunCode}
            compileData={compileAnswer}
            runDisabled={runDisabled}
          />
        </div>
      );
    }

    switch (question?.type) {
      case "multiple":
        return (
          <div className={classes.multipleScoreWrapper}>
            <MultipleChoiceQuestion
              onChange={MultipleChoicesHandler}
              loading={loading}
              codeString={QuestionCodeString}
              options={question?.multipleDetails?.options}
              language={question?.multipleDetails?.codeSnippet?.language}
              description={question?.description}
            />
          </div>
        );
      case "parsons":
        return ParsonsQuestionPlugin;
      case "match":
        return DndQuestionPlugin;
      default:
        return <div>loading...</div>;
    }
  };

  return (
    <>
      {loading && <BackdropLoad open={loading} />}

      <BackButton
        buttonLabel="All questions"
        handleBackClick={handleBackToQuestions}
      />

      {!loading && question && (
        <div className={classes.body}>
          <Grid container className={classes.reviewContainer}>
            <Grid item xs={12} md={8}>
              {getTaskContainer()}
            </Grid>

            <Grid item xs={12} md={4} className={classes.review}>
              <Typography className={classes.title} variant="h5">
                Question review -{" "}
                {selectedExam?.examDisplayCode || selectedExam?.examCode || ""}
              </Typography>
              <Divider className={classes.spacing} />

              <ReviewQuestionForm
                onCommentChange={handleCommentChange}
                onRatingChange={handleRatingChange}
                onCommentBlur={onCommentInputBlur}
                question={question}
              />

              <CustomPagination
                isLastQuestion={index >= questions?.length - 1}
                isFirstQuestion={index <= 0}
                onPreviousButtonClick={onPreviousButtonClick}
                onNextButtonClick={onNextButtonClick}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default ReviewTasks;
