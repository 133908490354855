import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    minHeight: '500px',
    paddingBottom: '15px',

  },
  parsonsWrapper: {
    margin: '20px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    height: '100%',
    '& .kps-dnd-container': {
      padding: '16px'
    },

  },
  multipleScoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    height: '100%',
    width: '95%',

    '& .mqfs-wrap': {
      flexGrow: 1,
    },
    '& .multiple-container': {
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  kpsphereWrap: {
    height: 'calc(100vh - 156px)',
    padding: '0 10px'
  },
  questionContainer: {
    minHeight: '500px',
    paddingBottom: '15px',
  },
  review: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexFlow: 'column wrap',
    display: 'flex',
  },
  spacing: {
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    '& button': {
      width: 'calc((100% - 20px)/2)',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '12px',
      '& button': {
        '&:first-child': {
          marginRight: '10px',
        },
      },
    },
  },
  icon: {
    width: 18,
    height: 18
  }
}));
