import React, { memo, useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { TableSelect as CustomSelect } from "../Inputs";
import Skeleton from "@mui/lab/Skeleton";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  CircularProgress,
  Checkbox
} from "@mui/material";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: ({ containerHeight }) => containerHeight
  },
  labelRow: {
    backgroundColor: "rgb(198, 222, 239)",
    fontWeight: 600
  },
  cursorHover: {
    cursor: "pointer"
  },
  skeleton: {
    margin: "0.5rem 0"
  }
});

function CustomTable({
  rows,
  columns,
  className,
  tableClass,
  pointerOff = false,
  columnsOff = false,
  handleClickRow = () => {},
  containerHeight = 440,
  //select
  selectValue,
  handleChangeSelect,
  selectOpt,
  //btn
  variant = "contained",
  btnClassName,
  btnColor,
  handleClickBtn,
  statusUpdating,
  idChanging,
  //checkbox
  hadleClickCheckbox,
  disabledCheckBox = false
}) {
  const classes = useStyles({ containerHeight });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setPage]
  );

  return (
    <Paper className={classNames(classes.root, className)}>
      {!rows || !rows.length ? (
        <>
          <Skeleton
            animation="wave"
            variant="rect"
            height={25}
            className={classes.skeleton}
          />
          <Skeleton
            animation="wave"
            variant="rect"
            height={20}
            className={classes.skeleton}
          />
          <Skeleton variant="rect" height={150} className={classes.skeleton} />
        </>
      ) : (
        <TableContainer className={classNames(classes.container, tableClass)}>
          <Table stickyHeader aria-label="sticky table">
            {!columnsOff && (
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={column.id + i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.labelRow}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {!!rows &&
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, indRow) => {
                    return (
                      <TableRow
                        className={!pointerOff ? classes.cursorHover : null}
                        hover={!pointerOff ? true : false}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code + indRow}
                        onClick={(e) => handleClickRow(e, row.id)}
                      >
                        {columns.map((column, i) => {
                          const value = row[column.id];
                          //btn section

                          if (column.id.substr(0, 6) === "button") {
                            return (
                              <TableCell
                                key={column.id + i}
                                align={column.align}
                              >
                                <Button
                                  variant={variant}
                                  color={btnColor}
                                  className={classNames(
                                    btnClassName,
                                    column.className
                                  )}
                                  onClick={() =>
                                    handleClickBtn(indRow, value, row)
                                  }
                                >
                                  {row[column.id]}
                                </Button>
                              </TableCell>
                            );
                          }
                          if (column.id === "checkbox") {
                            return (
                              <TableCell
                                key={column.id + i}
                                align={column.align}
                              >
                                <Checkbox
                                  disabled={disabledCheckBox}
                                  checked={row.asyncProctoring}
                                  color="primary"
                                  onChange={() => hadleClickCheckbox(indRow)}
                                  inputProps={{
                                    "aria-label": "secondary checkbox"
                                  }}
                                />
                              </TableCell>
                            );
                          }
                          //select section
                          if (column.id.substr(0, 6) === "select") {
                            return statusUpdating && idChanging === row.id ? (
                              <TableCell
                                TableCell
                                align="center"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <CircularProgress disableShrink size={27} />
                              </TableCell>
                            ) : (
                              <TableCell
                                align={column.align}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {row.status !== "used" ? (
                                  <CustomSelect
                                    align={column.align}
                                    value={row.status}
                                    handleChange={(e) =>
                                      handleChangeSelect(e, row.id)
                                    }
                                    arrSelectOptions={selectOpt}
                                  />
                                ) : (
                                  <CustomSelect
                                    align={column.align}
                                    value={row.status}
                                    disabled={true}
                                    handleChange={() => {}}
                                    arrSelectOptions={[
                                      {
                                        value: "used",
                                        label: "Used"
                                      }
                                    ]}
                                  />
                                )}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id + i} align={column.align}>
                              {Array.isArray(value) ? value.length : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {rows && rows.length > 9 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}

export default memo(CustomTable);
