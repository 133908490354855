import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { useStyles } from "../styles/ReviewQuestionsForm.styles";
import { ObjectiveInfo } from "../../../components/Cards/ObjectiveInfo";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

function ReviewQuestionForm({
  onRatingChange,
  onCommentChange,
  onCommentBlur,
  question
}) {
  const classes = useStyles();
  const [ratingValue, setValueRating] = useState(question.feedback);
  const [commentValue, setComment] = useState(question.comment);

  const smeSlice = useSelector((state) => state.smeSlice);

  useEffect(() => {
    const { selectedQuestion } = smeSlice;
    setValueRating(selectedQuestion.feedback);
    setComment(selectedQuestion.comment);
  }, [smeSlice, smeSlice.selectedQuestion]);

  const handleRatingChange = useCallback(
    (e) => {
      setValueRating(e.target.value);
      onRatingChange(e.target.value);
    },
    [onRatingChange]
  );

  const handleCommentsChange = useCallback(
    ({ target }) => {
      const { value } = target;
      setComment(value);
      onCommentChange(value);
    },
    [onCommentChange]
  );

  const onCommentInputBlur = () => {
    onCommentBlur();
  };

  const renderRating = () => {
    const ratings = ["None", "Good to go", "Critical edit required"];

    return (
      <FormControl className={classes.spacing} fullWidth>
        <InputLabel
          htmlFor="demo-customized-select"
          className={classes.selectLabel}
        >
          Status:
        </InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          onChange={handleRatingChange}
          input={<BootstrapInput />}
          fullWidth
          value={ratingValue || ""}
        >
          {ratings.map((rating, index) => (
            <MenuItem key={index} value={rating}>
              {rating}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.spacing}>
        <Typography> Item ID: {question.code}</Typography>
      </div>

      {renderRating()}

      <TextField
        label="Leave a comment"
        multiline
        rows={5}
        onChange={handleCommentsChange}
        fullWidth
        value={commentValue || ""}
        onBlur={onCommentInputBlur}
        className={classes.textF}
      />

      <ObjectiveInfo
        examObjective={question.examObjective}
        examSubObjective={question.examSubObjective}
      />
    </form>
  );
}

export default ReviewQuestionForm;
