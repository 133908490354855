
export const kpPrimaryColors = {
    lightBlue: "#2192C0",
    darkBlue: "#002955",
    kpYellow: "#eabc07",
    oldBlue: "#10355F"
  }

  export const kpNeutralsColors = {
    lightGray: "#777777",
    darkGray: "#151515",
    contrastGray: "#EEEEEE"
  }

  export const kpSecondaryColors = {
    purple: "#ac82ba",
    green: "#93bb45",
    orange: "#db922f"
  }
