import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function get() {
  return apiCall({
    url: `${ENDPOINT.notification}`,
    method: "GET",
  });
}

function getByProctoringType(typeProctoring) {
  return apiCall({
    url: `${ENDPOINT.notification}/template/${typeProctoring}`,
    method: "GET",
  });
}

function patch(id) {
  return apiCall({
    url: `${ENDPOINT.notification}/${id}`,
    method: "PATCH",
  });
}

const NotificationsService = {
  get,
  patch,
  getByProctoringType,
};

export default NotificationsService;
