import React, { useCallback, useEffect, useMemo, memo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  DnDQuestion,
  MultipleChoiceQuestion,
  ParsonsQuestion,
  SingleChoiceQuestion
} from "@knowledge-pillars-education-inc/kp-fe-lib";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  setEngineCodeAnswer,
  setMultiTasksAnswers
} from "store/slices/examsSession";
import { BackdropLoad } from "components";
import { SphereEngineWorkSpace } from "../sphere_engine_containers";
import { getSyntaxLanguage } from "utils/syntax-highlighter";
import { isWordpressExamWithInstance } from "heplers/examHelpers";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "20px 20px 40px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflowY: "auto",
    background: "white",
  }
}));

const WorkSpace = ({ popupType, open, taskAnswer, children, codeEditorData, onRefresh }) => {
  const currentTask = useSelector(
    (state) => state?.examsSessionSlice.currentTask,
    shallowEqual
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(setMultiTasksAnswers(null));
    dispatch(setEngineCodeAnswer(null));
  }, [currentTask]);

  const QuestionCodeString = useMemo(() => {
    if (!currentTask?.multipleDetails?.codeSnippet) return "";
    return currentTask?.multipleDetails?.codeSnippet["code"];
  }, [currentTask]);

  const MultipleChoicesHandler = useCallback(result => {
    const answers = result.reduce((acc, el) => {
      if (el.checked) {
        acc.push(el.text)
      }
      return acc
    }, [])
    dispatch(setMultiTasksAnswers(answers));
  }, [])

  const DndChangesHandler = useCallback(
    (dndAnswers) => {
      dispatch(setMultiTasksAnswers(dndAnswers));
    },
    [dispatch]
  );

  const ParsonsCodeChanges = useCallback(
    (code) => {
      dispatch(setMultiTasksAnswers(code));
    },
    [dispatch]
  );

  const DndQuestionPlugin = useMemo(() => {
    if (!currentTask) return <BackdropLoad open={true} />;
    const { questions, answers, description, displayName } = currentTask;
    return (
      <Box className={classes.wrapper}>
        <DnDQuestion
          questionLines={questions}
          answerLines={answers}
          selectedAnswers={taskAnswer?.answers || []}
          description={description}
          displayName={displayName}
          changesHandler={DndChangesHandler}
        />
      </Box>
    );
  }, [DndChangesHandler, classes.parsonsWrapper, currentTask, taskAnswer]);

  const ParsonsQuestionPlugin = useMemo(() => {
    return (
      <Box className={classes.wrapper}>
        <Box mx="auto" maxWidth="720px">
          <ParsonsQuestion
            questionLines={currentTask.answers}
            description={currentTask.description}
            selectedAnswers={taskAnswer?.answers || []}
            displayName={currentTask.displayName}
            codeSnippet={currentTask?.multipleDetails?.codeSnippet?.code}
            codeSnippetLanguage={getSyntaxLanguage(
              currentTask?.multipleDetails?.codeSnippet?.language
            )}
            language="javascript"
            handleCodeChanges={ParsonsCodeChanges}
          />
        </Box>
      </Box>
    );
  }, [
    classes.parsonsWrapper,
    currentTask.answers,
    currentTask.description,
    currentTask.displayName,
    ParsonsCodeChanges,
    taskAnswer
  ]);

  return (
    <>
      {currentTask?.type === "multiple" ? (
        <Box pt={2} pb={4} px={3}>
          <Box mx="auto" maxWidth="720px">
            <MultipleChoiceQuestion
              onChange={MultipleChoicesHandler}
              loading={false}
              codeString={QuestionCodeString}
              options={currentTask?.multipleDetails?.options || []}
              selectedOptions={taskAnswer?.textAnswers}
              displayName={currentTask?.displayName}
              language={getSyntaxLanguage(
                currentTask?.multipleDetails?.codeSnippet?.language
              )}
              description={currentTask?.description}
            />
          </Box>
        </Box>
      ) : currentTask?.type === "single" ? (
        <Box pt={2} pb={4} px={3}>
          <Box mx="auto" maxWidth="720px">
            <SingleChoiceQuestion
              onChange={MultipleChoicesHandler}
              loading={false}
              codeString={QuestionCodeString}
              options={currentTask?.multipleDetails?.options || []}
              selectedOptions={taskAnswer?.textAnswers}
              displayName={currentTask?.displayName}
              language={getSyntaxLanguage(
                currentTask?.multipleDetails?.codeSnippet?.language
              )}
              description={currentTask?.description}
            />
          </Box>
        </Box>
      ) : isWordpressExamWithInstance(currentTask.examCode) || isWordpressExamWithInstance(params?.examCode) ? (
        <>{children}</>
      ) : currentTask?.type === "match" ? (
        DndQuestionPlugin
      ) : currentTask?.type === "practical" ? (
        <SphereEngineWorkSpace
          popupType={popupType}
          open={open}
          codeEditorData={codeEditorData}
          onRefresh={onRefresh}
        />
      ) : currentTask?.type === "parsons" ? (
        ParsonsQuestionPlugin
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default memo(WorkSpace);
