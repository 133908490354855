import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { forgotPasswordKeys, checkEmail } from "../../../utils/validation";
import { forgotPassword } from "../../../services/auth";
import { CurrentState } from "../ForgotPassword";
import EmailIcon from "@mui/icons-material/Email";

import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Divider,
  Box,
  Link,
  InputAdornment
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  forgotContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "45%",
      maxWidth: "55%"
    }
  },
  forgotTitle: {
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  submitButtonContainer: {
    marginTop: theme.spacing(2)
  },
  backToLinkContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  backToLink: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "none"
    }
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submitButton: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2)
  }
}));

function ForgotEmail({ setCurrentState, setValues, values }) {
  const {
    root,
    form,
    forgotContainer,
    forgotTitle,
    DividerContainer,
    DividerBar,
    backToLinkContainer,
    backToLink,
    submitButtonContainer,
    submitButton
  } = useStyles();

  const [attempt, setAttemt] = useState(false);
  const [active, setToggleActive] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      });
    },
    [values, setValues]
  );

  const handleSubmit = useCallback(async () => {
    setToggleActive(false);
    if (checkEmail(values[forgotPasswordKeys.EMAIL.key])) {
      setAttemt(true);
      setToggleActive(true);
      return;
    }
    const result = await forgotPassword(values.email);

    if (typeof result !== "boolean") {
      setErrMessage(() => result.message);
      setToggleActive(true);
    } else {
      setCurrentState(CurrentState.CONFIRM);
    }
    return false;
  }, [values, setCurrentState]);

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={forgotContainer}>
        <Typography
          className={forgotTitle}
          align="center"
          variant="h5"
          color="primary"
        >
          Reset Password
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>
        <Typography variant="subtitle1" align="center">
          Enter your email to get a <b>confirmation code.</b>
        </Typography>

        <form className={form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="name"
            autoFocus
            onChange={(e) => handleChange(e)}
            helperText={
              attempt && checkEmail(values[forgotPasswordKeys.EMAIL.key])
                ? checkEmail(values[forgotPasswordKeys.EMAIL.key])
                : ""
            }
            error={attempt && checkEmail(values[forgotPasswordKeys.EMAIL.key])}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            }}
          />
          {errMessage && (
            <Grid container>
              <Typography variant="subtitle2" color="error">
                {errMessage}
              </Typography>
            </Grid>
          )}

          <Grid container className={submitButtonContainer}>
            <Grid item xs={12} sm={8} className={backToLinkContainer}>
              <Typography variant="subtitle2">
                <Link href="/" className={backToLink}>
                  {"Back to Sign In"}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
                onClick={handleSubmit}
                disabled={!active}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}

export default ForgotEmail;
