const languageConversionMap = {
    /** 
     * https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/310#issuecomment-702527953
     * Not using "markup" though, as Prisma is not used consistently across codebase
     */
    // 'html': 'xml'
}

/**
 * 
 * @param {string} inputLanguage
 * @returns {string} normalized and (possibly) converted language name that can be used in `react-syntax-highlighter`
 */
export const getSyntaxLanguage = (inputLanguage) => {
    return languageConversionMap[inputLanguage] || inputLanguage
}