import React, {memo, useCallback, useState} from "react"
import {useNavigate} from "react-router-dom"
import {
  signUpKeys,
  getInitialState,
  getAttributes,
  checkEmail,
  checkEmptyInput,
  checkPassword,
  checkPhone,
  checkUserName
} from "../../utils/validation"
import {useDispatch, useSelector} from "react-redux"
import {
  signUpUser,
  confirm,
  setSignUp,
  resendConfirmCode
} from "../../store/slices/user"
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  Paper,
  Link,
  InputAdornment
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import PersonIcon from "@mui/icons-material/Person"
import EmailIcon from "@mui/icons-material/Email"
import LockIcon from "@mui/icons-material/Lock"
import TodayIcon from "@mui/icons-material/Today"
import PhoneIcon from "@mui/icons-material/Phone"
import HomeIcon from "@mui/icons-material/Home"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import PublicIcon from "@mui/icons-material/Public"
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox"
import LockOpenIcon from "@mui/icons-material/LockOpen"

const getTextFieldIcon = (fieldName) => {
  switch (fieldName) {
    case "given_name":
      return <PersonIcon />
    case "family_name":
      return <PersonIcon />
    case "email":
      return <EmailIcon />
    case "password":
      return <LockIcon />
    case "birthdate":
      return <TodayIcon />
    case "phone_number":
      return <PhoneIcon />
    case "address":
      return <HomeIcon />
    case "city":
      return <LocationCityIcon />
    case "country":
      return <PublicIcon />
    case "postcode":
      return <MarkunreadMailboxIcon />
    default:
  }
}

const Item = ({name, handleChange, attempt, values, label}) => {
  const validateFunction = {
    email: checkEmail(values[signUpKeys.EMAIL.key]),
    password: checkPassword(values[signUpKeys.PASSWORD.key]),
    given_name: checkUserName(values[signUpKeys.GIVEN_NAME.key]),
    family_name: checkEmptyInput(values[signUpKeys.SURNAME.key]),
    birthdate: checkEmptyInput(values[signUpKeys.BIRTHDATE.key]),
    phone_number: checkPhone(values[signUpKeys.PHONE.key]),
    address: checkEmptyInput(values[signUpKeys.ADDRESS.key]),
    city: checkEmptyInput(values[signUpKeys.CITY.key]),
    country: checkEmptyInput(values[signUpKeys.COUNTRY.key]),
    postcode: checkEmptyInput(values[signUpKeys.POSTCODE.key])
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      style={{paddingLeft: "16px", paddingRight: "16px"}}
    >
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id={name === "birthdate" ? "date" : name}
        label={label}
        name={name}
        autoComplete={name}
        type={name === "birthdate" ? "date" : name}
        onChange={(e) => handleChange(e)}
        helperText={
          attempt && validateFunction[name] ? validateFunction[name] : ""
        }
        error={attempt && validateFunction[name]}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {name !== "birthdate" ? getTextFieldIcon(name) : ""}
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  signUpContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "50%",
      maxWidth: "55%"
    }
  },
  signUpSubmittedContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "40%",
      maxWidth: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "30%",
      maxWidth: "40%"
    }
  },
  confirmButtonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  signUpTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "25%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  submitButtonContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  submitButton: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),

    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    }
  },
  confirmButton: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2)
  },
  formFootTxt: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  fieldItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  backToLinkContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  backToLink: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "none"
    }
  },
  verificationText: {
    marginBottom: theme.spacing(1)
  }
}))

function SignUp() {
  const {
    root,
    signUpContainer,
    signUpTitle,
    form,
    submitButton,
    confirmButton,
    formFootTxt,
    DividerContainer,
    DividerBar,
    backToLink,
    submitButtonContainer,
    backToLinkContainer,
    signUpSubmittedContainer,
    confirmButtonContainer,
    verificationText
  } = useStyles()
  const navigate = useNavigate()
  const {
    confirmSignUp,
    errSignUp,
    loadingSigUp: loading
  } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [values, setValues] = useState(getInitialState(signUpKeys))
  const [code, setCode] = useState("")
  const [attempt, setAttemt] = useState(false)

  const handleSubmit = useCallback(
    async (values) => {
      if (
        checkUserName(values[signUpKeys.GIVEN_NAME.key]) ||
        checkEmail(values[signUpKeys.EMAIL.key]) ||
        checkPassword(values[signUpKeys.PASSWORD.key]) ||
        checkEmptyInput(values[signUpKeys.SURNAME.key]) ||
        checkEmptyInput(values[signUpKeys.BIRTHDATE.key]) ||
        checkPhone(values[signUpKeys.PHONE.key]) ||
        checkEmptyInput(values[signUpKeys.ADDRESS.key]) ||
        checkEmptyInput(values[signUpKeys.CITY.key]) ||
        checkEmptyInput(values[signUpKeys.COUNTRY.key]) ||
        checkEmptyInput(values[signUpKeys.POSTCODE.key])
      ) {
        setAttemt(true)
      } else {
        if (values)
          dispatch(
            signUpUser(
              values[signUpKeys.EMAIL.key],
              values[signUpKeys.PASSWORD.key],
              getAttributes(values, signUpKeys)
            )
          )
      }
    },
    [dispatch]
  )

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      })
    },
    [values]
  )

  const handleSubmitConfirm = useCallback(
    async (code, username) => {
      if (code && username) dispatch(confirm(username, code, history))
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleChangeConfirm = useCallback((e) => {
    setCode(e.target.value)
  }, [])

  const handleSigIn = useCallback(() => {
    dispatch(setSignUp(false))
    navigate("/")
  }, [dispatch])

  if (
    null !== confirmSignUp &&
    typeof confirmSignUp === "object" &&
    undefined !== confirmSignUp.username
  ) {
    return (
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={signUpSubmittedContainer}>
          <Typography
            className={verificationText}
            variant="subtitle1"
            align="center"
            color="textPrimary"
          >
            A verification code has been sent to the email address associated
            with your account.
          </Typography>
          <form className={form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Code"
              name="code"
              value={code}
              onChange={handleChangeConfirm}
              helperText={errSignUp ? errSignUp : ""}
              error={errSignUp}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockOpenIcon />
                  </InputAdornment>
                )
              }}
            />
            <Grid item xs={12} className={confirmButtonContainer}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={loading || !code}
                className={confirmButton}
                onClick={() =>
                  handleSubmitConfirm(code, confirmSignUp.username)
                }
              >
                Confirm
              </Button>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  className={formFootTxt}
                  onClick={handleSigIn}
                >
                  {"Back to Sign In"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  align="right"
                  className={formFootTxt}
                  onClick={(e) =>
                    dispatch(resendConfirmCode(confirmSignUp.username))
                  }
                >
                  {"Resend code"}
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    )
  }

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={signUpContainer}>
        <Typography
          className={signUpTitle}
          align="center"
          variant="h5"
          color="primary"
        >
          Sign Up
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>

        <form noValidate>
          <Grid container>
            {Object.keys(signUpKeys).map((item, index) => {
              return (
                <Item
                  key={signUpKeys[item].key + index}
                  name={signUpKeys[item].key}
                  label={signUpKeys[item].label}
                  values={values}
                  handleChange={handleChange}
                  attempt={attempt}
                />
              )
            })}
          </Grid>

          {errSignUp && (
            <Grid container>
              <Grid item style={{color: "red"}}>
                {errSignUp}
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item xs={12} sm={6} className={backToLinkContainer}>
              <Typography variant="subtitle2">
                <Link href="/" className={backToLink}>
                  {"Back to Sign In"}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={submitButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                className={submitButton}
                onClick={() => handleSubmit(values, signUpKeys)}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  )
}

export default memo(SignUp)
