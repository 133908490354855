import React, { useState, useRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  iFrame: {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  iFrameWrapper: {
    position: "relative",
    height: "100%",
    background: "#fff",
  },
  iFrameSpinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
    color: "rgb(255,255,255)",
    backgroundColor: "grey",
  },
}));

function WordpressWorkSpase() {
  const styles = useStyles();
  const [loading, setLoading] = useState(true);
  const { sessionData: data } = useSelector((state) => state).examsSessionSlice;
  const gridIframe = useRef(null);

  const handleLoad = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <div className={styles.iFrameWrapper}>
      {loading && <div className={styles.iFrameSpinner}>loading..</div>}
      {data?.instance?.urlForEmbedding && (
        <iframe
          src={data?.instance?.urlForEmbedding}
          frameBorder={0}
          title='wp-exam'
          className={styles.iFrame}
          width='100%'
          height='100%'
          ref={gridIframe}
          onLoad={() => handleLoad()}
        />
      )}
    </div>
  );
}

export default WordpressWorkSpase;
