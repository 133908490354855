import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  objective: {
    border: '1px solid #ddd',
    padding: '10px 12px',
    borderRadius: '4px',
  },
  title: {
    fontWeight: 'bold',
  },
  info: {
    fontSize: '14px',
    fontStyle: 'italic',

    color: 'gray',
    boxSizing: 'border-box',
    '& > span': {
      boxSizing: 'border-box',
      display: 'block',
      margin: '10px 0',
      paddingBottom: '10px',
      width: '100%',
      '&:not(:last-child)': {
        borderBottom: '1px solid #ddd',
      }
    }
  },
}));
