import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function get() {
  return apiCall({
    url: `${ENDPOINT.voucher}`,
    method: "GET",
  });
}

function toggleStatus(body) {
  return apiCall({
    url: `${ENDPOINT.voucher}/${body.id}`,
    method: "PATCH",
    data: {
      status: body.status
    },
  });
}

function validateVoucher(examCode, voucherCode) {

  return apiCall({
      url: `${ENDPOINT.validatePaymentVoucher}examCode=${examCode}&voucherCode=${voucherCode}`,
      method: "GET"
  })

}


const VoucherService = {
  get,
  toggleStatus,
  validateVoucher,
};

export default VoucherService;
