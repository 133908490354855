import React from "react";
import { Card, Grid, CardContent, Button, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh"
  },
  title: {
    textAlign: "center",
    padding: "0 2rem"
  },
  errCard: {
    maxWidth: "40rem"
  },
  prog: {
    padding: "2rem 1rem",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const ActionCard = ({ message, handleClick, progress }) => {
  const styles = useStyles();

  return (
    <Grid
      container
      className={styles.root}
      alignItems="center"
      justifyContent="center"
    >
      <Card className={styles.errCard}>
        <CardContent>
          <Button size="small" onClick={handleClick} value={message}>
            {message}
          </Button>
          {progress && (
            <div className={styles.prog}>
              <LinearProgress color="primary" />
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ActionCard;
