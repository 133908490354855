export function checkScroll(a) {
  var d = document,
    b = d.body,
    e = d.documentElement,
    c = "client" + a;
  a = "scroll" + a;
  return /CSS/.test(d.compatMode) ? e[c] < e[a] : b[c] < b[a];
}

// usege:
// checkScroll('Width') – есть ли горизонтальный скролл
// checkScroll('Height') – есть ли вертикальный скролл
