export interface SebCheckResult {
    configKey: string
    browserExamKey: string
    sebVersion: string
    sebVersionParsed: ParsedSebVersion | null
}

export interface ParsedSebVersion {
    appDisplayName: string
    os: string
    versionString: string
    buildNumber: string
    bundleId: string
}

/**
 * Parses a Safe Exam Browser version string into its component parts.
 * 
 * @param version - The SEB version string in format: appDisplayName_<OS>_versionString_buildNumber_bundleID
 *                 Example: "Safe Exam Browser_macOS_3.1.0_123_org.safeexambrowser.SafeExamBrowser"
 * @returns ParsedSebVersion object containing the parsed components, or null if invalid format
 */
const parseSebVersion = (version: string): ParsedSebVersion | null => {
    // Parse version string in format: appDisplayName_<OS>_versionString_buildNumber_bundleID
    const parts = version.split("_")
    if (parts.length < 5) {
        return null
    }

    // Extract OS from second part
    const os = parts[1]
    if (!["iOS", "macOS", "Windows"].includes(os)) {
        return null 
    }

    return {
        appDisplayName: parts[0],
        os: os,
        versionString: parts[2],
        buildNumber: parts[3],
        bundleId: parts[4]
    }
}

/**
 * Checks if the current browser environment is Safe Exam Browser (SEB).
 * This function verifies the presence of SEB by:
 * 1. Checking if the SafeExamBrowser API exists in the window object
 * 2. For SEB v3.0, calling updateKeys() if available
 * 3. Verifying access to security properties (configKey or browserExamKey)
 * 
 * @returns Promise<boolean> - Returns true if running in SEB, false otherwise
 */
export const sebCheck = async (): Promise<SebCheckResult | null> => {
    // Check if SafeExamBrowser API is available
    if (typeof SafeExamBrowser === "undefined") {
        return null
    }

    try {
        // Check if we can access the security properties
        const security = SafeExamBrowser.security

        // Only call updateKeys() if the function exists and no config key is present
        if (SafeExamBrowser.security?.updateKeys && !SafeExamBrowser.security?.configKey) {
            await new Promise<void>((resolve) => {
                SafeExamBrowser.security.updateKeys(() => resolve())
            })
        }

        // Verify that we can access either configKey or browserExamKey
        return {
            configKey: security.configKey,
            browserExamKey: security.browserExamKey,
            sebVersion: SafeExamBrowser.version,
            sebVersionParsed: parseSebVersion(SafeExamBrowser.version)
        }
    } catch (error) {
        console.error("Error checking SEB:", error)
        return null
    }
}