import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";
import { useDispatch, useSelector } from "react-redux";
import { examSessionStatuses } from "constants/constants";
import { getUserExamsScore } from "store/slices/exams";
import { Container, Grid } from "@mui/material";
import { examNameReplacer } from "constants/constants";
import { SimpleCardMessage } from "components/Cards/SimpleCardMessage";
import { Certificate } from "components/Certificate";
import { BackdropLoad } from "../../components";

const noResultsDetails = {
  message: "No results available",
  path: "/",
  buttonText: "Back to Dashboard"
};

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: theme.spacing(5)
    // minHeight: 'calc(100vh - 160px)',
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7)
  },
  content: {
    display: "flex",
    justifyContent: "center"
  }
}));

function Results() {
  const { pageContainer, contentContainer, content } = useStyles();
  const dispatch = useDispatch();
  const { loading, userExamsScore } = useSelector((state) => state).examsSlice;
  const [data, setData] = useState(null);
  const userHasExamsScores =
    userExamsScore && userExamsScore !== "null" && userExamsScore.length < 1;

  useEffect(() => {
    dispatch(getUserExamsScore());
  }, [dispatch]);

  const getExamStatus = (status, isPassed) => {
    if (status === examSessionStatuses.Canceled) {
      return "CANCELLED BY THE USER";
    }
    if (status === examSessionStatuses.ProctorReviewInProgress) {
      return "Under Review";
    }
    if (isPassed) {
      return "PASSED";
    } else {
      return "FAILED";
    }
  };

  const getExamScore = (score, status, isPassed) => {
    if (score && isPassed) {
      return `${score} %`;
    } else if (!score && status === examSessionStatuses.ProctorReviewInProgress) {
      return "Under Review";
    } else if (!score) {
      return "-";
    }
    return "Under Review";
  };

  const getFormattedDate = (date, initiatedDate) => {
    return date
      ? format(new Date(date), "dd  MMMM yyyy")
      : format(new Date(initiatedDate), "dd  MMMM yyyy");
  };

  const mapCertificationData = (exams) => {
    return exams.map((exam) => {
      const isWordpressExam = exam.name === "wce";
      const isPythonExam = ["python", "pca", "pcs"].includes(exam.name);

      let certificationAbbreviation = isWordpressExam ? "WCE" : "Other";
      if (isPythonExam && exam?.displayCode)
        certificationAbbreviation = exam?.displayCode;

      let imgUrl = exam?.displayCode
        ? `icons/${exam.displayCode.toLowerCase()}.png`
        : `icons/Unknown.png`;

      return {
        name: examNameReplacer[
          !isPythonExam ? exam.name : exam?.displayCode?.toLowerCase()
        ],
        certification: certificationAbbreviation,
        imgSrc: imgUrl,
        status: getExamStatus(exam.status, exam.isPassed),
        date: getFormattedDate(exam.date, exam.initiatedDate),
        score: getExamScore(exam.score, exam.status, exam.isPassed)
      };
    });
  };

  useEffect(() => {
    if (userExamsScore && userExamsScore.length > 0) {
      const mappedExamsData = mapCertificationData(userExamsScore);
      setData(mappedExamsData);
    }
  }, [userExamsScore]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading && <BackdropLoad open={true} />}
      <Container className={pageContainer} spacing={5}>
        <Grid container>
          <Grid className={contentContainer} item xs={12}>
            <Grid container>
              {userHasExamsScores ? (
                <Grid className={content} item xs={12}>
                  <SimpleCardMessage {...noResultsDetails}/>
                </Grid>
              ) : (
                data?.map((cert, index) => {
                  return (
                    <Grid className={content} item xs={12} key={index}>
                      <Certificate
                        name={cert.name}
                        certification={cert.certification}
                        status={cert.status}
                        date={cert.date}
                        imgSrc={cert.imgSrc}
                        score={cert.score}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Results;
