import {
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
} from "aws-amplify/auth";

export async function getCurrentUserInfo() {
  return getCurrentUser();
}

export const getCognitoGroups = () =>
  fetchAuthSession()
    .then(({ tokens }) => tokens.idToken.payload["cognito:groups"] || [])
    .catch((err) => []);

// export async function getSession() {
//   try {
//     const sessionData = await Auth.currentSession()
//
//     return {
//       accessToken: sessionData.getIdToken().getJwtToken(),
//       refreshToken: sessionData.getRefreshToken()
//     }
//   } catch (err) {
//     return null
//   }
// }

export async function forgotPassword(username: string) {
  try {
    await resetPassword({ username });
    return true;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function passwordReset(
  username: string,
  code: string,
  newPassword: string
) {
  try {
    await confirmResetPassword({
      username,
      confirmationCode: code,
      newPassword,
    });
    return true;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function resendConfirmationCode(username: string) {
  try {
    const ressult = await resendSignUpCode({ username });
    return {
      success: true,
      response: ressult,
    };
  } catch (err) {
    return {
      success: false,
      response: err,
    };
  }
}
