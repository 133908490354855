import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  CardActions,
  CardContent,
  Button,
  Typography,
  LinearProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh"
  },
  title: {
    textAlign: "center",
    padding: "0 2rem"
  },
  errCard: {
    maxWidth: "42rem",
    padding: "1rem"
  },
  prog: {
    padding: "2rem 1rem",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const CardMessage = ({
  message,
  buttonMessage = "Back to dashboard",
  handleClick,
  backTo,
  progress,
  buttonVariant,
  buttonColor,
  messageColor,
  buttonSize = "small",
  buttonPosition
}) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const handleBackToDashboard = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <Grid
      container
      className={styles.root}
      alignItems="center"
      justifyContent="center"
    >
      <Card className={styles.errCard}>
        <CardContent>
          {message && (
            <Typography
              variant={progress ? "h6" : "h5"}
              component="h2"
              color={messageColor || (progress ? "grey" : "secondary")}
              className={styles.title}
            >
              {message}
            </Typography>
          )}
          {progress && (
            <div className={styles.prog}>
              <LinearProgress color="primary" />
            </div>
          )}
        </CardContent>
        {backTo || handleClick ? (
          <CardActions sx={{
            justifyContent: buttonPosition
          }}>
            <Button
              size={buttonSize}
              variant={buttonVariant}
              color={buttonColor}
              onClick={
                backTo ? () => handleBackToDashboard() : () => handleClick()
              }
            >
              {buttonMessage}
            </Button>
          </CardActions>
        ) : null}
      </Card>
    </Grid>
  );
};

export default CardMessage;
