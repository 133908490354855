import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useQuestionIndex } from "../../hooks/tasksHooks";
import { useStyles } from "./styles/ReviewTasks.styles";
import { unwrapResult } from "@reduxjs/toolkit";
// actions
import {
  fetchExams,
  fetchTask,
  setSelectedExam,
  setSelectedQuestion,
  taskEvaluationScore
} from "../../store/slices/sme";
// components
import { Divider, Grid, Typography } from "@mui/material";
import ScoreQuestionForm from "./components/ScoreQuestionForm";
import { BackdropLoad } from "../../components";
import BackButton from "../../components/Buttons/BackButton";
import CustomPagination from "../../components/CustomPagination";
import ParsonsQuestion from "../../parsonsTest/ParsonsQuestion";

// private npm package
import {
  DnDQuestion,
  KpEngine,
  MultipleChoiceQuestion
} from "@knowledge-pillars-education-inc/kp-fe-lib";
// router links
import { LINKS } from "../../constants/links";
import useKpEditor from "../../hooks/tasksHooks/useKpEditor";
import { getSyntaxLanguage } from "utils/syntax-highlighter";

let localLoading = false;

function ScoreTasks() {
  // ! variables
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlProps = useParams();

  // ! selectors
  const { loading, questions, question, selectedExam } = useSelector(
    (state) => {
      const { selectedQuestion, examQuestions, loading, selectedExam } =
        state.smeSlice;
      return {
        loading,
        questions: examQuestions,
        question: selectedQuestion,
        selectedExam
      };
    }
  );
  // current question index
  const index = useQuestionIndex(questions, question);

  // ! KpEngine variables
  const { compileAnswer, runDisabled, onRunCode } = useKpEditor(question);

  // ! variables to avoid errors in question type, if data structure changed
  const QuestionCodeString = useMemo(() => {
    if (!question?.multipleDetails?.codeSnippet) return "";
    return question?.multipleDetails?.codeSnippet["code"];
  }, [question]);

  // check if page has all info
  useEffect(() => {
    // get all info about page, if reloaded
    const componentInit = async () => {
      localLoading = true;
      await dispatch(fetchExams());
      const wrappedQuestionsArray = await dispatch(
        setSelectedExam(urlProps.examCode)
      );
      const unwrappedQuestionsArray = unwrapResult(wrappedQuestionsArray);
      const currentQuestion = unwrappedQuestionsArray.find(
        (q) => q.id === urlProps.questionId
      );
      await dispatch(setSelectedQuestion(currentQuestion));
      localLoading = false;
    };
    if (!questions && !localLoading) componentInit();
  }, [questions, urlProps.examCode, urlProps.questionId, dispatch]);

  // ! handlers
  const onNextButtonClick = async () => {
    if (index >= questions?.length - 1)
      return navigate(LINKS.smeSelectAction, {replace: true});

    await dispatch(fetchTask(questions[index + 1].id));
    navigate(
      `${LINKS.smeTasksList}/score/${urlProps.examCode}/${
        questions[index + 1].id
      }`, {replace: true}
    );
  };

  const onPreviousButtonClick = async () => {
    await dispatch(fetchTask(questions[index - 1].id));
    navigate(
      `${LINKS.smeTasksList}/score/${urlProps.examCode}/${
        questions[index - 1].id
      }`, {replace: true}
    );
  };

  const handleScoreChange = (newScore) => {
    dispatch(
      taskEvaluationScore({ taskCode: question?.code, score: newScore })
    );
  };

  const handleBackToQuestions = useCallback(() => {
    navigate(
      `${LINKS.smeTasksList}/list/ScoreQuestions/${urlProps.examCode}`, {replace: true}
    );
  }, [ urlProps.examCode]);

  const MultipleChoicesHandler = (form) => {
    // This method takes in arguments the result from SingleChoiceQuestion or MultipleChoiceQuestion
    // console.log(form);
  };

  const changesHandler = useCallback((dndAnswers) => {
    // dispatch(setMultiTasksAnswers(dndAnswers));
  }, []);

  const ParsonsCodeChanges = useCallback((code) => {}, []);

  const DndQuestionPlugin = useMemo(() => {
    return (
      <DnDQuestion
        questionLines={question?.questions}
        answerLines={question?.answers}
        description={question?.description}
        displayName={question?.displayName}
        changesHandler={changesHandler}
      />
    );
  }, [
    changesHandler,
    question?.answers,
    question?.description,
    question?.displayName,
    question?.questions
  ]);

  const ParsonsQuestionPlugin = useMemo(() => {
    return (
      <div className={classes.parsonsWrapper}>
        <ParsonsQuestion
          questionLines={question?.answers}
          description={question?.description}
          displayName={question?.displayName}
          codeSnippet={question?.multipleDetails?.codeSnippet?.code}
          codeSnippetLanguage={getSyntaxLanguage(
            question?.multipleDetails?.codeSnippet?.language
          )}
          language={QuestionCodeString ? "sql" : "javascript"}
          handleCodeChanges={ParsonsCodeChanges}
        />
      </div>
    );
  }, [
    classes.parsonsWrapper,
    question?.answers,
    question?.description,
    question?.displayName,
    ParsonsCodeChanges
  ]);

  // ! render helpers
  const getTaskContainer = () => {
    if (question?.type === "match") {
      return DndQuestionPlugin;
    }
    if (question?.type === "parsons") {
      return ParsonsQuestionPlugin;
    }

    if (question?.type === "practical") {
      return (
        <div className={classes.kpsphereWrap}>
          <KpEngine
            displayName={question?.displayName || " "}
            editorLanguage={question?.practicalDetails?.language || "python"}
            infoLanguage="js"
            codeEditorData={question?.practicalDetails?.template || " "}
            infoEditorData={question?.problem?.body || " "}
            onRunCode={onRunCode}
            compileData={compileAnswer}
            runDisabled={runDisabled}
          />
        </div>
      );
    }

    if (question?.type === "multiple") {
      return (
        <div className={classes.multipleScoreWrapper}>
          <MultipleChoiceQuestion
            onChange={MultipleChoicesHandler}
            loading={loading}
            codeString={QuestionCodeString}
            options={question?.multipleDetails?.options}
            displayName={question?.displayName}
            language={getSyntaxLanguage(
              question?.multipleDetails?.codeSnippet?.language
            )}
            description={question?.description}
          />
        </div>
      );
    }

    return <div />;
  };

  // ! render
  return (
    <>
      {loading && <BackdropLoad open={loading} />}

      <BackButton
        buttonLabel="All questions"
        handleBackClick={handleBackToQuestions}
      />

      {!loading && question && (
        <div>
          <Grid container className={classes.reviewContainer}>
            <Grid item xs={12} md={8}>
              {getTaskContainer()}
            </Grid>

            <Grid item xs={12} md={4} className={classes.review}>
              <Typography className={classes.title} variant="h5">
                Standard setting -{" "}
                {selectedExam?.examDisplayCode || selectedExam?.examCode || ""}
              </Typography>
              <Divider className={classes.spacing} />

              <ScoreQuestionForm
                handleScoreChange={handleScoreChange}
                question={question}
              />

              <CustomPagination
                isLastQuestion={index >= questions?.length - 1}
                isFirstQuestion={index <= 0}
                onPreviousButtonClick={onPreviousButtonClick}
                onNextButtonClick={onNextButtonClick}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default ScoreTasks;
