import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { getAllUserRoles, updateRoleAndFetch } from "store/slices/roles";

import { Grid, Typography, Button } from "@mui/material";

import { CustomTable, Modal as CustomModal } from "components";
import { filterNormilizeRoles } from "utils";

import { useModal } from "hooks/useModal";
import { getGroup } from "store/slices/groups";
import { isAllowedToDelete } from "heplers/isAllowedToDelete";

const columns = [
  { id: "username", label: "Name", minWidth: 100, align: "center" },
  { id: "email", label: "Email", minWidth: 100, align: "center" },
  {
    id: "button",
    label: "Remove",
    minWidth: 120,
    align: "center"
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },

  labelRow: {
    backgroundColor: "#0f15ba1a"
  },
  titleContainer: {
    margin: "1rem 0"
  }
});

function Settings() {
  const { getModal, getModalClass, handleOpen, handleClose, open } = useModal();
  const [mode, setMode] = useState("");
  const [listForbiddenEdit, setListForbiddenEdit] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, allUsers } = useSelector((state) => state.rolesSlice);

  const { allGroups } = useSelector((state) => state.groupSlice);

  const [rowsAdmin, setRowsAdmin] = useState([]);
  const [rowsTeacher, setTeacherArr] = useState([]);
  const [rowsModal, setRowsModal] = useState([]);

  useEffect(() => {
    dispatch(getAllUserRoles());
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getGroup());
  }, []); // eslint-disable-line

  const normilizeData = useCallback((arr) => {
    const normilizedArr = arr.map((item) => {
      return { ...item, button: "Removed" };
    });
    return normilizedArr;
  }, []);

  const filteredNormilizedUsers = useCallback(
    (allUsers, exeptions, extraField) => {
      return filterNormilizeRoles(allUsers, exeptions, extraField);
    },
    []
  );

  useEffect(() => {
    if (allUsers["admin"].length) {
      setRowsAdmin(normilizeData(allUsers["admin"]));
    }
    if (allUsers["teacher"].length) {
      setTeacherArr(normilizeData(allUsers["teacher"]));
    }
  }, [loading]); // eslint-disable-line

  const handleClickAdmin = useCallback(
    (value) => {
      rowsAdmin.forEach((user, index) => {
        if (index === value) {
          dispatch(
            updateRoleAndFetch({
              id: user.id,
              role: "student",
              organizationId: user.organizationId
            })
          );
        }
      });
    },
    [rowsAdmin, dispatch]
  );

  const handleClickTeacher = useCallback(
    (idx, _, row) => {
      const forbiddenList = isAllowedToDelete(allGroups, row.id);
      const { teachers, students, proctors } = forbiddenList;
      if ([teachers, students, proctors].some((el) => el.length > 0)) {
        setListForbiddenEdit({ ...forbiddenList, name: row.username });
        handleOpen();
      } else {
        rowsTeacher.forEach((user, index) => {
          if (index === idx) {
            dispatch(
              updateRoleAndFetch({
                id: user.id,
                role: "student",
                organizationId: user.organizationId
              })
            );
          }
        });
      }
    },
    [rowsTeacher, setTeacherArr] // eslint-disable-line
  );

  const handleOpenModal = useCallback(
    (role) => () => {
      setMode(role);
      if (role === "admin") {
        setRowsModal(
          filteredNormilizedUsers(allUsers, role, { checkbox: false })
        );
      }
      if (role === "teacher") {
        setRowsModal(
          filteredNormilizedUsers(allUsers, role, {
            checkbox: false
          })
        );
      }
      handleOpen();
    },
    [setMode, setRowsModal, allUsers] // eslint-disable-line
  );

  const handleAdd = useCallback(() => {
    rowsModal.forEach((user) => {
      if (user.checkbox) {
        dispatch(
          updateRoleAndFetch({
            id: user.id,
            role: mode,
            organizationId: user.organizationId
          })
        );
      }
    });

    handleClose();
    setRowsModal([]);
  }, [rowsModal, setRowsModal, dispatch, mode, handleClose]);

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      {listForbiddenEdit ? (
        <CustomModal
          listForbiddenEdit={listForbiddenEdit}
          handleClose={handleClose}
          open={open}
          className={getModalClass("ADD_ROLES")}
        >
          {(props) => getModal("FORBIDDEN_EDIT", props)}
        </CustomModal>
      ) : (
        <CustomModal
          rowsModal={rowsModal}
          setRowsModal={setRowsModal}
          mode={mode}
          loading={loading}
          handleClose={handleClose}
          handleAdd={handleAdd}
          open={open}
          className={getModalClass("ADD_ROLES")}
        >
          {(props) => getModal("ADD_ROLES", props)}
        </CustomModal>
      )}

      <Grid item container direction="column" md={10} sm={12} lg={8}>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.titleContainer}
        >
          <Typography variant="h5">Admins:</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal("admin")}
          >
            Add+
          </Button>
        </Grid>

        <CustomTable
          rows={rowsAdmin}
          columns={columns}
          handleClickBtn={handleClickAdmin}
          className={classes.rootTable}
        />
      </Grid>
      <Grid item container direction="column" md={10} sm={12} lg={8}>
        <Grid
          item
          container
          justifyContent="space-between"
          className={classes.titleContainer}
        >
          <Typography variant="h5">Teachers:</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal("teacher")}
          >
            Add+
          </Button>
        </Grid>

        <CustomTable
          rows={rowsTeacher}
          columns={columns}
          handleClickBtn={handleClickTeacher}
          className={classes.rootTable}
        />
      </Grid>
    </Grid>
  );
}

export default Settings;
