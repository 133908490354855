import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function taskEvaluationScore(body) {
  return apiCall({
    url: `${ENDPOINT.taskEvaluationScore}`,
    method: "POST",
    data: {
        ...body
    }
  });
}

function taskEvaluationReview(body) {
   return apiCall({
    url: `${ENDPOINT.taskEvaluationReview}`,
    method: "POST",
    data: {
        ...body
    }
  });
}

const TaskEvaluationService = {
    taskEvaluationScore,
    taskEvaluationReview
};

export default TaskEvaluationService;