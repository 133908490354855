import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ExamService from "../../services/exam";

export const initialState = {
  loading: false,
  loadingHasAccess: false,
  hasErrors: false,
  hasAccessErrors: false,
  allExams: [],
  examsByProgramId: [],
  exam: {},
  userExamsScore: null,
  doesStudentHaveAccess: "",
};

export const getUserExamsScore = createAsyncThunk(
  "exams/getUserExamsScore",
  async () => {
    const res = await ExamService.getUserExamScore();
    return res.data;
  }
);

export const getExamStudentAccess = createAsyncThunk(
  "exams/getExamStudentAccess",
  async (examCode) => {
    const res = await ExamService.getExamStudentAccess(examCode);
    return res.data;
  }
);

const examsSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    getExams: (state) => {
      state.loading = true;
    },
    getExamSucess: (state, { payload }) => {
      state.allExams = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getExamFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    getExamByProgramIdSuccess: (state, { payload }) => {
      state.examsByProgramId = payload;
      state.loading = false;
      state.hasErrors = false;
    },

    getExam: (state, { payload }) => {
      state.exam = payload;
    },

    clearExamsState: () => initialState,
    clearHaveAccess: (state) => {
      state.doesStudentHaveAccess = "";
      state.loadingHasAccess = false;
      state.hasAccessErrors = false;
    },
  },
  extraReducers: {
    // pending
    [getUserExamsScore.pending || getExamStudentAccess.pending]: (state) => {
      state.loading = true;
    },
    [getExamStudentAccess.pending]: (state) => {
      state.loadingHasAccess = true;
    },
    //fulfilled
    [getUserExamsScore.fulfilled]: (state, { payload }) => {
      state.userExamsScore = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    [getExamStudentAccess.fulfilled]: (state, { payload }) => {
      state.doesStudentHaveAccess = payload.doesStudentHaveAccess;
      state.loadingHasAccess = false;
      state.hasAccessErrors = false;
    },
    //rejected
    [getUserExamsScore.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [getExamStudentAccess.rejected]: (state) => {
      state.loadingHasAccess = false;
      state.hasAccessErrors = true;
    },
  },
});

// Actions generated from the slice
export const {
  getExams,
  getExamsSucess,
  getExamFailure,
  getExamByProgramIdSuccess,
  getExam,
  clearExamsState,
  clearHaveAccess,
} = examsSlice.actions;

// The reducer
export default examsSlice.reducer;

export const fetchExams = createAsyncThunk(
  "exams/fetchExams",
  async (thunkAPI) => {
    // Set loading to true
    thunkAPI.dispatch(getExams());
    try {
      const res = await ExamService.get();
      thunkAPI.dispatch(getExamsSucess(res.data));
    } catch (error) {
      // Set any erros while trying to fetch
      thunkAPI.dispatch(getExamFailure());
    }
  }
);

export const fetchExamByProgramId = createAsyncThunk(
  "exams/fetchExamByProgramId",
  async (input, thunkAPI) => {
    // Set loading to true
    thunkAPI.dispatch(getExams());
    try {
      const res = await ExamService.getById(input);
      thunkAPI.dispatch(getExamByProgramIdSuccess(res.data));
    } catch (error) {
      // Set any erros while trying to fetch
      thunkAPI.dispatch(getExamFailure());
    }
  }
);

export const setExam = (id) => (dispatch, getState) => {
  const exams = getState().examsSlice.examsByProgramId;
  exams?.forEach((item) => {
    item.id === id && dispatch(getExam(item));
    return;
  });
};

export const clearExams = () => (dispatch) => {
  dispatch(clearExamsState());
};
