import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function get() {
  return apiCall({
    url: `${ENDPOINT.program}`,
    method: "GET",
  });
}

const ProgramService = {
  get,
};

export default ProgramService;
