export default function swapDiffColumnsElements(dragEndResult, startColumn, finishColumn, columns) {
  const { source, destination, draggableId } = dragEndResult;

  // remove element from start column
  const startCodeLinesIdsArray = [...startColumn.codeLineIds];
  startCodeLinesIdsArray.splice(source.index, 1);
  const newStartColumn = {
    ...startColumn,
    codeLineIds: startCodeLinesIdsArray,
  };
  // insert element to destination column
  const finishCodeLinesIdsArray = [...finishColumn.codeLineIds];
  finishCodeLinesIdsArray.splice(destination.index, 0, draggableId);
  const newFinishColumn = {
    ...finishColumn,
    codeLineIds: finishCodeLinesIdsArray,
  };

  // create ad return new columns object to override old info
  return {
    ...columns,
    [newStartColumn.id]: newStartColumn,
    [newFinishColumn.id]: newFinishColumn,
  };
}