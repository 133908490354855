import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchExams, setSelectedExam } from "../../store/slices/sme";
import { Grid, Typography, Box, Button } from "@mui/material";
import { LINKS } from "../../constants/links";
import { useStyles } from "./styles/Sme.styles";

function SmeExams() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedExam, exams } = useSelector((state) => {
    const { selectedExam, selectedOption, exams } = state.smeSlice;
    return { selectedExam, selectedOption, exams };
  });
  const styles = useStyles();

  useEffect(() => {
    dispatch(fetchExams());
  }, [dispatch, selectedExam]);

  const handleExamClick = useCallback(
    (exam) => {
      dispatch(setSelectedExam(exam));
      navigate(LINKS.smeSelectAction);
    },
    [dispatch]
  );

  const renderCta = () => {
    return (
      <>
        {!!exams &&
          exams.map(({ examCode, examDisplayCode }, index) => (
            <Button
              key={index}
              className={styles.margin}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleExamClick(examCode)}
            >
              {`${examCode} - ${examDisplayCode}`}
            </Button>
          ))}
      </>
    );
  };

  return (
    <Box className={styles.boxMiddle}>
      <Grid container align="center" justifyContent="center">
        <Grid className={styles.continer} item xs={8}>
          <Typography align="center" variant="h5">
            <Box fontWeight="fontWeightBold">ASSIGNED EXAMS</Box>
          </Typography>

          {!exams && (
            <Typography align="center" variant="h5">
              <Box fontWeight="fontWeightBold">
                You don't have any exams assigned
              </Box>
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {renderCta()}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SmeExams;
