import React, { useContext } from 'react';
// plugins
import { Draggable } from 'react-beautiful-dnd';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/prism';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getSyntaxLanguage } from 'utils/syntax-highlighter';

// styles
import './CodeLine.styles.css';
import { ParsonsContext } from '../../context';
import IconItem from './IconItem';

// component start
const CodeLine = ({ codeLine, index, columnId }) => {
  const { updateTab, language } = useContext(ParsonsContext);

  const onSpaceButtonClick = (direction) => {
    let newCodeLine = null;

    if (direction === 'left' && codeLine?.content && codeLine?.content.startsWith('    ')) {
      const newContent = codeLine.content.slice(4);
      newCodeLine = {
        ...codeLine,
        content: newContent,
      };
    }

    if (direction === 'right') {
      const newContent = '    ' + codeLine.content;
      newCodeLine = {
        ...codeLine,
        content: newContent,
      };
    }

    if (newCodeLine) updateTab(newCodeLine);
  };

  if (!codeLine) return <div />;

  // render
  return (
    <Draggable
      draggableId={codeLine.id}
      index={index}
    >
      {(provided, snapshot) => {
        // concat codeLine classes depends of dragging state state
        let codeLineClass = 'kps-pqcl-container';
        if (snapshot.isDragging) codeLineClass += ` kps-pqcl-dragging-state`;
        // concat button classes depends of dragging state state
        let buttonWrapClasses = 'kps-pqcl-buttons-wrapper';
        if (columnId === 'Answer') buttonWrapClasses += ' result-column';
        if (snapshot.isDragging) buttonWrapClasses += ` kps-pqcl-display-none`;

        return (
          <div
            className={'kps-pqcl-code-line-wrapper'}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {/*buttons*/}
            <div className={`${buttonWrapClasses} par-bwr`}>
              <IconItem
                iconName="ChevronLeft"
                onClick={() => onSpaceButtonClick('left')}
              />
              <IconItem
                iconName="ChevronRightSpace"
                onClick={() => onSpaceButtonClick('right')}
              />
            </div>
            {/*code line*/}
            <div
              ref={provided.innerRef}
              className={`${codeLineClass} par-cline-wrap`}
            >
              {/*SyntaxHighlighter plugin*/}
              <SyntaxHighlighter
                language={getSyntaxLanguage(language)}
                style={a11yDark}
              >
                {codeLine.content}
              </SyntaxHighlighter>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default CodeLine;