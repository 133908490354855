import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
    '& button': {
      width: 'calc((100% - 20px)/2)',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '12px',
      '& button': {
        '&:first-child': {
          marginRight: '10px',
        },
      },
    },
  },
  icon: {
    width: 18,
    height: 18
  }
}));
