import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function getGroups() {
  return apiCall({
    url: `${ENDPOINT.group}`,
    method: "GET",
  });
}

function removedUserFromAllGroups(userID) {
  return apiCall({
    url: `${ENDPOINT.currentGroup}/remove-user/${userID}`,
    method: "PATCH",
  });
}

function getMyGroups() {
  return apiCall({
    url: `${ENDPOINT.currentGroup}`,
    method: "GET",
  });
}

function getGroupById(id) {
  return apiCall({
    url: `${ENDPOINT.currentGroup}/${id}`,
    method: "GET",
  });
}

function createGroup(data) {
  return apiCall({
    url: `${ENDPOINT.currentGroup}`,
    method: "POST",
    data: {
      ...data,
    },
  });
}

function updateGroup(body) {
  const { id } = body;
  delete body["id"];
  return apiCall({
    url: `${ENDPOINT.currentGroup}/${id}`,
    method: "PATCH",
    data: {
      ...body,
    },
  });
}

function updateGroupStatus(body) {
  return apiCall({
    url: `${ENDPOINT.currentGroup}/${body.id}`,
    method: "PATCH",
    data: {
      status: body.status,
    },
  });
}

function checkTestingGroupCode(code, examCode) {
  return apiCall({
    url: `${ENDPOINT.checkGroupCode}${code}/${examCode}`,
    method: "GET",
  });
}

const GroupService = {
  getGroups,
  getGroupById,
  createGroup,
  updateGroup,
  updateGroupStatus,
  getMyGroups,
  removedUserFromAllGroups,
  checkTestingGroupCode,
};

export default GroupService;
