import React, {useCallback, useEffect, useState} from "react"
import {makeStyles} from "@mui/styles"

import {Navigate, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {
  Typography,
  Grid,
  CircularProgress,
  Paper,
  Box,
  Divider,
  Backdrop
} from "@mui/material"
import {PrevNextSection} from "../../components"
import {LINKS} from "../../constants/links"
import {getOrganizationById} from "../../store/slices/organization"

import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong"
import GroupIcon from "@mui/icons-material/Group"
import EmailIcon from "@mui/icons-material/Email"
import HomeIcon from "@mui/icons-material/Home"
import LocationCityIcon from "@mui/icons-material/LocationCity"
import PublicIcon from "@mui/icons-material/Public"
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox"
import AssignmentIcon from "@mui/icons-material/Assignment"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center"
  },
  Loader: {
    minHeight: "60vh"
  },
  ExamContainer: {
    padding: theme.spacing(5),
    minWidth: "55%",
    maxWidth: "55%"
  },
  VerificationTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  ContentContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  ExamDetail: {
    display: "flex",
    padding: theme.spacing(1)
  },
  Icon: {
    marginRight: "5px"
  },
  Details: {
    color: theme.palette.text.primary
  },
  DetailsContent: {
    color: theme.palette.secondary.main
    // fontWeight: '300',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  }
}))

const ExamsSelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const {exam} = useSelector((state) => state).examsSlice
  const {group} = useSelector((state) => state).groupSlice
  const {organization, loading} = useSelector((state) => state.organization)

  const {
    root,
    backdrop,
    ExamContainer,
    VerificationTitle,
    DividerContainer,
    DividerBar,
    ContentContainer,
    ExamDetail,
    Icon,
    Details,
    DetailsContent
  } = useStyles()

  useEffect(() => {
    if (group.organizationId) {
      dispatch(getOrganizationById(group.organizationId))
    }
  }, [group.organizationId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrev = useCallback(() => {
    navigate(LINKS.examsSelection)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = useCallback(() => {
    navigate(LINKS.examsConfirmPrivacy)
  }, [organization, group]) // eslint-disable-line react-hooks/exhaustive-deps

  const capitalizeFirstLetter = (s) => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const getAddressIcon = (address) => {
    switch (address) {
      case "address":
        return <HomeIcon className={Icon} />
      case "city":
        return <LocationCityIcon className={Icon} />
      case "country":
        return <PublicIcon className={Icon} />
      case "postcode":
        return <MarkunreadMailboxIcon className={Icon} />
      default:
    }
  }

  const renderAddress = () => {
    if (typeof user?.address == "string") {
      const addressIcon = getAddressIcon("address")
      return (
        <Grid className={ContentContainer} item xs={6}>
          <Box className={ExamDetail}>
            {addressIcon}
            <Typography className={Details} key="address 0">
              <span className={DetailsContent}>{user.address}</span>
            </Typography>
          </Box>
        </Grid>
      )
    }

    return <></>
  }

  if (!exam?.code) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  return (
    <>
      <Backdrop className={backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={ExamContainer}>
          <Typography
            className={VerificationTitle}
            align="center"
            variant="h5"
            color="primary"
          >
            Please verify the following details
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar} />
          </Box>
          <Grid container>
            <Grid className={ContentContainer} item xs={6}>
              {exam?.displayName && (
                <Box className={ExamDetail}>
                  <AssignmentIcon className={Icon} />
                  <Typography color="secondary" className={Details}>
                    Exam Name:{" "}
                    <span className={DetailsContent}>{exam?.displayName}</span>
                  </Typography>
                </Box>
              )}
              {organization?.name && (
                <Box className={ExamDetail}>
                  <CenterFocusStrongIcon className={Icon} />
                  <Typography className={Details}>
                    Test Center:{" "}
                    <span className={DetailsContent}>{organization?.name}</span>
                  </Typography>
                </Box>
              )}
              {group?.name && (
                <Box className={ExamDetail}>
                  <GroupIcon className={Icon} />
                  <Typography className={Details}>
                    Group Name:{" "}
                    <span className={DetailsContent}>{group?.name}</span>
                  </Typography>
                </Box>
              )}
              {(user?.firstName) && (
                <Box className={ExamDetail}>
                  <AccountCircleIcon color="inherit" className={Icon} />
                  <Typography className={Details}>
                    Name:{" "}
                    <span className={DetailsContent}>
                      {user.firstName}
                    </span>
                  </Typography>
                </Box>
              )}
              {(user?.lastName) && (
                <Box className={ExamDetail}>
                  <AccountCircleIcon className={Icon} />
                  <Typography className={Details}>
                    Surname:{" "}
                    <span className={DetailsContent}>
                      {user.lastName}
                    </span>
                  </Typography>
                </Box>
              )}
              {(user?.email) && (
                <Box className={ExamDetail}>
                  <EmailIcon className={Icon} />
                  <Typography className={Details}>
                    Email:{" "}
                    <span className={DetailsContent}>
                      {user.email}
                    </span>
                  </Typography>
                </Box>
              )}
            </Grid>
            {renderAddress()}
          </Grid>
          <PrevNextSection handlePrev={handlePrev} handleNext={handleNext} />
        </Paper>
      </Grid>
    </>
  )
}

export default ExamsSelection
