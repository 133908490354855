import React, { useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography
} from "@mui/material";

import { getOrganizationById } from "../../../store/slices/organization";

const useStyles = makeStyles({
  root: {
    maxWidth: "700px",
    margin: "0 auto"
  },
  table: {
    minWidth: 200
  },
  active: {
    color: "blue"
  },
  title: {
    padding: "0 0 1rem"
  }
});

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organization);
  const { userOrganizationId } = useSelector((state) => state.user);

  useEffect(() => {
    if (!organization) {
      dispatch(getOrganizationById(userOrganizationId));
    }
  }, [dispatch, organization, userOrganizationId]);

  const rows = [
    { name: "organization id", value: organization?.id },
    { name: "organization", value: organization?.name },
    { name: "account status", value: organization?.status }
  ];

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid
        item
        container
        direction="column"
        md={8}
        sm={12}
        className={classes.root}
      >
        <Typography variant="h5" className={classes.title}>
          Dashboard:
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={row.name + i}>
                  <TableCell component="th" scope="row">
                    {row.name.toUpperCase()}:
                  </TableCell>
                  <TableCell
                    style={{ width: 260 }}
                    align="left"
                    className={row.value === "active" && classes.active}
                  >
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
