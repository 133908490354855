import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  rating: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    justifyContent: 'space-between'
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  textF: {
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    color: 'black',
    fontSize: '16px',
  },
  selectLabel: {
    color: 'black',
    fontSize: '22px',
  },
}));
