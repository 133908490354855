import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GroupService from 'services/groupService';
import RolesService from 'services/roles.service';
import { addMinutes } from 'date-fns';
import LicenseService from 'services/license.service';
import ProgramService from 'services/program';
import ExamService from 'services/exam';
import { openAlert } from 'store/slices/alert';
import { getNotifications } from 'store/slices/notifications';

const InitStateGroup = {
  status: '',
  studentIds: [],
  teacherIds: [],
  proctorIds: [],
  name: '',
  programId: '',
  examId: '',
  licenseId: '',
  organizationId: '',
  typeProctoring: '',
  startTime: null,
  endTime: null,
  errorMessage: '',
  testingGroupCode: null,
  examusDisabledByCode: null,
  examCode: null,
};

export const initialState = {
  loading: false,
  hasErrors: false,
  group: InitStateGroup,
  allGroups: [],
  allMyGroups: [],
  allLicense: [],
  allTeachers: [],
  allStudents: [],
  allProctors: [],
  allPrograms: [],
  allExams: [],
  successResponse: null,
};

export const getGroup = createAsyncThunk('group/getGroup', async (id) => {
  if (id) {
    const res = await GroupService.getGroupById(id);
    // const {studentIds, teacherIds, proctorIds} = res.data;
    const [
      allLicense,
      allTeachers,
      allStudents,
      allProctors,
      allPrograms,
      allExams,
    ] = await Promise.all([
      // RolesService.getAllUsersGroupAdditionalInfoByRole(studentIds),
      // RolesService.getAllUsersGroupAdditionalInfoByRole(teacherIds),
      // RolesService.getAllUsersGroupAdditionalInfoByRole(proctorIds),
      LicenseService.getLicense(),
      RolesService.getUsersByRole('teacher'),
      RolesService.getUsersByRole('student'),
      RolesService.getUsersByRole('proctor'),
      ProgramService.get(),
      ExamService.get(),
    ]);
    return {
      group: {
        ...res.data,
        // studentIds: students.data,
        // teacherIds: teachers.data,
        // proctorIds: proctors.data,
        startTime: res.data.startTime
          ? new Date(res.data.startTime)
          : new Date(),
        endTime: res.data.endTime
          ? new Date(res.data.endTime)
          : addMinutes(new Date(), 45),
      },
      allAdditionalData: {
        allLicense: allLicense.data,
        allTeachers: allTeachers.data,
        allStudents: allStudents.data,
        allProctors: allProctors.data,
        allPrograms: allPrograms.data,
        allExams: allExams.data,
      },
    };
  } else {
    const res = await GroupService.getGroups();
    return res.data;
  }
});

export const updateGroupStatus = createAsyncThunk(
  'group/updateGroupStatus',
  async (body) => {
    const res = await GroupService.updateGroupStatus(body);
    return res.data;
  },
);
export const checkTestingGroupCodeThunk = createAsyncThunk(
  'group/checkTestingGroupCodeThunk',
  async ({ code, examCode }) => {
    try {
      const res = await GroupService.checkTestingGroupCode(code, examCode);
      const response = res?.data || res;
      return { ...response, testingGroupCode: code, examCode };
    } catch (res) {
      const msg = "Group Code is invalid. Please contact your proctor for the correct code.";
      throw new Error(msg || res);
    }
  },
);

export const updateGroup = createAsyncThunk(
  'group/updateGroup',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoading());
      const res = await GroupService.updateGroup(body);

      dispatch(stopLoading());
      dispatch(getNotifications());
      return res.data;
    } catch (e) {
      dispatch(
        openAlert({
          severity: 'error',
          message: 'Something goes wrong!',
        }),
      );
      return rejectWithValue(e);
    }
  },
);

export const createGroup = createAsyncThunk(
  'group/createGroup',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoading());
      const res = await GroupService.createGroup(data);

      dispatch(stopLoading());
      dispatch(getNotifications());
      return res.data;
    } catch (e) {
      dispatch(
        openAlert({
          severity: 'error',
          title: 'Something goes wrong!',
        }),
      );
      return rejectWithValue(e);
    }
  },
);

export const getAdditionalDataForCreateExamGroup = createAsyncThunk(
  'group/AdditionalDataForCreateExamGroup',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(startLoading());
      const [
        allLicense,
        allTeachers,
        allStudents,
        allProctors,
        allPrograms,
        allExams,
      ] = await Promise.all([
        LicenseService.getLicense(),
        RolesService.getUsersByRole('teacher'),
        RolesService.getUsersByRole('student'),
        RolesService.getUsersByRole('proctor'),
        ProgramService.get(),
        ExamService.get(),
      ]);
      thunkAPI.dispatch(stopLoading());
      return {
        allAdditionalData: {
          allLicense: allLicense.data,
          allTeachers: allTeachers.data,
          allStudents: allStudents.data,
          allProctors: allProctors.data,
          allPrograms: allPrograms.data,
          allExams: allExams.data,
        },
      };
    } catch (e) {
    } finally {
    }
  },
);

export const getMyGroups = createAsyncThunk(
  'group/getMyGroups',
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearError());
      thunkAPI.dispatch(startLoading());
      const res = await GroupService.getMyGroups();
      thunkAPI.dispatch(setMyGroups(res.data));
      thunkAPI.dispatch(stopLoading());
      // return res.data;
    } catch (e) {
      thunkAPI.dispatch(stopLoading());
      thunkAPI.dispatch(setError());
    } finally {
    }
  },
);

export const removeUserFromAllGroups = createAsyncThunk(
  'group/removeUserFromAllGroups',
  async (userId) => {
    await GroupService.removedUserFromAllGroups(userId);
    return true;
  },
);

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    saveFormFields: (state, { payload }) => {
      state.group[payload.name] = payload.value;
    },
    clearAllFormFields: (state) => {
      state.group = InitStateGroup;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setMyGroups: (state, { payload }) => {
      state.allMyGroups = payload;
    },
    setError: (state) => {
      state.hasErrors = true;
    },
    setExamusDisabledByCode(state, { payload }) {
      state.examusDisabledByCode = payload;
    },
    clearError: (state) => {
      state.hasErrors = false;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    //pending
    [getGroup.pending ||
      updateGroupStatus.pending ||
      updateGroup.pending ||
      createGroup.pending ||
      removeUserFromAllGroups.pending ||
      checkTestingGroupCodeThunk.pending ||
      getAdditionalDataForCreateExamGroup.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
      },
    //fulfilled
    [getGroup.fulfilled]: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.allGroups = payload;
      } else {
        state.group = payload.group;
        state.allLicense = payload.allAdditionalData.allLicense;
        state.allTeachers = payload.allAdditionalData.allTeachers;
        state.allStudents = payload.allAdditionalData.allStudents;
        state.allProctors = payload.allAdditionalData.allProctors;
        state.allPrograms = payload.allAdditionalData.allPrograms;
        state.allExams = payload.allAdditionalData.allExams;
      }
      state.loading = false;
    },
    [getAdditionalDataForCreateExamGroup.fulfilled]: (state, { payload }) => {
      state.allLicense = payload.allAdditionalData.allLicense;
      state.allTeachers = payload.allAdditionalData.allTeachers;
      state.allStudents = payload.allAdditionalData.allStudents;
      state.allProctors = payload.allAdditionalData.allProctors;
      state.allPrograms = payload.allAdditionalData.allPrograms;
      state.allExams = payload.allAdditionalData.allExams;
      state.loading = false;
      state.hasErrors = false;
    },
    [updateGroup.fulfilled ||
      updateGroupStatus.fulfilled ||
      createGroup.fulfilled]: (state, { payload }) => {
        state.successResponse = payload;
        state.loading = false;
      },
    [removeUserFromAllGroups.fulfilled]: (state) => {
      state.loading = false;
    },
    [checkTestingGroupCodeThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.requireSafeExamBrowser = payload?.requireSafeExamBrowser || false;
      state.testingGroupCode = payload?.testingGroupCode || null;
      state.examCode = payload?.examCode || null;
      state.examusDisabledByCode = true;
    },
    //rejected
    [getGroup.rejected ||
      updateGroupStatus.rejected ||
      updateGroup.rejected ||
      createGroup.rejected ||
      removeUserFromAllGroups.rejected ||
      getAdditionalDataForCreateExamGroup.rejected]: (state) => {
        state.loading = false;
        state.hasErrors = true;
      },
    [checkTestingGroupCodeThunk.rejected]: (state, { error }) => {
      state.loading = false;
      state.errorMessage = error?.message;

    },
  },
});

export const {
  saveFormFields,
  clearAllFormFields,
  startLoading,
  stopLoading,
  setMyGroups,
  setError,
  clearError,
  setExamusDisabledByCode,
} = groupSlice.actions;

export default groupSlice.reducer;
