import React, { useState, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import { Navigate, useNavigate } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Divider,
  Typography,
  Paper
} from "@mui/material";
import { PrevNextSection } from "components";
import { LINKS } from "../../constants/links";
import Policy from "../Policy/Policy";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center",
  },
  agreementContainer: {
    padding: theme.spacing(5),
    maxWidth: "60%"
  },
  formControl: {
    marginTop: theme.spacing(2)
  },
  agreementTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  privacyContainer: {
    overflow: "auto",
    maxHeight: "35vh",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px"
  },
  pos: {
    marginBottom: 12
  }
}));

const AGREE = "AGREE";
const DISAGREE = "DISAGREE";

function ExamsConfirmPrivacy() {
  const {
    root,
    agreementContainer,
    privacyContainer,
    formControl,
    agreementTitle,
    DividerContainer,
    DividerBar
  } = useStyles();
  const navigate = useNavigate();

  const examCode = useSelector((state) => state?.examsSlice?.exam?.code);

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === AGREE) {
      setError(false);
      navigate(LINKS.examsSelectGroup);
    } else if (value === DISAGREE) {
      setError(true);
    } else {
      setError(true);
    }
  };

  const handlePrev = useCallback(() => {
    navigate(LINKS.examsVerify);
  }, []);

  if (!examCode) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={agreementContainer}>
        <Typography
          className={agreementTitle}
          align="center"
          variant="h5"
          color="primary"
        >
          Non-disclosure Agreement and Knowledge Pillars Exam Agreement
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>
        <Box
          boxShadow={1}
          // container
          // alignItems="center"
          // justifyContent="center"
          className={privacyContainer}
        >
          <Policy />
        </Box>

        <form>
          <FormControl
            component="fieldset"
            error={error}
            className={formControl}
          >
            <FormLabel component="legend"></FormLabel>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={value}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                color="primary"
                value={AGREE}
                control={<Radio color="secondary" />}
                label={<Typography color="textPrimary">I agree</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </form>
        <PrevNextSection
          handlePrev={handlePrev}
          handleNext={handleSubmit}
          disabledNext={value !== AGREE}
        />
      </Paper>
    </Grid>
  );
}

export default ExamsConfirmPrivacy;
