import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { closeAlert } from "store/slices/alert";

import { Snackbar } from "@mui/material";
import { Alert as AlertComponent } from "@mui/material";
import AlertTitle from "@mui/lab/AlertTitle";

const Alert = () => {
  const dispatch = useDispatch();
  const { vertical, horizontal, open, message, title, severity, variant } =
    useSelector((state) => state.alert);

  const handleClose = () => {
    dispatch(closeAlert());
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 10000);
    }
  }, [open]); // eslint-disable-line

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      severity={severity}
      children={
        <AlertComponent
          severity={severity}
          variant={variant}
          onClose={() => handleClose()}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </AlertComponent>
      }
      key={vertical + horizontal}
    />
  );
};

export default Alert;
