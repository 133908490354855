import React, { useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Link,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({}));

const ErrorMessage = (props = {}) => {
  const styles = useStyles();

  const { message } = props
  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction={"column"}
      rowSpacing={2}
      px={10}
      py={12}
    >
      <Grid item xs={12} sx={{overflow: 'hidden'}}>
        <Typography variant="h5">Something went wrong :(</Typography>

        <Box component={"p"}>
          {message
            ? <Box sx={{ overflow: "scroll" }}>
              <Grid container direction={"row"} justifyContent={"flex-start"}>
                <Grid item pr={1}>{'Error message:'}</Grid>
                <Grid component={"code"} xs={12} item sx={{
                  whiteSpace: 'pre-line'
                }}>
                  {message}
                </Grid>
              </Grid>
            </Box>
            : null
          }
        </Box>
        You can try to <Link href="javascript:;" onClick={() => refreshPage()}>reload page</Link>. If the issue persists please contact the support team at <Link underline="hover" href="mailto:support@knowledge-pillars.com">support@knowledge-pillars.com</Link>.
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
