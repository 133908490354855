import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { CustomTable } from "components";
import ReviewQuestionForm from "./ReviewQuestionForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing.unit,
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing.unit
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: `${theme.spacing(3)}px`
  }
}));

function ScoreQuestionsList() {
  const classes = useStyles();
  const smeSlice = useSelector((state) => state.smeSlice);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const columns = [
    { id: "number", label: "Number", minWidth: 100, align: "left" },
    { id: "task.displayName", label: "Name", minWidth: 100, align: "left" },
    { id: "taskReview.status", label: "Status", align: "left" },
    { id: "taskReview.score", label: "Score", align: "left" }
  ];

  const handleCLickRow = (e, id) => {
    const selectedQuestion = smeSlice.examQuestions.find((q) => q.id === id);
    setSelectedQuestion(selectedQuestion);
  };

  return (
    <div>
      <Grid container spacing={24}>
        <Grid item xs={8}>
          <Typography variant="h5">{smeSlice.setSelectedExam}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={24}>
        <CustomTable
          rows={smeSlice.examQuestions}
          columns={columns}
          tableClass={classes.rootTable}
          containerHeight={400}
          handleClickRow={handleCLickRow}
        />
        {selectedQuestion && (
          <ReviewQuestionForm question={selectedQuestion}></ReviewQuestionForm>
        )}
      </Grid>
    </div>
  );
}

export default ScoreQuestionsList;
