import React from "react";
import { groupModal } from "./styles";
import { Typography, Grid } from "@mui/material";
import { TableSelect as CustomSelect, SelectAutocomplete } from "components";
import { checkSelect, groupsKeys } from "utils/validation";

const GroupMiddleSection = (props) => {
  const {
    handleChange,
    group,
    optionsLicense,
    optionsTeacher,
    optionsExam,
    optionsProgram,
    attempt
  } = props;
  const classes = groupModal(group.asyncProctoring);

  return (
    <>
      <Grid item xs={12} sm={4} container spacing={8}>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Select License:{" "}
          </Typography>

          <CustomSelect
            className={classes.select}
            variant="outlined"
            align={"center"}
            name="licenseId"
            label="Select"
            value={group.licenseId}
            handleChange={(e) => {
              handleChange({
                target: { name: "licenseId", value: e.target.value }
              });
            }}
            arrSelectOptions={optionsLicense}
            helperText={
              attempt && checkSelect(group[groupsKeys.LICENSE.key])
                ? checkSelect(group[groupsKeys.LICENSE.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.LICENSE.key])}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} container>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Assign Program:{" "}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            className={classes.select}
            variant="outlined"
            align={"center"}
            name="programId"
            label="Select"
            value={group.programId}
            handleChange={(e) => {
              handleChange({
                target: { name: "programId", value: e.target.value }
              });
            }}
            arrSelectOptions={optionsProgram}
            helperText={
              attempt && checkSelect(group[groupsKeys.PROGRAM.key])
                ? checkSelect(group[groupsKeys.PROGRAM.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.PROGRAM.key])}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Exam:
          </Typography>
          <SelectAutocomplete
            className={classes.select}
            arrSelectOptions={optionsExam}
            name="examId"
            label="Search or select"
            value={group.examId}
            handleChange={(_, v) => {
              handleChange({
                target: { name: "examId", value: v?.value }
              });
            }}
            helperText={
              attempt && checkSelect(group[groupsKeys.EXAM.key])
                ? checkSelect(group[groupsKeys.EXAM.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.EXAM.key])}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} container>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ textAlign: "left" }}
            className={classes.label}
          >
            Assign Teacher:
          </Typography>

          <SelectAutocomplete
            className={classes.select}
            arrSelectOptions={optionsTeacher}
            name="teacherIds"
            label="Search or select"
            value={group.teacherIds[0]}
            handleChange={(_, v) => {
              handleChange({
                target: {
                  name: "teacherIds",
                  value: v?.value ? [v.value] : []
                }
              });
            }}
            helperText={
              attempt && checkSelect(group[groupsKeys.TEACHERS.key])
                ? checkSelect(group[groupsKeys.TEACHERS.key])
                : ""
            }
            error={attempt && checkSelect(group[groupsKeys.TEACHERS.key])}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GroupMiddleSection;
