import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(5)
  },
  prevButtonContainer: {
    alignItems: "flex-start",
    display: "flex"
  },
  nextButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

const PrevNextSection = ({
  handlePrev,
  handleNext,
  PrevText = "Previous",
  NextText = "Next",
  disabledPrev = false,
  disabledNext = false
}) => {
  const { buttonsContainer, button, prevButtonContainer, nextButtonContainer } =
    useStyles();

  return (
    <Grid className={buttonsContainer} container>
      <Grid item xs={6} className={prevButtonContainer}>
        {handlePrev && (
          <Button
            variant="outlined"
            color="primary"
            className={button}
            onClick={handlePrev}
            disabled={disabledPrev}
            size="large"
          >
            {PrevText}
          </Button>
        )}
      </Grid>
      <Grid item xs={6} className={nextButtonContainer}>
        {handleNext && (
          <Button
            variant="contained"
            color="primary"
            className={button}
            onClick={handleNext}
            disabled={disabledNext}
            size="large"
          >
            {NextText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PrevNextSection;
