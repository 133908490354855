import * as Sentry from "@sentry/react"
import {Amplify} from "aws-amplify"
import {amplifyConfig} from "config"
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import {Provider} from "react-redux"
import {BrowserRouter as Router} from "react-router-dom"
import {CookieStorage} from "aws-amplify/utils"
import {cognitoUserPoolsTokenProvider} from "aws-amplify/auth/cognito"

import App from "./App"
import store from "./store"

const kpTarget = /\.knowledge-pillars\.com/

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "development",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", kpTarget]
    }),
    new Sentry.Replay({
      maskAllText: false,
      networkDetailAllowUrls: [kpTarget]
    })
  ],
  sampleRate: 0.3,
  tracesSampleRate: 0.3,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Amplify.configure(amplifyConfig)

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: window.location.hostname,
  sameSite: "none"
}))

if (typeof Object.hasOwn !== "function") {
  Object.defineProperty(Object, "hasOwn", {
    value: function (obj: object, prop: PropertyKey): boolean {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    },
    writable: true,
    configurable: true,
    enumerable: false
  })
}

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
)
