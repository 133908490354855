import { makeStyles } from "@mui/styles";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${left}%)`
  };
}

const modal = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minHeight: 120,
    backgroundColor: "#fff",
    padding: theme.spacing(2, 4, 3)
  },
  paperTable: {
    position: "absolute",
    marginTop: "4rem",
    minWidth: 400,
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  modalHead: {
    backgroundColor: "#CDCDCD",
    padding: "1.2rem 1rem",
    display: "flex",
    justifyContent: "space-between"
  },
  popUpClose: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  buttonFlagged: {
    backgroundColor: theme.palette.info.light,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
      boxShadow: "0px 3px 5px rgb(128,128,128, 0.4)"
    },
    color: "white"
  },
  body: {
    maxHeight: "80vh",
    overflow: "auto"
  },
  tableRow: {
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0f75bc3b"
    }
  },
  bottomWrapper: {
    padding: "1rem 0"
  }
}));

const examsSessionWindow = makeStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    position: "absolute",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    top: 0,
    left: 0,
    width: "100%"
  },
  scoreCard: {
    margin: "0 auto"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff !important",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column"
  },
  progressBar: {
    marginTop: theme.spacing(4)
  },
  loadingMessage: {
    paddingLeft: "80px",
    paddingRight: "80px"
  },
  //Styles applied after refactoring

  //globally used
  modalContent: {
    padding: theme.spacing(3, 0)
  },
  modalTitleContainer: {
    paddingBottom: 0
  },
  modalTitle: {
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "40%",
    backgroundColor: theme.palette.kpYellowColors.main
  },

  // SphereEngineExam.js
  sphereEngineWorkSpace: {
    position: "relative",
    marginTop: "90px",
    marginBottom: "116px",
    height: "calc(100vh - 206px)"
  },
  pdfWrapper: {
    maxHeight: "100%",
    overflow: "auto"
  },
  sphereEngineFooter: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    bottom: 0,
    zIndex: 1,
    position: "fixed",
    background: "white",
    minWidth: "980px"
  },

  //WordpressExam.js
  wpWorkSpace: {
    position: "relative",
  },
  wpWorkSpaceFullHeight: {
    minHeight: "100vh"
  },
  wpWorkSpaceHeight: {
    marginTop: "91px",
    marginBottom: "173px"
  },
  wpWorkSpaceFullHeightStart: {
    height: "calc(100vh - 264px)",
    marginTop: "91px",
    marginBottom: "173px"
  },
  wpFooterMax: {
    height: "173px"
  },
  wpFooterMin: {
    height: "113px",
  },
  wpFooter: {
    minWidth: "980px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    bottom: 0,
    zIndex: 1,
    position: "fixed",
    background: "white"
  },
  loadingBarContainer: {
    minHeight: "4px",
    background: theme.palette.grey[200]
  },

  //ExainationWindow.js
  noCodeContainer: {
    display: "flex",
    // flexDirection: "row",
    justifyContent: "center",
    // maxWidth: '50%',
    alignItems: "center",
    height: "100vh"
  },
  noCodeContainerMsg: {
    padding: theme.spacing(8, 4)
  },
  noCodeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(3)
  }
}));

export { getModalStyle, modal, examsSessionWindow };
