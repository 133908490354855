import React, { useState } from "react";
import { forgotPasswordKeys, getInitialState } from "../../utils/validation";
import { Container } from "@mui/material";
import ForgotEmail from "./components/ForgotEmail";
import ForgotConfirm from "./components/ForgotConfirm";
import ForgotReset from "./components/ForgotReset";
import ForgotSuccess from "./components/ForgotSucces";

export const CurrentState = {
  EMAIL: "EnterEmail",
  CONFIRM: "EnterConfirmCode",
  RESET: "ResetPassword",
  SUCCESS: "ResetSuccsess"
};

function ForgotPassword() {
  const [currentState, setCurrentState] = useState(CurrentState.EMAIL);
  const [values, setValues] = useState(getInitialState(forgotPasswordKeys));

  const getCurrentPage = () => {
    switch (currentState) {
      case CurrentState.EMAIL:
        return (
          <ForgotEmail
            setCurrentState={setCurrentState}
            setValues={setValues}
            values={values}
          />
        );
      case CurrentState.CONFIRM:
        return (
          <ForgotConfirm
            setCurrentState={setCurrentState}
            setValues={setValues}
            values={values}
          />
        );
      case CurrentState.RESET:
        return (
          <ForgotReset
            setCurrentState={setCurrentState}
            setValues={setValues}
            values={values}
          />
        );
      case CurrentState.SUCCESS:
        return <ForgotSuccess />;
      default:
        return null;
    }
  };

  return <Container>{getCurrentPage()}</Container>;
}

export default ForgotPassword;
