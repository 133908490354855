import React from "react";
import { Modal } from "@mui/material";

export default function CustomModal(props) {
  const {
    children,
    className,
    handleClose,
    open,
    hideBackdrop = false
  } = props;
  const body = <div className={className}>{children(props)}</div>;

  return (
    <div>
      <Modal
        disableEnforceFocus={true}
        disableAutoFocus={true}
        hideBackdrop={hideBackdrop}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
