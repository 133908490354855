import React from "react";
import { makeStyles } from "@mui/styles";
import { Snackbar, Typography, Button, Box, Tooltip } from "@mui/material";
import MuiAlert from "@mui/lab/Alert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const useStyles = makeStyles((theme) => ({
  snackbarContainer: {
    background: theme.palette.white.main,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.5, 0.5, 0.5, 1.5),
    border: "2px solid red",
  },
  detailsContainerParent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  detailsContainer: {
    marginTop: theme.spacing(2),
    width: "fit-content"
  },
  details: {
    width: "fit-content"
  },
  detailsItem: {
    display: "flex",
    padding: theme.spacing(0.5)
  },
  detailsIcon: {
    marginRight: theme.spacing(0.5)
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end"
  },
  snackbarCredRoot: {
    bottom: "60px",
  },
}));

const SnackbarCredentials = ({ open, handleClose, username, password }) => {
  const {
    snackbarContainer,
    buttonContainer,
    detailsContainerParent,
    detailsContainer,
    details,
    detailsIcon,
    detailsItem,
    snackbarCredRoot
  } = useStyles();

  return (
    <Snackbar 
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      className={snackbarCredRoot}
    >
      <MuiAlert
        elevation={3}
        variant="filled"
        action={false}
        icon={false}
        className={snackbarContainer}
      >
        <Typography>
          <b>
            Please copy/paste to examination window the username & password:
          </b>
        </Typography>
        <Box className={detailsContainerParent}>
          <Box className={detailsContainer}>
            <Tooltip title="username" placement="left">
              <Box className={detailsItem}>
                <PermIdentityIcon className={detailsIcon} />
                <Typography className={details}>{username}</Typography>
              </Box>
            </Tooltip>
            <Tooltip title="password" placement="left">
              <Box className={detailsItem}>
                <LockOpenIcon className={detailsIcon} />
                <Typography className={details}>{password}</Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box className={buttonContainer}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </Box>
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarCredentials;
