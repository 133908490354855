import React, { lazy } from "react";
import { makeStyles } from "@mui/styles";
import { Modal, Backdrop, Fade } from "@mui/material";
import PDF from "../../assets/pdf/examDocs/PCA_DOC.pdf";
const PDFembed = lazy(() => import("../../components/PDFembed/PDFembed"));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  pdfWrapper: {
    maxHeight: "98vh",
    overflow: "auto",
    border: 0
  }
}));

export default function CustomPdfModal({ open, setOpen }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} ${classes.pdfWrapper}`}>
            <PDFembed file={PDF} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
