import React, { memo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetUpdatedUserSuccess } from "store/slices/user";
import {
  getOrganizationById,
  updateOrganizationInfo
} from "store/slices/organization";
import { makeStyles } from "@mui/styles";

import {
  updateOrganizationInfoKeys,
  billingAddressKeys,
  getInitialState,
  checkEmail,
  checkEmptyInput,
  checkPhone,
  checkWebsiteUrl
} from "utils/validation";

import {
  Button,
  Card,
  TextField,
  Typography,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  CircularProgress
} from "@mui/material";

import { CardMessage, SuccessMessage } from "components";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "0 !important"
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },

  card: {
    minWidth: 275,
    margin: "3rem 0 0",
    padding: "1rem 0"
  },
  tableCell: {
    paddingLeft: "3rem"
  },
  textField: {
    margin: "1rem 2.3rem"
  },
  dashbordCell: {
    padding: "1rem 2rem "
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  title: {
    marginRight: "auto",
    padding: "0 0 1rem"
  },
  radioWrapper: {
    margin: "1rem 2.3rem"
  },
  errMessage: {
    color: "red"
  }
}));

const exeptionFields = [
  "email_verified",
  "sub",
  "address",
  "phone_number_verified",
  "updatedAt",
  "status",
  "id",
  "name",
  "billingAddress"
];

export const TextFieldItem = ({
  name,
  label,
  itemValue,
  handleChange,
  attempt,
  values
}) => {
  const validate = {
    website: checkWebsiteUrl(values[updateOrganizationInfoKeys.WEBSITE.key]),
    contactEmail: checkEmail(values[updateOrganizationInfoKeys.EMAIL.key]),
    country: checkEmptyInput(values[updateOrganizationInfoKeys.COUNTRY.key]),
    address1: checkEmptyInput(values[updateOrganizationInfoKeys.ADDRESS.key]),
    postalCode: checkEmptyInput(
      values[updateOrganizationInfoKeys.POSTCODE.key]
    ),
    city: checkEmptyInput(values[updateOrganizationInfoKeys.CITY.key]),
    phone: checkPhone(values[updateOrganizationInfoKeys.PHONE.key])
  };

  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      label={label}
      id="standard-size-small"
      size="small"
      name={name}
      value={itemValue}
      helperText={attempt && validate[name] ? validate[name] : ""}
      error={attempt && validate[name]}
      onChange={(e) => handleChange(e)}
    />
  );
};

function OrganProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { organization, hasErrors, loading, updateSuccess } = useSelector(
    (state) => state.organization
  );
  const { userOrganizationId: organizationId } = useSelector(
    (state) => state.user
  );

  const [radioValue, setRadioValue] = useState("");
  const [attempt, setAttemt] = useState(false);
  const [active, setToggleActive] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [values, setValues] = useState(
    getInitialState(updateOrganizationInfoKeys)
  );
  const [billingAdr, setBillingAdr] = useState(
    getInitialState(billingAddressKeys)
  );

  const getValues = (value, userRow) => {
    Object.keys(value).forEach((item) => {
      if (!exeptionFields.includes(item)) {
        return userRow.push({ name: item, value: value[item] });
      }
    });
  };

  const setValue = (sketch, valueOrganizations) => {
    const newValue = { ...sketch };
    valueOrganizations
      .reverse()
      .forEach((item) => (newValue[item.name] = item.value));
    return newValue;
  };

  const getUserInfoDataForRows = useCallback(() => {
    const userRow = [];
    const additionAddress = [];

    if (null === organization) {
      return;
    }

    getValues(organization, userRow);

    if (organization["billingAddress"]) {
      getValues(organization["billingAddress"], additionAddress);
    }

    setValues(setValue(values, userRow));
    setBillingAdr(setValue(billingAdr, additionAddress));

    if (!organization["billingAddress"]) {
      setRadioValue("main");
    } else {
      setRadioValue("additional");
    }
  }, [organization, values]); // eslint-disable-line

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      });
    },
    [values]
  );

  const handleChangeAddress = useCallback(
    (e) => {
      setBillingAdr({
        ...billingAdr,
        [e.target.name]: e.target.value
      });
    },
    [billingAdr]
  );

  const fetchProfileData = useCallback(async () => {
    if (!organizationId) return;
    dispatch(resetUpdatedUserSuccess());
    try {
      if (!organization) {
        dispatch(getOrganizationById(organizationId));
      }
    } catch (err) {
      setErrMessage(err);
    }
  }, [organizationId, organization, dispatch]);

  useEffect(() => {
    fetchProfileData();
    getUserInfoDataForRows();
  }, [organizationId, organization]); // eslint-disable-line

  useEffect(() => {
    if (hasErrors) {
      setErrMessage();
    }
  }, [hasErrors]);

  const handleChangeRadio = useCallback(
    (event) => {
      const targetVal = event.target.value;
      setRadioValue(targetVal);
      const isAditinal = "additional" === targetVal;
      setBillingAdr({ ...billingAdr, sameAsMainAddress: !isAditinal });
    },
    [setRadioValue, billingAdr, setBillingAdr]
  );

  const handleSubmit = useCallback(async () => {
    setToggleActive(false);
    if (
      checkWebsiteUrl(values[updateOrganizationInfoKeys.WEBSITE.key]) ||
      checkEmail(values[updateOrganizationInfoKeys.EMAIL.key]) ||
      checkEmptyInput(values[updateOrganizationInfoKeys.COUNTRY.key]) ||
      checkEmptyInput(values[updateOrganizationInfoKeys.ADDRESS.key]) ||
      checkEmptyInput(values[updateOrganizationInfoKeys.POSTCODE.key]) ||
      checkEmptyInput(values[updateOrganizationInfoKeys.CITY.key]) ||
      checkPhone(values[updateOrganizationInfoKeys.PHONE.key])
    ) {
      setAttemt(true);
      setToggleActive(true);
      return;
    }

    setToggleActive(false);
    const newState = { ...values, billingAddress: billingAdr };
    dispatch(updateOrganizationInfo(newState, organizationId));
    setToggleActive(true);
    return false;
  }, [values, billingAdr, organizationId]); // eslint-disable-line

  const getRows = () => {
    return [
      { name: "organization id", value: organization.id },
      { name: "organization", value: organization.name },
      { name: "account status", value: organization.status }
    ];
  };

  if (organization === null) {
    return <div></div>;
  }
  if (updateSuccess === "SUCCESS") {
    return <SuccessMessage />;
  }
  if (hasErrors) {
    return <CardMessage message={hasErrors} />;
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.title}>
          Organization Profile:
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableBody>
                  {getRows().map((item) => {
                    return (
                      <TableRow key={item.name}>
                        <TableCell className={classes.tableCell}>
                          {item.name.toUpperCase()}:
                        </TableCell>
                        <TableCell className={classes.dashbordCell}>
                          {item.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Card className={classes.card}>
              {Object.keys(updateOrganizationInfoKeys)
                .slice(0, 7)
                .map((item, index) => {
                  return (
                    <TextFieldItem
                      key={updateOrganizationInfoKeys[item].key + index}
                      label={updateOrganizationInfoKeys[item].label}
                      name={updateOrganizationInfoKeys[item].key}
                      itemValue={values[updateOrganizationInfoKeys[item].key]}
                      values={values}
                      handleChange={handleChange}
                      attempt={attempt}
                    />
                  );
                })}

              <FormControl
                component="fieldset"
                className={classes.radioWrapper}
              >
                <FormLabel component="legend">BILLING ADDRESS:</FormLabel>
                <RadioGroup
                  aria-label="biling_address"
                  name="biling_address1"
                  value={radioValue}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value="main"
                    control={<Radio />}
                    label="Use main address"
                  />
                  <FormControlLabel
                    value="additional"
                    control={<Radio />}
                    label="Additional address"
                  />
                </RadioGroup>
              </FormControl>
            </Card>

            {radioValue !== "main" && (
              <Grid container>
                <Typography
                  variant="h5"
                  className={classes.title}
                  style={{ paddingTop: "3rem" }}
                >
                  Additional Billing Info:
                </Typography>
                <Card className={classes.card} style={{ marginTop: "0" }}>
                  {Object.values(updateOrganizationInfoKeys)
                    .filter((item) => undefined !== item.address)
                    .map((item, index) => {
                      return (
                        <TextFieldItem
                          key={item.key + index}
                          label={item.label}
                          name={item.key}
                          itemValue={billingAdr[item.key]}
                          values={billingAdr}
                          handleChange={handleChangeAddress}
                          attempt={attempt}
                        />
                      );
                    })}
                </Card>
              </Grid>
            )}
          </Grid>

          {errMessage && (
            <Grid container>
              <Grid item className={classes.errMessage}>
                {errMessage}
              </Grid>
            </Grid>
          )}

          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={!active}
            >
              UPDATE ORGANIZATION PROFILE
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default memo(OrganProfile);
