import { makeStyles } from "@mui/styles";

const groupModal = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  label: {
    fontWeight: 600,
    fontSize: "16px",
    padding: "0.5rem 0",
  },
  select: {
    minWidth: "200px",
    margin: "0 0 0.4rem 0",
  },
  loader: {
    height: "100%",
  },
  title: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  sub: {
    color: "rgb(130,130,130)",
  },
}));

export { groupModal };
