import React, { Suspense, lazy } from "react";
import { Button, Box, Grid, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PDF from "../../../assets/pdf/instructions.pdf";
const PDFembed = lazy(() => import("../../../components/PDFembed/PDFembed"));

const useStyle = makeStyles((theme) => ({
  fullScreenContainer: {
    position: "absolute",
    minHeight: "100vh",
    backgroundColor: "white",
    zIndex: 10,
  },
  container: {
    position: "fixed",
    display: "flex",
    bottom: "0.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px"
  },
  fullScreenRoot: {
    boxShadow: "none !important"
  },
  root: {
    width: "100%",
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper
  },
  cardActions: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  cardItem: {
    padding: "1rem"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    padding: "0.5rem 1rem 0",
    justifyContent: "space-between"
  },
  btnHint: {
    backgroundColor: theme.palette.primary.light,
    padding: "0.3rem",
    minWidth: "2rem",
    maxWidth: "2rem",
    maxHeight: "2rem",
    borderRadius: "13px",
    margin: "0 0 0.5rem 0.5rem"
  },
  submitBtnContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4)
  },
  submitBtn: {
    padding: "3px 12px",
    fontSize: 16,
    background: "#66bb6a",
    width: "200px",
    marginBottom: "0.3rem",
    "&:hover": {
      background: "#00bb6a"
    }
  },
  PDFloader: {
    heigth: "550px"
  },
  snackbarContainer: {
    background: theme.palette.white.main,
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2)
  },
  snackbarHead: {
    display: "flex"
  },
  snackbarTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  }
}));

const ExamInstructions = ({
  handleClick,
  fullScreen,
  btnText = "START EXAM"
}) => {
  const styles = useStyle();

  // TODO: Refactoring of the entire component!

  return (
    <Grid
      container
      direction="column"
      className={fullScreen ? styles.fullScreenContainer : styles.container}
    >
      {fullScreen ? (
        <Suspense
          fallback={
            <div className={styles.PDFloader}>
              <CircularProgress color="inherit" />
            </div>
          }
        >
          <PDFembed
            file={PDF}
            description={
              "Please read the exam instructions before starting the session"
            }
          />
          <Box className={styles.submitBtnContainer}>
            <Button
              className={styles.submitBtn}
              variant="contained"
              color="primary"
              onClick={() => handleClick()}
            >
              {btnText}
            </Button>
          </Box>
        </Suspense>
      ) : null}
    </Grid>
  );
};

export default ExamInstructions;
