import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../../store/slices/user";
import { makeStyles } from "@mui/styles";
import { ROLES } from "constants/constants";
import {
  AppBar,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CodeIcon from "@mui/icons-material/Code";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { checkScroll } from "utils/checkScroll";
import logo from "assets/svg/knowledge-pillars-pogo.svg";
import { LINKS } from "constants/links";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 100,
    width: "100%",
    height: "72px",
    top: 0,
    left: 0
  },
  appBar: {
    backgroundColor: 'white'
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[200]
    //     : theme.palette.grey[800]
  },
  menu: {
    marginTop: "3rem",
    display: "flex"
  },
  img: {
    maxWidth: "20rem",
    margin: "0.5rem 1rem",
    cursor: "pointer"
  },
  userName: {
    fontSize: "17px",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  iconButton: {
    paddingRight: ({ open, scroll }) => open && scroll && "22px",
    "& svg": {
      fontSize: "19px"
    }
  },
  menuIcon: {
    marginRight: "7px",
    fontSize: "20px",
    color: theme.palette.text.primary
  },
  menuItem: {
    fontSize: "15px",
    color: theme.palette.primary.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    paddingLeft: "2rem"
  },
  selectEmpty: {
    paddingTop: theme.spacing(1)
  }
}));

export default function MenuAppBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPathRole = location.pathname;
  const navigate = useNavigate();

  const { user, userRole } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectValue] = useState(
    userRole ? userRole : ROLES.USER
  );
  const open = Boolean(anchorEl);
  const scroll = Boolean(checkScroll("Height"));
  const classes = useStyles({ open, scroll });

  const handleMenu = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleLogo = useCallback(() => {
    navigate(LINKS.smeSelectAction);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogout = useCallback(() => {
    setAnchorEl(null);
    dispatch(logout());
    navigate(LINKS.main);
  }, [dispatch, setAnchorEl]);

  const handleRedirectUpdate = useCallback(() => {
    navigate(LINKS.updateUserAccount);
  }, []);

  const handleRedirectResults = useCallback(() => {
    navigate(LINKS.results);
  }, []);

  const handleChangeSelect = useCallback(
    (event) => {
      const value = event.target.value;
      setSelectValue(value);
      if (userRole === ROLES.ADMIN && value === ROLES.ADMIN) {
        navigate(LINKS.adminPanelDashboard);
      }
      if (userRole === ROLES.ADMIN && value === ROLES.USER) {
        navigate("/");
      }
    },
    [userRole]
  );

  useEffect(() => {
    if (
      userRole === ROLES.ADMIN &&
      currentPathRole.slice(0, 5) === `/${ROLES.USER}`
    ) {
      setSelectValue(ROLES.ADMIN);
    }
    if (
      userRole === ROLES.ADMIN &&
      currentPathRole.slice(0, 5) === `/${ROLES.USER}`
    ) {
      setSelectValue(ROLES.USER);
    }
  }, [userRole, currentPathRole]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt="" className={classes.img} onClick={handleLogo} />

          {userRole === "admin" && (
            <Grid container item justifyContent="flex-start" alignItems="center">
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={selectValue}
                  onChange={handleChangeSelect}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={ROLES.USER}>user</MenuItem>
                  <MenuItem value={ROLES.ADMIN}>admin</MenuItem>
                </Select>
                <FormHelperText>account view mode</FormHelperText>
              </FormControl>
            </Grid>
          )}

          {user && (
            <Grid container item sx={{justifyContent: 'flex-end'}} alignItems="center">
              {/* <Notifications /> */}
              <Typography
                variant="h6"
                color="primary"
                className={classes.userName}
              >
                {user}
              </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
                className={classes.iconButton}
              >
                {open ? <MenuOpenIcon color="primary"/> : <MenuIcon color="primary"/>}
              </IconButton>

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                className={classes.menu}
                // id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleRedirectUpdate}>
                  <PersonOutlineIcon
                    color="primary"
                    className={classes.menuIcon}
                  />
                  <span className={classes.menuItem}>My Profile</span>
                </MenuItem>
                <MenuItem onClick={handleRedirectResults}>
                  <CodeIcon color="primary" className={classes.menuIcon} />
                  <span className={classes.menuItem}>My Results</span>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ExitToAppIcon color="primary" className={classes.menuIcon} />
                  <span className={classes.menuItem}>Logout</span>
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
