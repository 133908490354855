import { useMemo } from 'react';

const useQuestionIndex = (questions, question) => {
  return useMemo(() => {
    return (questions && question)
      ? questions.findIndex((el) => (el.id === question.id) || (el.id === question._id))
      : -1;
  }, [questions, question]);
}

export default useQuestionIndex;

