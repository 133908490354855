import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  margin: {
    "&:not(:last-child)" : {
      marginRight: theme.spacing(6)
    }
  },
  continer: {
    marginBottom: theme.spacing(6),
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 32,
    height: 32
  },
  boxMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 160px)",
    width: "100%"
  }
}));