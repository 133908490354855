import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_BASE_URL.replace(/\/api$/, "/graphql"),
});

const authLink = setContext(async (_, { headers }) => {
  let idtoken: string;

  try {
    idtoken = (await fetchAuthSession()).tokens.idToken.toString();
  } catch {
    idtoken = undefined;
  }

  return {
    headers: {
      ...headers,
      idtoken,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
