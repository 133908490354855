import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { CustomTable } from "../../../components";

import {
  getAllLicenses,
  updateLicenseStatus
} from "../../../store/slices/license";

import { LINKS } from "../../../constants/links";
import { Grid, Typography } from "@mui/material";

const selectOpt = [
  {
    value: "active",
    label: "Active"
  },
  {
    value: "inactive",
    label: "Inactive"
  }
];

const columns = [
  {
    id: "examsRemain",
    label: "Remaining Exams",
    minWidth: 100,
    align: "center"
  },
  { id: "description", label: "Description", minWidth: 100 },
  {
    id: "select",
    label: "Status",
    minWidth: 100,
    align: "center"
  }
];

const useStyles = makeStyles({
  title: {
    padding: "0 0 1rem"
  },
  formFootTxt: {
    color: "#3f51b5",
    textAlign: "end",
    cursor: "pointer"
  },
  textInactive: {
    cursor: "default",
    color: "black"
  },
  footContainer: {
    margin: "2rem 0"
  }
});

function Licenses() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { license } = useSelector((state) => state.licenseData.license);
  const { loading: statusUpdating } = useSelector(
    (state) => state.licenseData.currentLicense
  );
  const [licenseIdChanging, setLicenseIdChanging] = useState(null);

  useEffect(() => {
    dispatch(getAllLicenses());
  }, [dispatch]);

  const handleChangeStatus = (event, id) => {
    const status = event.target.value;
    setLicenseIdChanging(id);
    dispatch(updateLicenseStatus({ id, status })).then(() => {
      dispatch(getAllLicenses());
    });
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item container direction="column" xl={5} sm={11} md={6}>
        <Typography variant="h5" className={classes.title}>
          License:
        </Typography>

        <CustomTable
          idChanging={licenseIdChanging}
          statusUpdating={statusUpdating}
          rows={license}
          columns={columns}
          handleChangeSelect={handleChangeStatus}
          selectOpt={selectOpt}
        />

        <Grid
          item
          container
          className={classes.footContainer}
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography
              href="#"
              variant="body2"
              className={classes.formFootTxt}
            >
              <span className={classes.textInactive}>
                {"Do you need more licences?"}{" "}
              </span>
              <a href={LINKS.kpContactUs} target="_blank" rel="noreferrer">
                Please Contact Us
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Licenses;
