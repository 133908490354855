import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { USER_GROUP } from "../constants/constants";

const useRoleRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { userGroups } = useSelector((state) => state.user);

  useEffect(() => {
    const {pathname} = location;
    const isSME = userGroups.includes(USER_GROUP.SME);
    const isADMIN = userGroups.includes(USER_GROUP.ADMIN);
    const isSmePath = pathname.startsWith("/sme");
    const isAdminPath = pathname.startsWith("/admin");
    const accessDenied = (isAdminPath && !isADMIN) || (isSmePath && !(isADMIN || isSME))

    if (accessDenied) {
      navigate("/");
    }
  }, [location, userGroups]);

  return;
};

export default useRoleRedirect;
