import React from "react";
import { Button } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useStyles } from "./CustomPagination.style";

const CustomPagination = ({
  isFirstQuestion,
  isLastQuestion,
  onPreviousButtonClick,
  onNextButtonClick
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={onPreviousButtonClick}
        disabled={isFirstQuestion}
      >
        <ArrowBackIos className={classes.icon} />
        Previous
      </Button>

      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={onNextButtonClick}
      >
        {!isLastQuestion ? "Next" : "Finish"}
        <ArrowForwardIos className={classes.icon} />
      </Button>
    </div>
  );
};

export default CustomPagination;
