import React from "react";
import { makeStyles } from "@mui/styles";
import { Backdrop, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const BackdropLoad = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoad;
