import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

const client = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

const apiCall = async function (options) {
  //get token from localStore

  const idtoken = (await fetchAuthSession()).tokens.idToken.toString();
  const onSuccess = function (response) {
    return response.data;
  };
  const onError = function (error) {
    return Promise.reject(error.response || error.message);
  };

  const idToken = {
    headers: {
      idtoken,
    },
  };

  return client({ ...options, ...idToken })
    .then(onSuccess)
    .catch(onError);
};

export default apiCall;
