import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import "./index.css";
export function HtmlTerms() {
  return (
    <div className="WordSection1">
      {/* <p className="MsoNormal" style={{marginBottom: '0in', lineHeight: 'normal'}}><b><span style={{fontSize: '20.0pt', fontFamily: '"Arial",sans-serif', color: '#454545'}}>Non-disclosure
              Agreement and Knowledge Pillars Exam Agreement</span></b></p> */}
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            &nbsp;
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Updated: December 2020
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span
          style={{
            fontSize: "9.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          PLEASE READ THE KNOWLEDGE PILLARS EXAM AGREEMENT(“AGREEMENT”)
          CAREFULLY. BY CLICKING ON THE “YES” BUTTON, YOU ACKNOWLEDGE THAT YOU
          HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT AND YOU AGREE TO ALL
          THE TERMS AND CONDITIONS IN THIS AGREEMENT. YOU MUST SELECT “YES” TO
          TAKE THE KNOWLEDGE PILLARS CERTIFICATION EXAM.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span
          style={{
            fontSize: "9.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoListParagraph"
        style={{
          marginBottom: "0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </b>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            CONFIDENTIALITY
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span
          lang="RU"
          style={{
            fontSize: "9.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          Keeping Knowledge Pillars Certification exam confidential helps
          maintain the value of the Knowledge Pillars Certifications and helps
          ensure only qualified individuals are certified.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          This exam is Knowledge Pillars confidential and proprietary
          information and is protected by trade secret law and other applicable
          laws. It is made available to You,&nbsp; the Examinee, solely to test
          Your knowledge and competency in the exam subject matter.&nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          Any disclosure of the exam or information related to the exam,
          including exam questions, answers, content, computations, diagrams,
          drawings or worksheets (“Exam Related Information”) is strictly
          prohibited. You will not disclose, distribute, copy, display, publish,
          summarise, photograph, record, download, transmit or post the exam or
          any Exam Related Information, in whole or in part, in any form or by
          any means now known or hereafter devised.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span
          style={{
            fontSize: "9.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoListParagraph"
        style={{
          marginBottom: "0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </b>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            CANDIDATE CONDUCT POLICY&nbsp;&nbsp;
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          You will not participate in any behaviour that could compromise the
          confidentiality or results of any Knowledge Pillars exam. Proctors are
          authorized to take immediate and appropriate measures against
          individuals who violate testing rules. You may be prohibited from
          taking any Knowledge Pillars exams and your exam results may be
          invalidated if Knowledge Pillars believes You violated this Agreement
          or engaged in any misconduct and no exam refund will be provided.{" "}
        </span>
        <span
          lang="RU"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          Examples of misconduct include:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          lang="RU"
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <ul style={{ marginTop: "0in" }} type="disc">
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            providing access to, disclosing, distributing, copying,
            photographing, recording, downloading, posting, displaying,
            publishing or transmitting any exam or Exam Related Information in
            any form or by any means
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            misrepresenting Your identity or impersonating another individual
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            lang="RU"
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            misrepresenting Your country of residence
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            modifying or altering the exam, exam score or any exam record
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            submitting any work or answers that are not entirely Your own
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            providing or receiving improper assistance during the exam
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            seeking or obtaining unauthorised access to Knowledge Pillars exams
            or Exam Related Information (this includes using brain-dump material
            or unauthorized publication of exam questions with or without
            answers).
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            possessing non-authorized items while taking an exam, including
            mobile phones, electronic devices, laptops, watches, notes,
            documentation or content.
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            misconduct as determined by statistical analysis.
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            attempting to reverse engineer, decompile or disassemble any exam
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            engaging in any other misconduct that could compromise the
            confidentiality, integrity or security of the exam.
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            violating any Knowledge Pillars or exam delivery provider’s policy,
            requirement or rule.&nbsp;
          </span>
        </li>
      </ul>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          Knowledge Pillars reserves the right to invalidate your exam score if
          Knowledge Pillars determines you were not compliant with this section.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span
          style={{
            fontSize: "9.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoListParagraph"
        style={{
          marginBottom: "0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            3.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </b>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            EXAM POLICY
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          You are solely responsible for understanding and complying with
          Knowledge Pillars exam policies and procedures located at{" "}
        </span>
        <span lang="RU">
          <a href="https://knowledge-pillars.com/exam-policies/">
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Arial",sans-serif',
                color: "#E4AF0A"
              }}
            >
              https://knowledge-pillars.com/exam-policies/
            </span>
          </a>
        </span>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          {" "}
          as well as the exam delivery provider’s policies and procedures.&nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          You can review the Knowledge Pillars Retake Policy as well as other
          Knowledge Pillars exam and testing procedures at{" "}
        </span>
        <span lang="RU">
          <a href="https://knowledge-pillars.com/exam-policies/">
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Arial",sans-serif',
                color: "#E4AF0A"
              }}
            >
              https://knowledge-pillars.com/exam-policies/
            </span>
          </a>
        </span>
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          {" "}
          . You can learn more about the exam provider’s policies and procedures
          at the exam provider’s website.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          You have thirty (30) days after taking an exam to challenge your exam
          score for that exam.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}>
          &nbsp;
        </span>
      </p>
      <p
        className="MsoListParagraph"
        style={{
          marginBottom: "0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            4.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </b>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            EXAM RETAKE POLICY
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: ".25in",
          marginRight: "0in",
          marginBottom: "4.0pt",
          marginLeft: "0in",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          General Knowledge Pillars Certification exam retake policy
        </span>
      </p>
      <ul style={{ marginTop: "0in" }} type="disc">
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginTop: "24.0pt",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            If a candidate does not achieve a passing score on an exam the
            candidate must wait 20 days before retaking the exam. At that time,
            candidates can log onto their{" "}
          </span>
          <span lang="RU" style={{ color: "windowtext" }}>
            <span
              lang="EN-US"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Arial",sans-serif',
                color: "#454545"
              }}
            >
              certification dashboard
            </span>
          </span>
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            {" "}
            and reschedule the exam themselves.
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            A candidate may not take a given exam any more than five times per
            year (12 months). This 12-month period starts from the first
            attempt. The candidate is eligible to retake the exam 12 months from
            the date of their first attempt.
          </span>
        </li>
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginBottom: "24.0pt",
            textAlign: "justify",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            To take a given exam more than five times per year or to have the
            time between attempts waived, a candidate must have experienced one
            of the errors listed below and request an exception from Knowledge
            Pillars.{" "}
          </span>
          <span
            lang="RU"
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            Requests should be sent to{" "}
          </span>
          <span lang="RU" style={{ color: "windowtext" }}>
            <a href="mailto:support@knowledge-pillars.com">
              <b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Arial",sans-serif',
                    color: "#1155CC"
                  }}
                >
                  support@knowledge-pillars.com
                </span>
              </b>
            </a>
          </span>
          <b>
            <span
              lang="RU"
              style={{
                fontSize: "12.0pt",
                fontFamily: '"Arial",sans-serif',
                color: "#3E86CA"
              }}
            >
              .&nbsp;
            </span>
          </b>
        </li>
      </ul>
      <p
        className="MsoNormal"
        style={{
          marginTop: "24.0pt",
          marginRight: "0in",
          marginBottom: "24.0pt",
          marginLeft: "0in",
          textIndent: ".5in",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          These requests must include the following information:
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "24.0pt",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Candidate ID
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            First/Last Name
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Email associated with your Candidate ID
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Exam Registration ID
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Exam Number
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Description of error
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "24.0pt",
          marginLeft: "1.0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <span
          lang="RU"
          style={{ fontSize: "10.0pt", fontFamily: "Symbol", color: "#454545" }}
        >
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            Knowledge Pillars case number
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "24.0pt",
          marginRight: "0in",
          marginBottom: "24.0pt",
          marginLeft: "0in",
          textIndent: ".5in",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          Exam retake exceptions will be granted in case of Knowledge Pillars
          server crash.
        </span>
      </p>
      <ul style={{ marginTop: "0in" }} type="disc">
        <li
          className="MsoNormal"
          style={{
            color: "#454545",
            marginTop: "24.0pt",
            marginBottom: "24.0pt",
            lineHeight: "normal",
            verticalAlign: "baseline"
          }}
        >
          <span
            style={{ fontSize: "12.0pt", fontFamily: '"Arial",sans-serif' }}
          >
            Exam retake exceptions will NOT be granted for failure due to a
            candidate’s lack of preparation, knowledge, skill, or ability or if
            the request does NOT include a Knowledge Pillars case number.
          </span>
        </li>
      </ul>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{
          marginBottom: "0in",
          textIndent: "-.25in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            5.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </b>
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            ACADEMIC EXAM
          </span>
        </b>
      </p>
      <p
        className="MsoListParagraphCxSpLast"
        style={{
          marginBottom: "0in",
          lineHeight: "normal",
          verticalAlign: "baseline"
        }}
      >
        <b>
          <span
            lang="RU"
            style={{
              fontSize: "12.0pt",
              fontFamily: '"Arial",sans-serif',
              color: "#454545"
            }}
          >
            &nbsp;
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          If You registered to take an academic exam, You must provide the exam
          provider with documentation that verifies You are currently enrolled
          as a student at a faculty member of an accredited academic institution
          before You can take the exam.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          By taking an academic exam, You acknowledge that You are either a
          student or faculty member of an accredited academic institution. If it
          is determined You were not a Student or a Faculty member at the time
          You took an academic exam, Knowledge Pillars reserves the right to
          invalidate Your exam results, and no exam refund will be provided.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal"
        }}
      >
        <span
          style={{
            fontSize: "12.0pt",
            fontFamily: '"Arial",sans-serif',
            color: "#454545"
          }}
        >
          This agreement shall be construed and controlled by the laws of the
          State of Delaware, and Examinee further consents to jurisdiction by
          the state and federal courts sitting in the State of
          Delaware.&nbsp;&nbsp;
        </span>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span
          style={{
            fontSize: "12.0pt",
            lineHeight: "115%",
            fontFamily: '"Arial",sans-serif'
          }}
        >
          &nbsp;
        </span>
      </p>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#424242",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: theme.palette.grey[50]
  }
}));

const Policy = () => {
  const { root } = useStyles();
  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container item xs={12} md={12} className={root}>
          <HtmlTerms />
        </Grid>
      </Grid>
    </>
  );
};

export default Policy;
