import React from "react";
import { Button, Grid } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backButton: {
    color: "red",
    paddingBottom: "0 !important",
    "& > button": {
      fontSize: "14px"
    }
  },
  icon: {
    fontSize: "18px"
  }
}));

const BackButton = ({ buttonLabel, handleBackClick, size }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.backButton}>
      <Button size={size} color="primary" onClick={handleBackClick}>
        <ArrowBackIos className={classes.icon} />
        {buttonLabel}
      </Button>
    </Grid>
  );
};

export default BackButton;
