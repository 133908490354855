import React from "react";
import { useStyles } from "./ObjectiveInfo.styles";
import { Typography } from "@mui/material";

const ObjectiveInfo = ({ examObjective, examSubObjective }) => {
  const classes = useStyles();
  return (
    <div className={classes.objective}>
      <Typography className={classes.title}>{examObjective}</Typography>
      <div className={classes.info}>
        <span>{examSubObjective}</span>
      </div>
    </div>
  );
};

export default ObjectiveInfo;
