import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import SmeService from '../../services/sme.service';
import TaskEvaluationService from '../../services/taskEvaluation.service';

export const initialState = {
  loading: false,
  hasErrors: false,
  exams: null,
  selectedExam: null,
  examQuestions: null,
  selectedOption: null,
  selectedQuestion: null,
  currentTask: null
};

export const fetchExams = createAsyncThunk(
  "sme/fetchExams",
  async () => {
    const response = await SmeService.getSmeAssignedExams();
    return response.data;
  }
);

export const fetchTask = createAsyncThunk(
  "sme/fetchTask",
  async (taskId) => {
    const response = await SmeService.getSmeTask(taskId);
    return response.data;
  }
);

export const fetchExamQuestions = createAsyncThunk(
  "sme/examQuestions",
  async (examCode) => {
    const response = await SmeService.getSmeExamQuestions(examCode);
    return response.data;
  }
);

export const taskEvaluationScore = createAsyncThunk(
  "sme/taskEvaluationScore",
  async (body) => {
    const response = await TaskEvaluationService.taskEvaluationScore(body);
    return response.data;
  }
);


export const taskEvaluationReview = createAsyncThunk(
  "sme/taskEvaluationReview",
  async (body) => {
    const response = await TaskEvaluationService.taskEvaluationReview(body);
    return response.data;
  }
);

export const setSmeSelectedOption = (smeAction) => async (dispatch) => {
  dispatch(setSmeSelectedAction(smeAction));
}

export const setSelectedExam = (exam) => async (dispatch) => {
  dispatch(setSelectedExamAction(exam));
  return dispatch(fetchExamQuestions(exam));
}

export const setSelectedQuestion = (question) => async (dispatch) => {
  dispatch(setSelectedQuestionAction(question));
}

export const setSelectedTask = (task) =>(dispatch) => {
  dispatch(setSelectedTask(task))
}

const smeSlice = createSlice({
  name: "smeSlice",
  initialState,
  reducers: {
    setSmeSelectedAction: (state, action ) => {
      state.selectedOption = action.payload;
    },
    setSelectedExamAction: (state, action)=> {
      state.selectedExam = state.exams.find((e)=> e.examCode===action.payload);
    },
    setSelectedQuestionAction: (state, action)=>{
      state.selectedQuestion = action.payload;
    }
  },
  extraReducers: {
    [fetchExams.pending]: (state) => {
      state.loading = true;
    },
    [fetchExams.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [fetchExams.fulfilled]: (state, action) => {
      state.exams = action.payload[0]?.smeExams;
      state.loading = false;
      state.hasErrors = false;
    },
    [fetchExamQuestions.pending]: (state) => {
      state.loading = true;
    },
    [fetchExamQuestions.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [fetchExamQuestions.fulfilled]: (state, action) => {
      state.examQuestions = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    [taskEvaluationScore.pending]: () => {
    },
    [taskEvaluationScore.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [taskEvaluationScore.fulfilled]: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    [taskEvaluationReview.pending]: () => {
    },
    [taskEvaluationReview.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [taskEvaluationReview.fulfilled]: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    [fetchTask.pending]: (state) => {
      state.loading=true;
    },
    [fetchTask.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [fetchTask.fulfilled]: (state, action) => {
      state.selectedQuestion = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

// Actions generated from the slice
export const {
  setSmeSelectedAction,
  setSelectedExamAction,
  setSelectedQuestionAction
} = smeSlice.actions;


export default smeSlice.reducer;


