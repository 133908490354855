import React, { memo } from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import CodeEditor from "@knowledge-pillars-education-inc/code-editor";
import useKpEditor from "../../../hooks/tasksHooks/useKpEditor";
import { setEngineCodeAnswer } from "store/slices/examsSession";

const useStyles = makeStyles(() => ({
  spinner: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    zIndex: 2
  },
  kpsphereWrap: {
    height: "100%",
    "& .kps-out-collapse": {
      cursor: "pointer"
    }
  }
}));

const SphereEngineWorkSpace = ({ codeEditorData }) => {
  const dispatch = useDispatch();

  const { currentTask } = useSelector(
    (state) => state
  ).examsSessionSlice;

  const styles = useStyles();

  const { compileAnswer, runDisabled, onRunCode } = useKpEditor(currentTask);

  const handleRunCode = (code) => {
    dispatch(setEngineCodeAnswer(code));
    onRunCode(code);
  };

  const handleAnswerChange = value => {
    setEngineCodeAnswer(value)
  }

  return (
    <div className={styles.kpsphereWrap}>
      {!currentTask ? (
        <div className={styles.spinner}>
          <CircularProgress color="inherit" disableShrink />
        </div>
      ) : (
        <CodeEditor
          overview={{
            input: currentTask?.problem?.body
          }}
          code={{
            lang: currentTask?.practicalDetails?.language,
            template: currentTask?.practicalDetails?.template,
            input: codeEditorData,
            onRun: handleRunCode,
            submissionLoading: runDisabled,
            alert: compileAnswer
              ? {
                code: compileAnswer.status_code,
                name: compileAnswer.status_name,
                labels: {
                  15: "compiled",
                }
              }
              : null,
          }}
          compiled={
            compileAnswer?.cmpinfo ||
            compileAnswer?.error ||
            compileAnswer?.output ||
            compileAnswer?.signal_desc
          }
          onChange={handleAnswerChange}
        />
      )}
    </div>
  );
};

export default memo(SphereEngineWorkSpace);
