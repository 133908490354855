import React from "react";

import { groupModal } from "./styles";
import { TableList } from "components/TableList";

import { Button, Typography, Grid } from "@mui/material";

const GroupStudentsSection = ({
  selectedStudentsForDeleting,
  setSelectedStudentsForDeleting,
  handleOpenAddStudents,
  allStudents,
  handleChange,
  group
}) => {
  const classes = groupModal();
  return (
    <Grid item xs={12} sm={6} container>
      <Grid item xs={12} container alignContent="flex-start">
        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
          <Grid item xs={6}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ textAlign: "left" }}
              className={classes.label}
            >
              Students:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenAddStudents()}
              style={{ margin: "1rem" }}
            >
              Add+
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableList
            mode="delete"
            title={"Students"}
            rows={allStudents.filter((item) =>
              group.studentIds.includes(item.id)
            )}
            selected={selectedStudentsForDeleting}
            setSelected={setSelectedStudentsForDeleting}
            handleChange={() => {
              const newMembers = group.studentIds.filter(
                (el) => !selectedStudentsForDeleting.includes(el)
              );
              return handleChange({
                target: { name: "studentIds", value: newMembers }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupStudentsSection;
