import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function getLicense() {
  return apiCall({
    url: `${ENDPOINT.license}`,
    method: "GET",
  });
}

function postLicense(data) {
  return apiCall({
    url: `${ENDPOINT.license}`,
    method: "POST",
  });
}


function toggleStatus(body) {
  return apiCall({
    url: `${ENDPOINT.license}/${body.id}`,
    method: "PATCH",
    data: {
      status: body.status
    },
  });
}

const LicenseService = {
  getLicense,
  postLicense,
  toggleStatus
};

export default LicenseService;
