import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrganizationService from '../../services/organization';

export const initialState = {
    loading: false,
    hasErrors: false,
    organization: null,
    updateSuccess: ''
};

// A slice for posts with our three reducers
const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.updateSuccess = '';
        },
        setOrganization: (state, { payload }) => {
            state.organization = payload;
            state.loading = false;
            state.updateSuccess = '';
        },
        organizationFailure: (state, { payload }) => {
            state.loading = false;
            state.hasErrors = payload;
            state.updateSuccess = '';
        },
        updateFinishSuccess: (state) => {
            state.loading = false;
            state.hasErrors = false;
            state.updateSuccess = 'SUCCESS';
        }
    }
});

// Actions generated from the slice
export const {
    setLoading,
    setOrganization,
    organizationFailure,
    updateFinishSuccess
} = organizationSlice.actions;

// The reducer
export default organizationSlice.reducer;

export const getOrganizationById = createAsyncThunk(
    "organization/getOrganizationById",
    async (input, thunkAPI) => {
        thunkAPI.dispatch(setLoading());
        try {
            const res = await OrganizationService.getById(input);
            thunkAPI.dispatch(setOrganization(res.data));
        } catch (err) {
            thunkAPI.dispatch(organizationFailure(err.data.error.message));
        }

    }
);

export const updateOrganizationInfo = (organizInfo, id) => (dispatch) => {
         dispatch(setLoading());

         OrganizationService.update(id, organizInfo)
             .then(data => {
                 dispatch(updateFinishSuccess());
                 dispatch(getOrganizationById(id));
                 return data;
             })
             .catch(err => {
                 dispatch(organizationFailure(err.data.error.message));
                 return err;
             })

};
