import React, { useEffect, useState } from 'react';
// plugins
import { DragDropContext } from 'react-beautiful-dnd';
// components
import Column from './components/Column/Column';
// custom functions
import {
  compareLinePositions,
  removeAllIndentations,
  swapDiffColumnsElements,
  swapOneColumnElements,
} from './helpers/index';
// styles
import { ParsonsContext } from './context';
import './ParsonsQuestion.styles.css';
import ParsonsCodeSnippet from './components/ParsonsCodeSnippet/ParsonsCodeSnippet';
import { getSyntaxLanguage } from '../../utils/syntax-highlighter';

// component start
const ParsonsQuestion = ({
  codeSnippet = null,
  questionLines = [],
  description,
  displayName,
  language = 'python',
  codeSnippetLanguage = 'sql',
  handleCodeChanges = (code) => undefined,
  pluginDisabled = false,
}) => {
  // variables
  const [codeLines, setCodeLines] = useState(null);
  const [columns, setColumns] = useState(null);
  const [columnsOrder] = useState(['lines-column', 'Answer']);

  // ! start configuration building
  useEffect(() => {
    if (!questionLines) return;
    // collect ids
    let linesIds = [];
    let sortedCodeLines = questionLines.slice().sort(compareLinePositions);

    // set cooked code blocks
    const linesToSet = sortedCodeLines.reduce((acc, line) => {
      acc[line.id] = line;
      linesIds.push(line.id);
      return acc;
    }, {});
    setCodeLines(linesToSet);

    setColumns((prevColumns) => {
      return {
        ...prevColumns,
        'Answer': {
          id: 'Answer',
          title: 'Correct Code Order',
          codeLineIds: [],
        },
        'lines-column': {
          id: 'lines-column',
          title: 'Code Lines',
          codeLineIds: linesIds,
        },
      };
    });
  }, [questionLines, setColumns]);

  // ! watch columns changes and give strings back
  useEffect(() => {
    if (!(columns && codeLines)) return;

    const lineIdsArray = columns['Answer'].codeLineIds;
    if (!lineIdsArray || !lineIdsArray?.length) {
      handleCodeChanges(null);
      return;
    }

    const answer = lineIdsArray.reduce((acc, lID, index) => {
      let line = codeLines[lID];
      line = { ...line, position: index + 1 };
      acc.push(line);
      return acc;
    }, []);

    handleCodeChanges(answer);
  }, [columns, codeLines, handleCodeChanges]);

  // handlers
  const onDragEnd = (result) => {
    // movement variables
    const { source, destination, draggableId } = result;

    // dropped somewhere it can not move
    if (!destination) return;

    // location not changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const startColumn = columns[source.droppableId];
    const finishColumn = columns[destination.droppableId];
    // if movement was in one column
    if (startColumn === finishColumn) {
      const newColumns = swapOneColumnElements(result, startColumn, columns);
      setColumns(newColumns);
      return;
    }

    // remove indents if we are moving code line to Code Lines column
    if (finishColumn.id === 'lines-column') {
      const draggableCodeLine = codeLines[draggableId];
      const newCodeLine = removeAllIndentations(draggableCodeLine);
      setCodeLines((prev) => {
        return {
          ...prev,
          [draggableId]: newCodeLine,
        };
      });
    }

    // moving from one column to another
    const newColumns = swapDiffColumnsElements(result, startColumn, finishColumn, columns);
    setColumns(newColumns);
  };

  // inserting tabs
  const updateTab = (newCodeLine) => {
    setCodeLines((prev) => {
      return {
        ...prev,
        [newCodeLine.id]: newCodeLine,
      };
    });
  };

  // render
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ParsonsContext.Provider value={{ updateTab, language }}>
        {/*parsons container*/}
        {columns && <div className={`kps-pq-container par-container`}>
          {/*question description*/}
          <div className={`kps-pq-parson-header par-header`}>
            <div className={`kps-pq-question-name par-q-name`}>{displayName}</div>
            <div className={`kps-pq-question-title par-q-title`}>{description}</div>
          </div>
            {!!codeSnippet && <ParsonsCodeSnippet codeSnippetLanguage={getSyntaxLanguage(codeSnippetLanguage)} codeSnippet={codeSnippet} />}
            {/*Columns*/}
            <div className={`kps-pq-columns-wrapper par-col-wrap ${pluginDisabled ? 'plugin-disabled' : ''}`}>
              {columnsOrder && columnsOrder.map((columnId) => {
                const column = columns[columnId];
                const codeBlocks = column.codeLineIds.map((taskId) => codeLines[taskId]);
                return (
                  <Column
                    key={column.id}
                    column={column}
                    codeLines={codeBlocks}
                    updateTab={updateTab}
                  />
                );
              })}
            </div>
        </div>

        }
      </ParsonsContext.Provider>
    </DragDropContext>
  );
};

export default ParsonsQuestion;
