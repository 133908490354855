import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function addUserPayment(data) {
  return apiCall({
    url: `${ENDPOINT.payment}`,
    method: "POST",
    data: {
      ...data,
    },
  });
}

const PaymentService = {
  addUserPayment,
};

export default PaymentService;
