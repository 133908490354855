import { makeStyles } from "@mui/styles";

export const reviewTableConfig = () => [
  { id: 'code', label: 'Question ID', minWidth: 100, align: 'left' },
  { id: 'reviewStatus', label: 'Status', align: 'left' },
  { id: 'feedback', label: 'Feedback', align: 'left' },
];

export const scoreTableConfig = () => [
  { id: 'code', label: 'Item ID', minWidth: 100, align: 'left' },
  { id: 'scoreStatus', label: 'Status', align: 'left' },
  { id: 'score', label: 'Rate', minWidth: 100, aling: 'left' },
];


export const useStyles = makeStyles((theme) => ({
  backButton: {
    color: 'red',
    paddingBottom: '0 !important',
    '& > button': {
      fontSize: '14px'
    }
  },
  icon: {
    fontSize: '18px'
  },
}));
