import { ENDPOINT } from '../constants/endpoints';
import apiCall from '../api/api';

const FetchUserServiceMethods = {
  fetchUserInfo: () => {
    return apiCall({
      url: ENDPOINT.fetchUserInfo,
      method: 'GET',
    });
  }
};

const userService = {
  ...FetchUserServiceMethods
};

export default userService;