import * as Sentry from '@sentry/react';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ExamSessionService from "../../services/examSession";
import { addUserPayment } from "./payment";

export const initialState = {
  loading: false,
  loadingMoveTo: false,
  redirect: false,
  hasErrors: null,
  sessionFailureMessage: null,
  sessionData: {},
  sessionStatus: "",
  currentTask: {},
  sessionScore: {},
  scoredErr: null,
  taskNumber: null,
  startLink: null,
  multiTasksAnswers: [],
  engineCodeAnswer: null,
  finished: false,

  tasksAnswers: [],
  sessionTasksStatus: [],
  currentSessionDetails: {}
};
// A slice for posts with our three reducers
const examsSessionSlice = createSlice({
  name: "examsSession",
  initialState,
  reducers: {
    initFetching: (state) => {
      state.loading = true;
      state.hasErrors = false;
    },
    // sessionInitializeSucess: (state, { payload }) => {
    //   state.sessionData = payload;
    //   state.redirect = true;
    //   state.loading = false;
    //   state.hasErrors = false;
    // },
    sessionStartSucess: (state, { payload }) => {
      state.sessionData = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    sessionGetDetailsSucess: (state, { payload }) => {
      state.sessionData = payload;
      state.startLink = payload.startLink;
      state.loading = false;
      state.hasErrors = false;
    },
    sessionGetStatusSucess: (state, { payload }) => {
      state.sessionStatus = payload;
    },
    sessionGetScoreSucess: (state, { payload }) => {
      state.sessionScore = payload;
      state.loading = false;
      state.scoredErr = false;
    },
    scoredFailuer: (state, { payload }) => {
      state.scoredErr = payload;
    },
    // currentTaskInit: (state) => {
    //   state.loadingMoveTo = true;
    //   state.hasErrors = false;
    // },
    // currentTuskSuccess: (state, { payload }) => {
    //   localStorage.setItem('taskId', payload.task.id)
    //   state.loadingMoveTo = false;
    //   state.currentTask = payload.task;
    //   state.currentTask.details = {
    //     ...state.currentTask.details,
    //     ...payload.taskSessionDetails
    //   };
    //   if (payload.task) state.currentTask.problem = payload.problem || null;

    //   state.taskNumber = payload.taskNumber;
    // },
    submitTuskSuccess: (state, { payload }) => {
      // if (payload.nextTask) {
      //   state.currentTask = payload.nextTask.task;
      //   state.currentTask.details = {
      //     ...state.currentTask.details,
      //     ...payload.nextTask.taskSessionDetails
      //   };
      //   state.taskNumber = payload.nextTask.taskNumber;
      //   state.currentTask.problem = payload.nextTask.problem || null;
      // }
      state.loading = false;
      state.hasErrors = false;
    },
    forcedFinishSuccess: (state) => {
      state.loading = false;
      state.hasErrors = false;
      state.finished = true;
    },
    tuskToMoveSuccess: (state, { payload }) => {
      state.tuskToMove = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    setMultiTasksAnswers: (state, { payload }) => {
      state.multiTasksAnswers = payload;
    },
    setEngineCodeAnswer: (state, { payload }) => {
      state.engineCodeAnswer = payload;
    },
    clearState: () => initialState,
    sessionFailure: (state, { payload }) => {
      const err = payload;
      const errMessage = err?.message || err?.data?.error?.message || err;

      Sentry.captureException(payload)

      state.loading = false;
      state.loadingMoveTo = false;
      state.hasErrors = errMessage;
      state.sessionFailureMessage = state.sessionFailureMessage ?? errMessage
    },
    removeFlaggedTaskSucess: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    addFlaggedTaskSucess: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    getTasksAnswersSucess: (state, { payload }) => {
      state.tasksAnswers = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getSessionTasksStatusSucess: (state, { payload }) => {
      state.sessionTasksStatus = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getSessionDataSucess: (state, { payload }) => {
      state.currentSessionDetails = payload;
      state.redirect = true
      state.loading = false;
      state.hasErrors = false;
    },
    getCurrentTaskDataSucess: (state, { payload }) => {
      state.taskNumber = payload.taskIndex + 1;
      state.currentTask = payload.task;
      state.currentTask.problem = payload.problem
      state.loading = false;
      state.hasErrors = false;
    },
  }
});

export const {
  initFetching,
  // currentTaskInit,
  // sessionInitializeSucess,
  setMultiTasksAnswers,
  sessionStartSucess,
  sessionGetDetailsSucess,
  sessionGetStatusSucess,
  sessionGetScoreSucess,
  submitTuskSuccess,
  currentTuskSuccess,
  forcedFinishSuccess,
  scoredFailuer,
  clearState,
  sessionFailure,
  setEngineCodeAnswer,
  removeFlaggedTaskSucess,
  addFlaggedTaskSucess,
  getTasksAnswersSucess,
  getSessionTasksStatusSucess,
  getSessionDataSucess,
  getCurrentTaskDataSucess
} = examsSessionSlice.actions;

export default examsSessionSlice.reducer;

export const initExamSession =
  ({ examCode, testingGroupCode }) =>
    async (dispatch) => {
      dispatch(initFetching());
      try {
        const res = await ExamSessionService.initialize({
          examCode,
          testingGroupCode
        });
        // await dispatch(sessionInitializeSucess(res.data));
        await dispatch(getSessionData(res?.data?.examSessionId));
        await dispatch(getSessionDetails(res?.data?.examSessionId));
        return await dispatch(addUserPayment());
      } catch (err) {
        dispatch(sessionFailure(err))
        return err
      }
    };

export const startExamSession = (sessionId) => async (dispatch) => {
  dispatch(initFetching());
  try {
    await ExamSessionService.start(sessionId);
    const sessionDetails = await ExamSessionService.getSessionData(sessionId);
    // Maybe i'm doing it wrong, but session status is changing after start – we might need some re-renders –Alex
    dispatch(getSessionStatus(sessionId));
    dispatch(getCurrentTaskData(sessionDetails?.tasks[0].taskId))
    dispatch(getSessionDataSucess(sessionDetails));
    await dispatch(getSessionTasksStatus());
    // dispatch(sessionStartSucess(res.data));
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const getSessionDetails = (id) => async (dispatch, getState) => {
  dispatch(initFetching());

  let examSessionId = "";
  id
    ? (examSessionId = id)
    : (examSessionId =
      getState().examsSessionSlice.currentSessionDetails._id);
  const res = await ExamSessionService.getDetails(examSessionId);
  dispatch(sessionGetDetailsSucess({ ...res.data, examSessionId }));

};

export const getSessionStatus = createAsyncThunk(
  "examsSession/getSessionStatus",
  async (id, thunkAPI) => {
    try {
      const res = await ExamSessionService.getStatus(id);
      thunkAPI.dispatch(sessionGetStatusSucess(res.data));
    } catch (err) {
      thunkAPI.dispatch(sessionFailure(err));
    }
  }
);





export const removeFlaggedTask = (sessionId, taskId) => async (dispatch) => {
  dispatch(initFetching());
  try {
    const res = await ExamSessionService.removeFlaggedTask(sessionId, taskId);
    dispatch(removeFlaggedTaskSucess(res));
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const addFlaggedTask = (sessionId, taskId) => async (dispatch) => {
  dispatch(initFetching());
  try {
    const res = await ExamSessionService.addFlaggedTask(sessionId, taskId);
    dispatch(addFlaggedTaskSucess(res));
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const getTasksAnswers = () => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;

  const res = await ExamSessionService.getTasksAnswers(sessionId);
  dispatch(getTasksAnswersSucess(res.data));
};

export const getSessionTasksStatus = () => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;

  const res = await ExamSessionService.getSessionTasksStatus(sessionId);
  dispatch(getSessionTasksStatusSucess(res.data));
};

export const getSessionData = (id) => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = id || getState().examsSessionSlice.sessionData.examSessionId;

  const res = await ExamSessionService.getSessionData(sessionId);
  dispatch(getSessionDataSucess(res));
};

export const getCurrentTaskData = (id) => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;
  // Fallback to first task, as i saw /null cases –Alex
  const taskId = id || localStorage.getItem('taskId') || getState().examsSessionSlice.currentSessionDetails.tasks[0]?.taskId

  const res = await ExamSessionService.getCurrentTaskData(sessionId, taskId);
  localStorage.setItem('taskId', res?.task?.id)
  dispatch(getCurrentTaskDataSucess(res));
};

export const getSessionScore = () => async (dispatch, getState) => {
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;
  try {
    const res = await ExamSessionService.getScore(sessionId);
    dispatch(sessionGetScoreSucess(res.data));
  } catch (err) {
    dispatch(scoredFailuer(err.data.error.message));
  }
};

export const cancelSession = () => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;
  try {
    await ExamSessionService.cuncel(sessionId);
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const submitCurentTusk = () => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = getState().examsSessionSlice.currentSessionDetails._id;
  const taskId = getState().examsSessionSlice.currentTask.id;
  const multiTasksAnswers = getState().examsSessionSlice.multiTasksAnswers;
  const engineCodeAnswer = getState().examsSessionSlice.engineCodeAnswer;
  try {
    let res = {};
    if (multiTasksAnswers?.length > 0 || !!engineCodeAnswer) {
      res = await ExamSessionService.submitTuskMultipleAnswers(
        sessionId,
        taskId,
        multiTasksAnswers,
        engineCodeAnswer
      );
    } else {
      res = await ExamSessionService.submitTusk(sessionId, taskId);
    }

    await dispatch(submitTuskSuccess(res.data));
    await dispatch(getTasksAnswers());
    await dispatch(getSessionTasksStatus());
    return dispatch(setMultiTasksAnswers([]));
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const forcedFinishSession = (id) => async (dispatch, getState) => {
  dispatch(initFetching());
  const sessionId = id || getState().examsSessionSlice.currentSessionDetails._id;
  try {
    await ExamSessionService.forceFinish(sessionId);
    dispatch(forcedFinishSuccess());
    return true;
  } catch (err) {
    dispatch(sessionFailure(err));
  }
};

export const cleanStateToDefault = () => (dispatch) => {
  dispatch(clearState());
};
