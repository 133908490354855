import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Input, InputLabel, Typography } from "@mui/material";

import { useStyles } from "../styles/ReviewQuestionsForm.styles";
import { ObjectiveInfo } from "../../../components/Cards/ObjectiveInfo";

function ScoreQuestionForm({ handleScoreChange, question }) {
  const classes = useStyles();
  const [scoreValue, setScore] = useState(question.score);

  const smeSlice = useSelector((state) => state.smeSlice);

  useEffect(() => {
    const { selectedQuestion } = smeSlice;
    setScore(selectedQuestion.score);
  }, [smeSlice, smeSlice.selectedQuestion]);

  const onScoreChange = useCallback(
    (e) => {
      let newScore = e.target.value;
      // check if new value < 0 || > 100
      newScore = newScore > 100 ? 100 : newScore < 0 ? 0 : newScore;

      setScore(newScore);
      handleScoreChange(newScore);
    },
    [handleScoreChange]
  );

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.spacing}>
        <Typography>Item ID: {question.code}</Typography>
      </div>

      <Grid item xs={12} className={classes.textF}>
        <InputLabel className={classes.inputLabel} htmlFor={question._id}>
          Rating:
          <Input
            type="number"
            onChange={onScoreChange}
            fullWidth
            value={scoreValue ?? ""}
            id={question._id}
          />
        </InputLabel>
      </Grid>
      <ObjectiveInfo
        examObjective={question.examObjective}
        examSubObjective={question.examSubObjective}
      />
    </form>
  );
}

export default ScoreQuestionForm;
