import { useTheme } from "@mui/styles";
import UPDATE_USER_BY_ID from "api/apollo/mutations/UPDATE_USER_BY_ID";
import { BackdropLoad, SuccessMessage } from "components";
import { LINKS } from "constants/links";
import { City, Country, ICountry, IState, State } from "country-state-city";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import GET_USER from "api/apollo/queries/GET_USER";
import { Formik } from "formik";
import {
  GetUserQuery,
  GetUserQueryVariables,
  UpdateUserByIdMutation,
  UpdateUserByIdMutationVariables,
} from "generated/graphql";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Grid,
  FormHelperText,
  Divider,
  Typography,
  Paper,
} from "@mui/material";
import FieldTemplate from "./components/FieldTemplate";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function UpdateUserAccountInfo() {
  const theme = useTheme<any>();
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [userUpdated, setUserUpdated] = useState(false);

  const { data, loading } = useQuery<GetUserQuery, GetUserQueryVariables>(
    GET_USER,
    { fetchPolicy: "network-only" }
  );

  const [updateUser] = useMutation<
    UpdateUserByIdMutation,
    UpdateUserByIdMutationVariables
  >(UPDATE_USER_BY_ID);

  const user = useMemo(() => {
    return data?.me || null;
  }, [data]);
  const countries = useMemo(() => Country?.getAllCountries(), []);
  const states = useMemo(() => {
    return State?.getStatesOfCountry(selectedCountry?.isoCode);
  }, [selectedCountry]);

  const cities = useMemo(() => {
    return City?.getCitiesOfState(
      selectedCountry?.isoCode,
      selectedState?.isoCode
    );
  }, [selectedState]);

  const initialValues = useMemo(() => {
    return {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      birthdate: user?.birthdate || "",
      phone: user?.phone || "",
      country: user?.country || "",
      state: user?.state || "",
      city: user?.city || "",
      postcode: user?.postcode || "",
      submit: null,
    };
  }, [user]);

  if (loading) return <BackdropLoad open={loading} />;

  if (userUpdated) {
    return (
      <SuccessMessage handleGoBack={() => navigate(LINKS.main)} goBackSection />
    );
  }

  return (
    <Box py={4}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item lg={8} md={6} xl={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().max(255).required(),
              lastName: Yup.string().max(255).required(),
              phone: Yup.string().matches(
                phoneRegExp,
                "Phone number is not valid"
              ),
              birthdate: Yup.string(),
              email: Yup.string().email("Must be a valid email").max(255),
              country: Yup.string().max(255).required("Country is required"),
              state: Yup.string().max(255),
              city: Yup.string().max(255),
              postcode: Yup.string().max(255),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ): Promise<void> => {
              delete values.submit;
              delete values.email;

              try {
                await updateUser({
                  variables: {
                    userId: user._id,
                    userPayload: {
                      ...values,
                    },
                  },
                }).then(() => {
                  setUserUpdated(true);
                });
                setStatus({ success: true });
                setSubmitting(false);
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <Paper
                  sx={{
                    p: 5,
                  }}
                >
                  <Box>
                    <Typography
                      fontWeight="bold"
                      align="center"
                      variant="h5"
                      color="primary"
                    >
                      User Profile
                    </Typography>
                    <Box mt={2} mb={5} width="25%" mx="auto">
                      <Divider
                        sx={{
                          height: 2,
                          backgroundColor: theme.palette.warning.main,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Grid container spacing={4}>
                      <FieldTemplate
                        fieldName="firstName"
                        label="First Name"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <FieldTemplate
                        fieldName="lastName"
                        label="Last Name"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <FieldTemplate
                        fieldName="email"
                        label="Email"
                        type="email"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <FieldTemplate
                        fieldName="birthdate"
                        label="Birthdate"
                        type="date"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <FieldTemplate
                        fieldName="phone"
                        label="Phone"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                      <FieldTemplate
                        fieldName="country"
                        label="Country"
                        touched={touched}
                        values={values}
                        errors={errors}
                        options={countries}
                        handleSelect={(value) => {
                          setSelectedCountry(
                            countries.find((a) => a.name === value)
                          );
                          setSelectedState(null);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setFieldValue("country", value || "");
                        }}
                      />
                      <FieldTemplate
                        fieldName="state"
                        label="State/Region"
                        touched={touched}
                        values={values}
                        errors={errors}
                        options={states}
                        handleSelect={(value) => {
                          setSelectedState(
                            states.find((a) => a.name === value)
                          );
                          setFieldValue("city", "");
                          setFieldValue("state", value || "");
                        }}
                      />
                      <FieldTemplate
                        fieldName="city"
                        label="City"
                        touched={touched}
                        values={values}
                        errors={errors}
                        options={cities}
                        handleSelect={(value) => {
                          setFieldValue("city", value || "");
                        }}
                      />
                      <FieldTemplate
                        fieldName="postcode"
                        label="Postcode"
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Grid>
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {errors.submit as string}
                        </FormHelperText>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Paper>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
}
