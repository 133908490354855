import React from "react";
import TableList from "../TableList/TableList";
import { makeStyles } from "@mui/styles";
import { Button, Typography, Grid } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const AddNewProctors = (props) => {
  const { mode, selected, setSelected, rows, handleChange, title } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    label: {
      fontWeight: 600
    }
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
        >
          <Grid item container xs={12}>
            <Grid item container xs={10} justifyContent="center">
              <Grid item xs={2} style={{ textAlign: "left" }}></Grid>
              <Grid item xs={10} style={{ textAlign: "center" }}>
                <Typography component="h1" variant="h5">
                  {title}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={2} style={{ textAlign: "right" }}>
              <HighlightOffIcon
                style={{ cursor: "pointer" }}
                onClick={() => props.handleClose()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={8} />

      <Grid item xs={12}>
        <TableList
          mode={mode}
          selected={selected}
          setSelected={setSelected}
          rows={rows}
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} />
      {rows && rows.length === 0 && (
        <Typography component="h4" variant="h8">
          no members to add
        </Typography>
      )}
      <Grid item container xs={12}>
        <Grid item xs={6} />
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.handleClose()}
          >
            CLOSE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
