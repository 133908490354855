import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LINKS } from "constants/links";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "60px",
    maxWidth: "100%",
    // position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.grey[200]
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  copyrightText: {
    // fontSize: '0.9rem',
    color: "gray"
  },
  footerLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: "0.8rem"
  },
  linksContainer: {
    display: "flex",
    justifyContent: "space-evenly"
  }
}));

function Footer() {
  const { termsAndCond, privacyPolicy, faqs } = LINKS;
  const { footerLink, root, linksContainer, copyrightText } = useStyles();
  const ref = useRef(null)

  const copyRightText = `Copyright ${new Date().getFullYear()} © Knowledge Pillars`;

  return (
    <Container className={root} ref={ref}>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            className={copyrightText}
            variant="subtitle2"
            color="textSecondary"
            align="center"
          >
            {copyRightText}
          </Typography>
        </Grid>

        <Grid item xs={6} className={linksContainer}>
          <Link className={footerLink} to={termsAndCond}>
            TERMS OF USE
          </Link>
          <Link className={footerLink} to={privacyPolicy}>
            PRIVACY POLICY
          </Link>
          <Link className={footerLink} to={faqs}>
            FAQs
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Footer;
