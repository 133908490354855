import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AssignmentIcon from "@mui/icons-material/Assignment"
import EmailIcon from "@mui/icons-material/Email"
import {examSessionStatuses} from "constants/constants.js"
import {LINKS} from "constants/links.js"
import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import {makeStyles} from "@mui/styles"
import {useNavigate} from "react-router-dom"
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  Backdrop
} from "@mui/material"
import {BackdropLoad, PrevNextSection} from "components"
import {cleanStateToDefault, forcedFinishSession} from "store/slices/examsSession.js"
import {clearActiveSession} from "store/slices/user"
import {fetchVouchers} from "store/slices/vouchers.js"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center"
  },
  examSelectionContainer: {
    padding: theme.spacing(5),
    minWidth: "55%",
    maxWidth: "55%"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  examSelectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  errAccess: {
    color: theme.palette.error.main,
    maxWidth: "40rem",
    textAlign: "center"
  },
  icon: {
    marginRight: "5px"
  },
  secondaryColor: {
    color: theme.palette.secondary.main
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  }
}))

export default function ActiveExamInfo() {
  const {
    root,
    examSelectionContainer,
    DividerContainer,
    DividerBar,
    examSelectionTitle,
    icon,
    secondaryColor,
    backdrop
  } = useStyles()
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {activeExamSession, id, firstName, lastName, email} = useSelector((state) => state.user)
  const allExamVouchers = useSelector(
    (state) => state.vouchersData?.vouchers?.examVouchers || null
  )

  const examVoucher = useMemo(() => {
    if (!allExamVouchers || !activeExamSession) {
      return null
    }

    return allExamVouchers.find(i => {
      return i.examCode.toLowerCase() === activeExamSession?.examCode?.toLowerCase()
    }) || null
  }, [allExamVouchers, activeExamSession])

  useEffect(() => {
    if (id && !activeExamSession) {
      navigate("/")
    } else {
      if (activeExamSession?.voucherCode) {
        dispatch(fetchVouchers())
      }
    }
  }, [id, activeExamSession])

  if (!id) return <BackdropLoad open />

  const handlePrev = () => {
    dispatch(forcedFinishSession(activeExamSession?._id)).finally(() => {
      localStorage.removeItem("taskId")
      dispatch(clearActiveSession())
      dispatch(cleanStateToDefault())
      navigate("/", {replace: true})
    })
  }

  const handleNext = () => {
    if (activeExamSession?.proctoring === "online" && !examVoucher?.examusDisabled) {
      window.open(activeExamSession?.startLink, "_self")
    } else {
      navigate(`${LINKS.examWindow}/${activeExamSession.examCode}/${activeExamSession.id}`)
    }
  }

  return (
    <>
      <Backdrop className={backdrop} open={false}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={examSelectionContainer}>
          <Typography
            className={examSelectionTitle}
            align="center"
            variant="h5"
            color="primary">
            Active Exam Information
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar}/>
          </Box>
          {activeExamSession && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" alignItems="center">
                <AssignmentIcon className={icon} />
                <Typography color="primary">
                  Exam Name:{" "}
                  <span className={secondaryColor}>
                  {activeExamSession?.exam?.displayName}
                </span>
                </Typography>
              </Box>
              {firstName && (
                <Box display="flex" alignItems="center">
                  <AccountCircleIcon color="inherit" className={icon} />
                  <Typography>
                    Name:{" "}
                    <span className={secondaryColor}>
                    {firstName}
                  </span>
                  </Typography>
                </Box>
              )}
              {lastName && (
                <Box display="flex" alignItems="center">
                  <AccountCircleIcon className={icon} />
                  <Typography>
                    Surname:{" "}
                    <span className={secondaryColor}>
                    {lastName}
                  </span>
                  </Typography>
                </Box>
              )}
              {email && (
                <Box display="flex" alignItems="center">
                  <EmailIcon className={icon} />
                  <Typography>
                    Email:{" "}
                    <span className={secondaryColor}>
                    {email}
                  </span>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          <PrevNextSection
            handlePrev={
              activeExamSession?.status === examSessionStatuses.InProgress ?
                handlePrev
                :
                undefined
            }
            handleNext={handleNext}
            NextText="Join Active Session"
            PrevText="Close Active Session"
          />
        </Paper>
      </Grid>
    </>
  )
}
