import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import LicenseService from "../../services/license.service";

export const initialState = {
  loading: false,
  hasErrors: false,
  license: null,
};

export const initialStateUpdatedLicense = {
  loading: false,
  hasErrors: false,
  updated: null,
};

export const getAllLicenses = createAsyncThunk(
  "license/getLicenses",
  async () => {
    const res = await LicenseService.getLicense();
    return res["data"];
  }
);

export const updateLicenseStatus = createAsyncThunk(
  "currentLicense/updateLicenseStatus",
  async (body) => {
    const res = await LicenseService.toggleStatus(body);
    return res.data;
  }
);

const licenseSlice = createSlice({
  name: "license",
  initialState,
  extraReducers: {
    [getAllLicenses.pending]: (state) => {
      state.loading = true;
    },
    [getAllLicenses.fulfilled]: (state, { payload }) => {
      state.license = payload;
      state.loading = false;
    },
    [getAllLicenses.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

const licenseUpdateSlice = createSlice({
  name: "currentLicense",
  initialState: initialStateUpdatedLicense,
  reducers: {},
  extraReducers: {
    [updateLicenseStatus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateLicenseStatus.rejected]: (state, action) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [updateLicenseStatus.fulfilled]: (state, action) => {
      state.updated = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});

export default combineReducers({
  license: licenseSlice.reducer,
  currentLicense: licenseUpdateSlice.reducer,
});
