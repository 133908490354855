export const filterNormilizeRoles = (
  allUsers,
  exeption = "admin",
  extraField
) => {
  const filteredArr = [];
  Object.keys(allUsers).forEach((item) => {
    allUsers[item].forEach((item) => {
      if (item.role !== exeption) {
        return filteredArr.push({ ...item, ...extraField });
      }
    });
  });
  return filteredArr;
};
