import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    overflowY: "auto",
    height: "65px",
    padding: theme.spacing(1.8, 10),
  }
}));

const Description = () => {
  const styles = useStyles();
  const { currentTask } = useSelector(
    (state) => state
  ).examsSessionSlice;

  if (["multiple", "single"].includes(currentTask?.type)) {
    return null;
  }

  return (
    <Box className={styles.content}>
      <Typography align="center" variant="subtitle1">
        {currentTask?.description}
      </Typography>
    </Box>
  );
};

export default Description;
