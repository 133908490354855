export const isAllowedToDelete = (allGroups, id)=>{
  const isTeacherInGroups  = [];
  const isStudentInGroups  = [];
  const isProctorInGroups  = [];
  allGroups.forEach((group)=>{
    if (group.proctorIds && group.proctorIds.includes(id)) {
      isProctorInGroups.push(group)
    }
    if (group.studentIds && group.studentIds.includes(id)) {
      isStudentInGroups.push(group)
    }
    if (group.teacherIds && group.teacherIds.includes(id)) {
      isTeacherInGroups.push(group)
    }
  });


  return {
    teachers: isTeacherInGroups,
    students: isStudentInGroups,
    proctors: isProctorInGroups
  }
};

