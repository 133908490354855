export const ENDPOINT = {
  program: "/program",
  exam: "/exam",
  examStudentAccess: "/exam/student-access-to-run-exam/?examCode=",
  examByProgramId: "/exam?programId=",
  userExamsScrore: "/user/exam-result-list",
  //
  exams: `/exams`,
  programs: `/programs`,
  examSession: "/exam-session",
  checkGroupCode: '/testing-groups/',
  examsSessionInit: "/exam-session/initiate",
  voucher: "/voucher",
  //
  organization: `/organization`,
  license: "/license",
  //
  group: "organization/exam-group",
  currentGroup: "/exam-group",

  //
  user: "/user",
  userRole: "/user/role",
  usersByRole: "/user/exam-group-info",
  //
  payment: "/pay-off",
  validatePaymentVoucher: "/voucher/validate-voucher/?",
  notification: "/notification",

  //
  sme: "/sme/",
  smeExamQuestions: "/sme/",
  smeTask: "/sme/task/",

  //
  taskEvaluationScore: "/smeTasks/score",
  taskEvaluationReview: "/smeTasks/review",
  fetchUserInfo: '/users/me',
};
