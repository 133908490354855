import React, { useState } from "react";

import {
  GroupUpperSection,
  GroupMiddleSection,
  GroupProctorsSection,
  GroupStudentsSection
} from "./ExamGroupComponents";
import CustomModal from "./Modal";
import { useModal } from "hooks/useModal";

import { Button, Grid, CircularProgress } from "@mui/material";
import { groupModal } from "./ExamGroupComponents/styles";

export const ExamGroupModal = (props) => {
  const { getModal, getModalClass, handleOpen, handleClose, open } = useModal();

  const {
    handleChange,
    group,
    loading,
    optionsLicense,
    optionsTeacher,
    optionsExam,
    optionsProgram,
    allProctors,
    allStudents,
    handleSubmit,
    mode,
    attempt
  } = props;

  const [modalName, setModalName] = useState("");
  const [selectedProctorsForDeleting, setSelectedProctorsForDeleting] =
    useState([]);
  const [selectedProctorsForAdding, setSelectedProctorsForAdding] = useState(
    []
  );
  const [selectedStudentsForDeleting, setSelectedStudentsForDeleting] =
    useState([]);
  const [selectedStudentsForAdding, setSelectedStudentsForAdding] = useState(
    []
  );
  const classes = groupModal(group.asyncProctoring);

  const handleOpenAddProctors = () => {
    setModalName("ADD_NEW_PROCTORS");
    handleOpen();
  };

  const handleOpenAddStudents = () => {
    setModalName("ADD_NEW_STUDENTS");
    handleOpen();
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <Grid
          container
          xs={12}
          className={classes.loader}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress disableShrink size={40} />
        </Grid>
      </div>
    );
  }

  return (
    <form className={classes.root} noValidate>
      <Grid container spacing={3}>
        <GroupUpperSection
          handleChange={handleChange}
          group={group}
          mode={mode}
          handleClose={props.handleClose}
          attempt={attempt}
        />

        <GroupMiddleSection
          handleChange={handleChange}
          group={group}
          optionsLicense={optionsLicense}
          optionsTeacher={optionsTeacher}
          optionsExam={optionsExam}
          optionsProgram={optionsProgram}
          attempt={attempt}
        />
        <GroupProctorsSection
          handleOpenAddProctors={handleOpenAddProctors}
          selectedProctorsForDeleting={selectedProctorsForDeleting}
          setSelectedProctorsForDeleting={setSelectedProctorsForDeleting}
          handleChange={handleChange}
          allProctors={allProctors}
          group={group}
        />
        <GroupStudentsSection
          selectedStudentsForDeleting={selectedStudentsForDeleting}
          setSelectedStudentsForDeleting={setSelectedStudentsForDeleting}
          handleOpenAddStudents={handleOpenAddStudents}
          allStudents={allStudents}
          handleChange={handleChange}
          group={group}
        />
      </Grid>

      <Grid item xs={12} container justifyContent="flex-end">
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={() => props.handleClose()}
            style={{ margin: "1rem" }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e)}
            style={{ margin: "1rem" }}
          >
            {props.mode === "add" ? "Create" : "Update"}
          </Button>
        </Grid>
      </Grid>
      {modalName === "ADD_NEW_PROCTORS" ? (
        <CustomModal
          mode="add"
          title={"Add new Proctors"}
          selected={selectedProctorsForAdding}
          setSelected={setSelectedProctorsForAdding}
          handleChange={() => {
            const newMembers = allProctors
              .filter((el) => selectedProctorsForAdding.includes(el.id))
              .map(({ id }) => id);
            return handleChange({
              target: {
                name: "proctorIds",
                value: [...group.proctorIds, ...newMembers]
              }
            });
          }}
          rows={allProctors.filter(
            (item) => !group.proctorIds.includes(item.id)
          )}
          handleClose={handleClose}
          open={open}
          className={getModalClass("ADD_NEW_PROCTORS")}
        >
          {(props) => getModal("ADD_NEW_PROCTORS", props)}
        </CustomModal>
      ) : (
        <CustomModal
          mode="add"
          title={"Add new Students"}
          selected={selectedStudentsForAdding}
          setSelected={setSelectedStudentsForAdding}
          handleChange={() => {
            const newMembers = allStudents
              .filter((el) => selectedStudentsForAdding.includes(el.id))
              .map(({ id }) => id);
            return handleChange({
              target: {
                name: "studentIds",
                value: [...group.studentIds, ...newMembers]
              }
            });
          }}
          rows={allStudents.filter(
            (item) => !group.studentIds.includes(item.id)
          )}
          handleClose={handleClose}
          open={open}
          className={getModalClass("ADD_NEW_STUDENTS")}
        >
          {(props) => getModal("ADD_NEW_STUDENTS", props)}
        </CustomModal>
      )}
    </form>
  );
};
