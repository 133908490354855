import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function get() {
  return apiCall({
    url: `${ENDPOINT.exam}`,
    method: "GET",
  });
}

function getById(id) {
  return apiCall({
    url: `${ENDPOINT.examByProgramId}${id}`,
    method: "GET",
  });
}

function getExamStudentAccess(examCode) {
  return apiCall({
    url: `${ENDPOINT.examStudentAccess}${examCode}`,
    method: "GET",
  });
}

function getUserExamScore() {
  return apiCall({
    url: `${ENDPOINT.userExamsScrore}`,
    method: "GET",
  });
}

const ExamService = {
  get,
  getById,
  getUserExamScore,
  getExamStudentAccess,
};

export default ExamService;
