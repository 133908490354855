import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProgramService from "../../services/program";

export const initialState = {
  loading: false,
  hasErrors: false,
  data: [],
};

// A slice for posts with our three reducers
const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    getPrograms: (state) => {
      state.loading = true;
    },
    getProgramsSucess: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getProgramsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

// Three actions generated from the slice
export const {
  getPrograms,
  getProgramsSucess,
  getProgramsFailure,
} = programsSlice.actions;

// The reducer
export default programsSlice.reducer;

export const fetchPrograms = createAsyncThunk(
  "programs/fetchPrograms",
  async (input, thunkAPI) => {
    // Set loading to true
    thunkAPI.dispatch(getPrograms());
    try {
      const res = await ProgramService.get();
      thunkAPI.dispatch(getProgramsSucess(res.data));
    } catch (error) {
      // Set any erros while trying to fetch
      thunkAPI.dispatch(getProgramsFailure());
    }
  }
);
