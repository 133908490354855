import React from "react"
import { Route, Navigate } from "react-router-dom"
import {  useSelector} from 'react-redux';

export const ProtectedRoute = ({ children }) => {
  const userState = useSelector((state) => state.user);

  if (!userState.user) {
    return <Navigate to="/" replace />
  }

  return children
};
