import * as Sentry from "@sentry/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCurrentUserInfo,
  getCognitoGroups,
  resendConfirmationCode,
} from "services/auth";
import userService from "services/user.service";
import {
  signIn,
  signOut,
  signUp,
  updateUserAttributes,
  confirmSignUp as awsConfirmSignUp,
} from "aws-amplify/auth";

export const initialState = {
  activeExamSession: null,
  hasErrors: false,
  user: null,
  userOrganizationId: null,
  userGroups: [],
  loading: true,
  loadingSigUp: false,
  errLogin: false,
  errSignUp: false,
  confirmSignUp: null,
  signUp: false,
  address: null,
  errUpdateUser: false,
  updateUserAnswer: "",
  birthdate: null,
  city: null,
  country: null,
  firstName: null,
  groups: [],
  id: null,
  isProctor: false,
  lastName: null,
  phone: null,
  photoUrl: null,
  postcode: null,
  proctorSignedAt: null,
  schoolId: null,
  studentId: null,
  username: null,
  email: null,
  fakeGroup: null,
  isAuth: false,
};

export const fetchUserDetailsThunk = createAsyncThunk(
  "user/fetchUserDetailsThunk",
  async () => {
    try {
      return await userService.fetchUserInfo();
    } catch (err) {
      console.log(err);
      // thunkAPI.dispatch(handleError(err));
      throw err?.data?.error || err;
    }
  }
);

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      const { userEmail, username, address, userOrganizationId, userGroups } =
        action.payload;

      if (!state.email && userEmail) state.email = userEmail;
      if (!state.user && username) state.user = username;
      if (!state.userOrganizationId && userOrganizationId)
        state.userOrganizationId = userOrganizationId;
      if (!state.userGroups?.length && userGroups)
        state.userGroups = userGroups;
      if (!state.address && address)
        state.address = address ? JSON.parse(address) : null;

      state.loading = false;
      state.errLogin = false;
      state.isAuth = true;
    },
    loginFailure: (state: any) => {
      state.errLogin = "The credentials you have entered are invalid.";
      state.loading = false;
      state.isAuth = false;
    },
    checkUserLoginedStart: (state) => {
      state.loading = true;
    },
    checkUserLoginedFailed: (state) => {
      state.loading = false;
    },

    logoutStart: (state) => {
      state.loading = true;
    },
    signUpStart: (state) => {
      state.loadingSigUp = true;
      state.errSignUp = false;
    },
    updateUserInfoSuccess: (state, action) => {
      state.loading = false;
      state.updateUserAnswer = action.payload;
    },
    updateUserInfoFailure: (state, action) => {
      state.errUpdateUser = action.payload;
      state.loading = false;
    },
    updateUserInfoReset: (state) => {
      state.updateUserAnswer = "";
    },
    signUpSuccess: (state) => {
      state.loadingSigUp = false;
      state.errSignUp = false;
    },
    signUpFailure: (state, action) => {
      state.errSignUp = action.payload;
      state.loadingSigUp = false;
    },
    confirmSignUp: (state, action) => {
      state.confirmSignUp = action.payload;
    },
    setDefaultState: (state) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    isSignUp: (state, action) => {
      state.signUp = action.payload;
    },
    clearActiveSessionState: (state) => {
      state.activeExamSession = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetailsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserDetailsThunk.rejected, (state: any, action) => {
      state.loading = false;
      state.hasErrors = true;
      if (action?.error?.message) state.errorMessage = action?.error?.message;
      console.warn(action?.error);
    });
    builder.addCase(fetchUserDetailsThunk.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (!payload) return;

      if (payload.email) {
        Sentry.setUser({ email: payload.email });
      }

      state.user = payload.email ? payload.email : null;
      Object.entries(payload).forEach(([key, value]: any) => {
        if (key === "groups" && value?.length) {
          state.userGroups = value;
        }
        if (value) state[key] = value;
      });
    });
  },
});
export default slice.reducer;

// Actions
const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutStart,
  checkUserLoginedStart,
  checkUserLoginedFailed,
  setDefaultState,
  confirmSignUp,
  signUpFailure,
  signUpSuccess,
  signUpStart,
  isSignUp,
  updateUserInfoSuccess,
  updateUserInfoFailure,
  updateUserInfoReset,
  clearActiveSessionState,
} = slice.actions;

export const login =
  (username: string, password: string) => async (dispatch) => {
    dispatch(loginStart());
    try {
      await signIn({ username, password });

      const userPayload = {
        userEmail: username,
        username: username,
        address: "",
        userOrganizationId: "",
        userGroups: [],
      };
      dispatch(loginSuccess(userPayload));
    } catch (e) {
      dispatch(loginFailure(e.message));
      return console.error(e.message);
    }
  };
export const logout = () => async (dispatch) => {
  dispatch(logoutStart());
  try {
    await signOut();

    dispatch(setDefaultState());
  } catch (e) {
    return console.error(e.message);
  }
};

export const checkUserLogined = () => async (dispatch) => {
  dispatch(checkUserLoginedStart());

  try {
    const [cognitoUser, userGroups] = await Promise.all([
      getCurrentUserInfo(),
      getCognitoGroups(),
    ]);
    const { username } = cognitoUser;

    const userPayload = {
      userEmail: username,
      username: username,
      address: "",
      userOrganizationId: [],
      userGroups,
    };

    dispatch(loginSuccess(userPayload));

    return cognitoUser;
  } catch (e) {
    dispatch(setDefaultState());
    dispatch(checkUserLoginedFailed());
  }
};

export const signUpUser = (username, password, attr) => async (dispatch) => {
  dispatch(signUpStart());
  try {
    await signUp({
      username,
      password,
      options: {
        ...attr,
        "custom:role": "student",
      },
    }).then((data) => {
      dispatch(confirmSignUp({ username: username }));
      dispatch(signUpSuccess());
      return data;
    });
  } catch (e) {
    dispatch(signUpFailure(e.message));
    return console.error(e.message);
  }
};

export const resendConfirmCode = (username) => async (dispatch) => {
  try {
    await resendConfirmationCode(username);
  } catch (e) {
    dispatch(signUpFailure(e.message));
    return console.error(e.message);
  }
};

export const updateUserInfo = (attr) => async (dispatch) => {
  dispatch(signUpStart());
  try {
    await updateUserAttributes({
      userAttributes: {
        ...attr,
      },
    }).then((data) => {
      dispatch(updateUserInfoSuccess(data));
      dispatch(signUpSuccess());
      return data;
    });
  } catch (e) {
    dispatch(updateUserInfoFailure(e.message));
    return console.error(e.message);
  }
};

export const resetUpdatedUserSuccess = () => (dispatch) => {
  return dispatch(updateUserInfoReset());
};

export const confirm = (username, code, history) => async (dispatch) => {
  try {
    await awsConfirmSignUp({ username, confirmationCode: code }).then(
      (data) => {
        dispatch(isSignUp(false));
        dispatch(confirmSignUp(null));
        // navigate("/")
        dispatch(loginFailure(false));
        dispatch(signUpFailure(false));
        return data;
      }
    );
  } catch (err) {
    dispatch(signUpFailure(err.message));
    console.log(err.message);
  }
};

export const clearActiveSession = () => (dispatch) => {
  dispatch(clearActiveSessionState());
};

export const setSignUp = (data) => (dispatch) => {
  dispatch(isSignUp(data));
  dispatch(loginFailure(false));
  dispatch(signUpFailure(false));
  dispatch(confirmSignUp(null));
};

export const deferredConfirmation = (username, link) => (dispatch) => {
  dispatch(confirmSignUp({ username: username }));
  dispatch(resendConfirmCode(username));
  // navigate(link)
};
