import React, { useState, useCallback, useEffect } from "react";
import { Button, Grid, Typography, CircularProgress } from "@mui/material";

import { Page, Document, pdfjs } from "react-pdf";

import classNames from "classnames";

import { makeStyles } from "@mui/styles";

// https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#use-external-cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const useStyle = makeStyles((theme) => ({
  container: {
    zIndex: 3
  },
  PDFdoc: {
    position: "relative",
    margin: "1rem 0 0",
    boxShadow: "0 0 3px 1px rgba(16, 36, 94, 0.2)",
    "&:hover": {
      "& .controls-item": {
        opacity: "10"
      }
    },
    "& .controls-item": {
      opacity: "0.3"
    },
    overflow: "auto"
  },
  description: {
    padding: "2rem 0"
  },
  controlsContainer: {
    position: "absolute",
    bottom: "1%",
    transition: "opacity ease-in-out 0.2s"
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f9f9f9",
    dropShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    borderRadius: "4px"
  },
  button: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  PDFloader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "1100px",
    width: "777px"
  }
}));

const PDFembed = ({ file, url, description, pageHeight = 1100 }) => {
  const styles = useStyle();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = useCallback(
    (arg) => {
      const { numPages } = arg;
      setNumPages(numPages);
    },
    [numPages, setNumPages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      <Typography variant="h6" className={styles.description}>
        {description}
      </Typography>

      <Document
        file={url ? { url: url } : file && file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        loading={
          <div className={styles.PDFloader}>
            <CircularProgress color="inherit" />
          </div>
        }
        onItemClick={(e) => {
          console.warn(e);
        }}
        className={styles.PDFdoc}
      >
        {numPages ? (
          new Array(numPages).fill(1).map((_, index) => {
            return (
              <Page
                key={index}
                className={styles.PDFpage}
                pageNumber={index + 1}
                height={pageHeight}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                renderForms={true}
              />
            );
          })
        ) : (
          <Page
            className={styles.PDFpage}
            pageNumber={pageNumber}
            height={pageHeight}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderForms={true}
          />
        )}

        <Grid
          item
          container
          className={classNames(styles.controlsContainer, "controls-item")}
          justifyContent="center"
          alignItems="center"
        >
          {!pageNumber && (
            <Grid item className={styles.controls}>
              <Button
                className={styles.button}
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                {"<"}
              </Button>
              <p>
                {pageNumber} of {numPages}
              </p>
              <Button
                className={styles.button}
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numPages}
              >
                {">"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Document>
    </Grid>
  );
};

export default PDFembed;
