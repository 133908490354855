import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import NotificationsService from "services/notifications";

export const initialState = {
  loading: false,
  hasErrors: false,
  allNotifications: null,
  message: null,
  count: 0,
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    const response = await NotificationsService.get();
    return response.data;
  }
);

export const getNotificationsByProctoringType = createAsyncThunk(
  "notifications/getNotificationsByProctoringType",
  async (typeProctoring) => {
    const response = await NotificationsService.getByProctoringType(
      typeProctoring
    );

    return response.data;
  }
);
export const patchNotification = createAsyncThunk(
  "notifications/patchNotification",
  async (input) => {
    const response = await NotificationsService.patch(input);
    return response.data;
  }
);

const setNotificationsCount = (allNotifications) => {
  let acumulator = 0;
  allNotifications?.forEach((item) => {
    if (item.read === false) {
      acumulator = ++acumulator;
    }
  });
  return acumulator;
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: {
    //pending
    [getNotifications.pending ||
    patchNotification.pending ||
    getNotificationsByProctoringType.pending]: (state) => {
      state.loading = true;
    },

    //fulfilled
    [getNotifications.fulfilled]: (state, action) => {
      state.allNotifications = action.payload.reverse();
      state.count = setNotificationsCount(action.payload);
      state.loading = false;
      state.hasErrors = false;
    },
    [patchNotification.fulfilled]: (state) => {
      state.loading = false;
      state.hasErrors = false;
    },
    [getNotificationsByProctoringType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.message = payload.data.message;
    },
    //rejected
    [getNotifications.rejected ||
    patchNotification.rejected ||
    getNotificationsByProctoringType.pending]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { clearMessage } = notifications.actions;

export default notifications.reducer;
