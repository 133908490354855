import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography, Grid } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WarningIcon from "@mui/icons-material/Warning";

export const WarningForbiddenEditDeleteUsers = (props) => {
  const { listForbiddenEdit, handleClose } = props;
  const { teachers, students, proctors } = listForbiddenEdit;
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      flexGrow: 1,
      padding: theme.spacing(0.5)
    },
    label: {
      fontWeight: 600
    },
    user: {
      padding: "0 5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      textTransform: "capitalize",
      backgroundColor: "#ccc",
      "&::first-letter": {
        textTransform: "capitalize",
        color: "red"
      }
    },
    usedIn: {
      padding: theme.spacing(2)
    },
    head: {
      padding: theme.spacing(0, 2)
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
        >
          <Grid item container xs={12}>
            <Grid item container xs={10} justifyContent="center">
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <WarningIcon color="error" fontSize="large" />
              </Grid>
              <Grid item xs={10} style={{ textAlign: "center" }}>
                <Typography component="h1" variant="h5">
                  Warning!
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={2} style={{ textAlign: "right" }}>
              <HighlightOffIcon
                style={{ cursor: "pointer" }}
                onClick={() => props.handleClose()}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography component="h4" variant="h7">
            User <span className={classes.user}>{listForbiddenEdit.name}</span>{" "}
            cannot be edited or deleted because he is assigned for the following
            groups:
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="flex-start"
          alignContent="center"
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            className={classes.head}
          >
            <Grid item xs={6}>
              <span className={classes.user}>Role</span>
            </Grid>
            <Grid item xs={6}>
              <span className={classes.user}>Name</span>
            </Grid>
          </Grid>
          {!!teachers.length && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.usedIn}
            >
              <Grid item xs={4}>
                <Typography component="h4" variant="h8">
                  as a teacher
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ul style={{ margin: "0px" }}>
                  {teachers.map(({ name, id }) => (
                    <li key={id}>{name}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          )}

          {!!students.length && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.usedIn}
            >
              <Grid item xs={4}>
                <Typography component="h4" variant="h8">
                  as a student
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ul style={{ margin: "0px" }}>
                  {students.map(({ name, id }) => (
                    <li key={id}>{name}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          )}

          {!!proctors.length && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.usedIn}
            >
              <Grid item xs={4}>
                <Typography component="h4" variant="h8">
                  as a proctor
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ul style={{ margin: "0px" }}>
                  {proctors.map(({ name, id }) => (
                    <li key={id}>{name}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={8} />

        <Grid item xs={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose()}
          >
            CLOSE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
