import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { getSyntaxLanguage } from 'utils/syntax-highlighter';

const ParsonsCodeSnippet = ({ codeSnippet, codeSnippetLanguage }) => {
    return (
    <div className="pq-mult-syntax-padding">
    <div className={`pq-mult-syntax pq-syntax-wrap`}>
      <SyntaxHighlighter
        language={
          getSyntaxLanguage(codeSnippetLanguage)
        }
        style={docco}
      >
        {codeSnippet || ''}
      </SyntaxHighlighter>
    </div>
    </div>
  );
};

export default ParsonsCodeSnippet;