

import {
  createTheme as createMuiTheme
} from "@mui/material"
import {
    kpPrimaryColors,
    kpNeutralsColors,
    kpSecondaryColors
  } from "../constants/theme"

export const createTheme = () => {
  let theme = createMuiTheme({
    palette: {
      action: {
        active: "#6b778c"
      },
      background: {
        default: "#00000",
        paper: "#fafafa"
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336"
      },
      mode: "light",
      primary: {
        light: kpPrimaryColors.lightBlue,
        main: kpPrimaryColors.darkBlue,
        contrastText: "#ffffff"
      },
      secondary: {
        main: kpPrimaryColors.lightBlue,
        contrastText: "#ffffff"
      },
      white: {
        main: "#ffffff"
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50"
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c"
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800"
      },
      kpYellowColors: {
        main: kpPrimaryColors.kpYellow
      },
      kpPurpleColors: {
        main: kpSecondaryColors.purple
      },
      kpGreenColors: {
        main: kpSecondaryColors.green
      },
      kpOrangeColors: {
        main: kpSecondaryColors.orange
      },
      kpNeutralColors: {
        main: kpNeutralsColors.lightGray,
        dark: kpNeutralsColors.darkGray,
        light: kpNeutralsColors.contrastGray
      },
      secondPrimary: {
        main: "#0F75BC"
      }
      // text: {
      //   primary: '#404040',
      // },
    },
    shape: {
      borderRadius: 12
    }
  })
  return theme
}
