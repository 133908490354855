import React, {useCallback, useState} from "react"
import {makeStyles} from "@mui/styles"
import {passwordReset} from "services/auth"
import {forgotPasswordKeys, checkPassword} from "utils/validation"
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Divider,
  Box,
  InputAdornment
} from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import {CurrentState} from "../ForgotPassword"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  forgotContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "45%",
      maxWidth: "55%"
    }
  },
  forgotTitle: {
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  submitButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submitButton: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2)
  }
}))

function ForgotReset({setCurrentState, setValues, values}) {
  const {
    root,
    form,
    forgotContainer,
    forgotTitle,
    DividerContainer,
    DividerBar,
    submitButtonContainer,
    submitButton
  } = useStyles()

  const [attempt, setAttemt] = useState(false)
  const [active, setToggleActive] = useState(true)
  const [errMessage, setErrMessage] = useState("")

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      })
    },
    [values] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSubmit = useCallback(async () => {
    setToggleActive(false)
    if (checkPassword(values[forgotPasswordKeys.PASSWORD.key])) {
      setAttemt(true)
      setToggleActive(true)
      return
    }

    const result = await passwordReset(
      values.email,
      values.confirmation,
      values.password
    )

    if (typeof result !== "boolean") {
      setErrMessage(() => result.message)
      setToggleActive(true)
    } else setCurrentState(CurrentState.SUCCESS)

    return false
  }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={forgotContainer}>
        <Typography
          className={forgotTitle}
          align="center"
          variant="h5"
          color="primary"
        >
          New Password
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>
        <Typography variant="subtitle1" align="center">
          Please insert your new password.
        </Typography>

        <form className={form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoFocus
            onChange={(e) => handleChange(e)}
            helperText={
              attempt && checkPassword(values[forgotPasswordKeys.PASSWORD.key])
                ? checkPassword(values[forgotPasswordKeys.PASSWORD.key])
                : ""
            }
            error={
              attempt && checkPassword(values[forgotPasswordKeys.PASSWORD.key])
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              )
            }}
          />

          {errMessage && (
            <Grid container>
              <Typography variant="subtitle2" color="error">
                {errMessage}
              </Typography>
            </Grid>
          )}
          <Grid container className={submitButtonContainer}>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
                onClick={handleSubmit}
                disabled={!active || !values["password"]}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  )
}

export default ForgotReset
