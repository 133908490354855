import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7)
  },
  NoResultsContainer: {
    padding: theme.spacing(4),
    minWidth: "400px"
  },
  Message: {
    marginBottom: theme.spacing(2)
  },
  ButtonContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));

const SimpleCardMessage = ({ message, path, buttonText }) => {
  const { NoResultsContainer, Message, ButtonContainer } = useStyles();

  return (
    <Paper className={NoResultsContainer}>
      <Typography
        className={Message}
        variant="h6"
        align="center"
        display="block"
        color="secondary"
      >
        {message}
      </Typography>
      <Box className={ButtonContainer}>
        <Button href={path} color="primary" variant="contained">
          {buttonText}
        </Button>
      </Box>
    </Paper>
  );
};

export default SimpleCardMessage;
