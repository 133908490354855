import React from "react";
import { useMediaQuery } from "@mui/material";
import {
  ExamGroupModal,
  AddNewProctors,
  AddNewStudents,
  AddRoles
} from "components";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { WarningForbiddenEditDeleteUsers } from "../components/Modals/WarningForbiddenEditDeleteUsers";

export const useModal = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const matches = useMediaQuery("(max-width:960px)");

  const useStyles = (matches) =>
    makeStyles((theme) => ({
      BASE: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 0.5, 2, 3),
        "&:focus": {
          outline: "none"
        }
      },
      EXAM_GROUP: {
        width: matches ? "100%" : "80%",
        height: "90%",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`
      },
      ADD_NEW_MEMBERS: {
        padding: theme.spacing(3),
        width: "50%",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        height: "80%"
      },
      FORBIDDEN_EDIT: {
        padding: theme.spacing(3),
        width: "30%",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        height: "50%"
      },
      ADD_ROLES: {
        padding: theme.spacing(3),
        width: "fit-content",
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`
      }
    }));

  const classes = useStyles(matches)();
  const getModal = (modalName, props) => {
    switch (modalName) {
      case "EXAM_GROUP":
        return <ExamGroupModal modalName={modalName} {...props} />;
      case "ADD_NEW_PROCTORS":
        return <AddNewProctors modalName={modalName} {...props} />;
      case "ADD_NEW_STUDENTS":
        return <AddNewStudents modalName={modalName} {...props} />;
      case "ADD_ROLES":
        return <AddRoles modalName={modalName} {...props} />;
      case "FORBIDDEN_EDIT":
        return (
          <WarningForbiddenEditDeleteUsers modalName={modalName} {...props} />
        );
      default:
        return null;
    }
  };

  const getModalClass = (modalName) => {
    switch (modalName) {
      case "EXAM_GROUP":
        return classNames(classes.BASE, classes.EXAM_GROUP);
      case "ADD_NEW_PROCTORS":
        return classNames(classes.BASE, classes.ADD_NEW_MEMBERS);
      case "ADD_NEW_STUDENTS":
        return classNames(classes.BASE, classes.ADD_NEW_MEMBERS);
      case "ADD_ROLES":
        return classNames(classes.BASE, classes.ADD_ROLES);
      case "FORBIDDEN_EDIT":
        return classNames(classes.BASE, classes.FORBIDDEN_EDIT);
      default:
        return "";
    }
  };

  return {
    getModal,
    getModalClass,
    handleOpen,
    handleClose,
    open
  };
};
