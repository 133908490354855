import React, { memo, useEffect, useRef, useState } from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  iFrameWrapper: {
    flexGrow: 1,
    position: 'relative',
    background: 'white',
    height: '100%',
    opacity: ({ invisible, finished }) => (invisible || finished ? 0 : 1),
    transition: '0.3s ease-out',
  },
  iFrameSpinner: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    color: 'grey',
    zIndex: 2,
  },
});

const GenericSphereEngineWorkSpace = ({ popupType, open, question }) => {
  const [loading, setLoading] = useState(true);
  const [invisible, setInvisible] = useState(false);
  const [widgetId, setWidgetId] = useState(null);
  const finished = false;
  const styles = useStyles({ invisible, finished });

  const iFrameRef = useRef(null);

  const SE_BASE = '74f2a553.widgets.sphere-engine.com';
  const SE_HTTPS = true;

  useEffect(() => {
    if (popupType === 'tasks' && open) {
      setInvisible(true);
    } else setInvisible(false);
  }, [popupType, open]);

  useEffect(() => {
    const wId = question?.details?.smeWidgetId || question?.details?.widgetId;
    setWidgetId(wId);
  }, [question]);

  /* eslint-disable no-return-assign, no-param-reassign */
  useEffect(() => {
    let js, fjs;

    if (widgetId) {
      (function (d, s, id) {
        window.SE_BASE = SE_BASE;
        window.SE_HTTPS = SE_HTTPS;
        window.SE = [];

        fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = `${
          SE_HTTPS ? 'https' : 'http'
        }://${SE_BASE}/static/sdk/sdk.min.js`;
        fjs.parentNode.insertBefore(js, fjs);

        js.onload = () => {
          if (window.SE.released) {
            // console.log(window?.SE?.widgets[0]?.iframe);
            setLoading(false);
          }
        };
      })(document, 'script', 'sphere-engine-jssdk');
    }

    return () => {
      if (js) js.remove();
    };
  }, [widgetId]); // eslint-disable-line
  /* eslint-enable no-return-assign, no-param-reassign */

  // if (window?.SE?.widgets[0]) {
  //   console.log(window.SE.widgets[0]);
  // }

  return (
    <div className={styles.iFrameWrapper}>
      {loading || !question ? (
        <div className={styles.iFrameSpinner}>loading..</div>
      ) : null}

      {widgetId ? (
        <div
          ref={iFrameRef}
          className="se-widget"
          data-id="example-widget"
          data-widget={widgetId}
          data-signature={question?.details?.signature}
          data-user-id={question?.details?.userId}
          data-nonce={question?.details?.nonce}
        />
      ) : null}
    </div>
  );
};

export default memo(GenericSphereEngineWorkSpace);
