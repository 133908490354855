import React, { memo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExamByProgramId,
  getExamStudentAccess,
  clearExams,
  setExam,
  clearExamsState
} from "../../store/slices/exams";
import { fetchPrograms } from "../../store/slices/programs";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  Grid,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  LinearProgress,
  Divider,
  Backdrop
} from "@mui/material";
import { PrevNextSection } from "../../components";
import { LINKS } from "../../constants/links";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    justifyContent: "center"
  },
  examSelectionContainer: {
    padding: theme.spacing(5),
    minWidth: "40%",
    maxWidth: "50%"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  examSelectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  },
  pleaseAllowMsg: {
    marginTop: theme.spacing(3)
  },
  certTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  formContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  formItems: {
    minWidth: "100%"
  },
  tableRow: {
    cursor: "pointer"
  },
  errAccess: {
    color: theme.palette.error.main,
    maxWidth: "40rem",
    textAlign: "center"
  },
  activeRow: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    "&:hover": {
      backgroundColor: `${lighten(
        theme.palette.secondary.main,
        0.85
      )} !important`
    }
  },
  tableHeadCell: {
    minWidth: "50px",
    fontWeight: "bold",
    color: theme.palette.text.primary
  },
  tableBodyCell: {
    color: theme.palette.text.primary
  },
  linearProgressBar: {
    backgroundColor: lighten(theme.palette.text.secondary, 0.97),
    minHeight: "4px"
  },
  tablePagination: {
    color: theme.palette.text.primary
  }
}));

const columns = [
  { id: "displayCode", label: "Exam Code", maxWidth: 50 },
  { id: "displayName", label: "Exam Name", minWidth: 150 }
];

const ExamsSelection = () => {
  const {
    root,
    examSelectionContainer,
    DividerContainer,
    DividerBar,
    examSelectionTitle,
    backdrop,
    formContainer,
    formItems,
    pleaseAllowMsg,
    certTitle,
    linearProgressBar,
    activeRow,
    tableRow,
    tableHeadCell,
    tableBodyCell,
    tablePagination
  } = useStyles();
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { data: programs } = useSelector((state) => state).programsSlice
  const {
    examsByProgramId,
    loading: examsLoading,
    loadingHasAccess,
    doesStudentHaveAccess,
    exam
  } = useSelector((state) => state).examsSlice

  const [programId, setProgramId] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedExam, setSelectedExam] = useState(null)
  const [lockRetake, setLockRetake] = useState(false)

  useEffect(() => {
    dispatch(clearExamsState())

    if (programs.length === 0) {
      dispatch(fetchPrograms())
    }
  }, [dispatch, programs.length])

  useEffect(() => {
    if (programId) {
      setLockRetake(doesStudentHaveAccess === false)
    }
  }, [programId, doesStudentHaveAccess])

  useEffect(() => {
    if (programId) {
      dispatch(fetchExamByProgramId(programId))
      setSelectedExam(null)
    } else {
      dispatch(clearExams())
    }
  }, [programId])

  const handleChange = useCallback(e => {
    dispatch(clearExamsState())

    setProgramId(e.target.value)
  }, [dispatch])

  const handleChangePage = useCallback(newPage => {
    setPage(newPage)
  }, [])

  const handleChangeRowsPerPage = useCallback(e => {
    setRowsPerPage(+e.target.value)
    setPage(0)
  }, [])

  const handleNext = useCallback(() => {
    dispatch(getExamStudentAccess(selectedExam.code)).then(({payload}) => {
      if (payload.doesStudentHaveAccess) {
        dispatch(setExam(selectedExam.id))
        navigate(LINKS.examsVerify)
      }
    })
  }, [doesStudentHaveAccess, programId, exam, selectedExam])

  return (
    <>
      <Backdrop className={backdrop} open={examsLoading}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={examSelectionContainer}>
          <Typography
            className={examSelectionTitle}
            align="center"
            variant="h5"
            color="primary">
            Exam Selection
          </Typography>
          <Box className={DividerContainer}>
            <Divider className={DividerBar}/>
          </Box>
          <Grid container className={formContainer}>
            <Grid item xs={6}>
              <FormControl className={formItems} variant="outlined">
                <InputLabel id="exam-label">
                  Select your program
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={programId}
                  onChange={handleChange}
                  label="Select your program">
                  {programs?.map((item, index) => {
                    return (
                      <MenuItem dense value={item.id} key={item.id + index}>
                        {item.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {lockRetake && (
            <Typography
              className={pleaseAllowMsg}
              color="secondary"
              variant="subtitle1"
              align="center">
              Please allow at least 20 days for a retake.{" "}
              {/*For any additional
              information or to be allowed an exception please contact us at
              &nbsp;
              <a href="mailto:support@knowledge-pillars.com">
                support@knowledge-pillars.com
              </a>*/}
            </Typography>
          )}
          {examsByProgramId.length ? (
            <Typography
              className={certTitle}
              variant="subtitle1"
              color="primary">
              Please select your Certification Exam:
            </Typography>
          ) : null}
          {examsByProgramId.length ? (
            <>
              <TableContainer component={Paper}>
                <Box className={linearProgressBar}>
                  {loadingHasAccess && <LinearProgress/>}
                </Box>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={`${column.id} ${index}`}
                          align={column.align}
                          className={tableHeadCell}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {examsByProgramId
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, num) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            className={classNames(
                              selectedExam?.id === row.id && activeRow,
                              tableRow
                            )}
                            tabIndex={-1}
                            key={`${row?.id || row.code} ${num}`}
                            onClick={() => setSelectedExam({
                              id: row.id,
                              code: row.code
                            })}>
                            {columns.map((column, index) => {
                              const value = row[column.id]

                              return (
                                <TableCell
                                  className={tableBodyCell}
                                  key={`${column.id} ${index}`}
                                  align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {examsByProgramId.length > 10 && (
                <TablePagination
                  className={tablePagination}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={examsByProgramId.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </>
          ) : null}
          <PrevNextSection
            handleNext={handleNext}
            disabledNext={
              !selectedExam || examsLoading || loadingHasAccess
            }
          />
        </Paper>
      </Grid>
    </>
  )
}

export default memo(ExamsSelection)
