import React, { useMemo } from 'react';
// components
import CodeLine from '../CodeLine/CodeLine';
// styles
import './CodeList.styles.css';

// component start
const CodeList = ({ provided, snapshot, codeLines, columnId }) => {

  // list of code block DOM elements
  const codeLinesListDOM = useMemo(() => {
    if (!codeLines?.length) return null;

    return codeLines.map((codeLine, index) => {
      return (
        <CodeLine codeLine={codeLine} key={codeLine?.id} index={index} columnId={columnId}/>
      );
    });
  }, [codeLines, columnId]);

  // render list of lines in column
  return (
    <div
      ref={provided.innerRef}
      className={`kps-cl-task-list ${snapshot.isDraggingOver ? 'is-dragging-over' : ''} ${columnId === 'Answer'
        ? 'is-result-column'
        : ''} par-cl-code-list`}
      {...provided.droppableProps}
    >
      {codeLinesListDOM}
      {provided.placeholder}
    </div>
  );
};

export default CodeList;