export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  STUDENT: 'student',
  SME: 'sme'
}

export const USER_GROUP = {
  SME: 'sme',
  ADMIN: 'admin'
}

export const EXAM_CODE = {
  HTML_CSS: 'hccs',
  ASPNET: 'dncs',
  AIEE: 'aiee',
  C_SHARP: 'ccs',
  JSCS: 'jscs',
  T_SQL: 'tscs' ,
  SQLCS: 'sqlcs',
  SQLCA: 'sqlca',
  // JAVASCRIPT: 'javascript',
  // HTML_CSS: 'htmlCss',
  // ASPNET: 'aspnet',
  // C_SHARP: 'csharp',
  PCS: 'pcs',
  PCA: 'pca',
  CCA: 'cca',
  WCE: 'wce',
  WCA: "wca",
  WCD: "wcd",
  WES: "wes",
  WWS: "wws",
  PHPCS: "phpcs",
  JCS: "jcs",
  JCA: "jca",
  CPCS: 'cpcs',
  CPPCS: 'cppcs',
}

export const examNameReplacer = {
  aiee: "AI Essentials for Educators",
  cca: "C# Coding Apprentice",
  ccs: "C# Coding Specialist",
  cpcs: "C Coding Specialist",
  cppcs: "C++ Coding Specialist",
  dncs: ".NET Coding Specialist",
  hccs: "HTML CSS Coding Specialist",
  hcca: "HTML CSS Coding Apprentice",
  java: "JAVA Specialist",
  jca: "Java Coding Apprentice",
  jcs: "Java Coding Specialist",
  jsca: "JavaScript Coding Apprentice",
  jscs: "JavaScript Coding Specialist",
  pca: "Python Coding Apprentice",
  pcs: "Python Coding Specialist",
  php: "PHP Specialist",
  phpcs: "PHP Coding Specialist",
  sqlca: "SQL Coding Apprentice",
  sqlcs: "SQL Coding Specialist",
  tscs: "T-SQL Coding Specialist",
  wca: "WordPress Certified Administrator",
  wcd: "WordPress Certified Developer",
  wce: "WordPress Certified Editor",
  wes: "WordPress Elementor Specialist",
  wws: "WordPress WooCommerce Specialist"
}

export const specialExams = [EXAM_CODE.AIEE]

export const examSessionStatuses = {
  "Initiating": "Initiating",
  "Initiated": "Initiated",
  "InProgress": "InProgress",
  "Finished": "Finished",
  "ScoringInProgress": "ScoringInProgress",
  "Scored": "Scored",
  "ProctorReviewInProgress": "ProctorReviewInProgress",
  "ReviewedByProctor": "ReviewedByProctor",
  "Canceled": "Canceled"
}

export const kpEmail = 'support@knowledge-pillars.com'

