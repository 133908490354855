import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import VoucherService from '../../services/vouchers.service';

export const initialState = {
  loading: false,
  hasErrors: false,
  allVoucher: null,
  examVouchers: null,
};

export const initialStateUpdatedVoucher = {
  loading: false,
  hasErrors: false,
  updated: null,
};

export const fetchVouchers = createAsyncThunk(
  'vouchers/fetchVouchers',
  async () => {
    const response = await VoucherService.get();
    return response?.data || response;
  }
);

export const updateVoucherStatus = createAsyncThunk(
  'currentVoucher/updateVoucherStatus',
  async (body) => {
    const response = await VoucherService.toggleStatus(body);
    return response.data;
  }
);

export const validateVoucher = createAsyncThunk(
  'currentVoucher/validateVoucher',
  async (_, { getState, rejectWithValue }) => {

    const { voucherCode } = getState().paymentSlice;
    const { exam } = getState().examsSlice;

    try {
      const res = await VoucherService.validateVoucher(exam.code, voucherCode);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.data.error.message);
    }
  }
);


const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVouchers.pending]: (state) => {
      state.loading = true;
    },
    [fetchVouchers.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [fetchVouchers.fulfilled]: (state, action) => {
      state.allVoucher = action.payload;
      state.examVouchers = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
  },
});


const voucherUpdateSlice = createSlice({
  name: 'currentVoucher',
  initialState: initialStateUpdatedVoucher,
  reducers: {},
  extraReducers: {
    [updateVoucherStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateVoucherStatus.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [updateVoucherStatus.fulfilled]: (state, action) => {
      state.updated = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    //pending
    [validateVoucher.pending]: (state) => {
      state.loading = true;
    },
    //fulfilled
    [validateVoucher.fulfilled]: (state) => {
      state.hasErrors = false;
      state.loading = false;
    },
    //rejected
    [validateVoucher.rejected]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});


export default combineReducers({
  vouchers: vouchersSlice.reducer,
  currentVoucher: voucherUpdateSlice.reducer
});

export const validatePaymentVoucher = () => async (dispatch) => {
  try {
    return dispatch(validateVoucher());
  } catch (err) {
    return console.error(err);
  }
};





