import React from "react";
import { makeStyles } from "@mui/styles";
import { Link, Typography, Grid, Paper, Divider, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  forgotContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      minWidth: "80%",
      maxWidth: "90%"
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "70%"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "45%",
      maxWidth: "55%"
    }
  },
  forgotTitle: {
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  backToLink: {
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

function ForgotSuccess() {
  const {
    root,
    forgotContainer,
    forgotTitle,
    DividerContainer,
    DividerBar,
    backToLink
  } = useStyles();

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={forgotContainer}>
        <Typography
          className={forgotTitle}
          align="center"
          variant="h5"
          color="primary"
        >
          Password Changed!
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>
        <Typography variant="subtitle1" align="center">
          To sign in with you new password{" "}
          <Link href="/" className={backToLink}>
            click here.
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default ForgotSuccess;
