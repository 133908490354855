import { Amplify } from "aws-amplify";

export const amplifyConfig: Parameters<typeof Amplify.configure>[0] = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
      userPoolClientId:
        process.env.REACT_APP_AWS_COGNITO_USERPOOL_WEB_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
          scopes: ["openid"],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: "code",
        },
      },
    },
  },
};
