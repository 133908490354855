import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function getSmeAssignedExams() {
  return apiCall({
    url: `${ENDPOINT.sme}`,
    method: "GET",
  });
}

function getSmeExamQuestions(examCode) {
    return apiCall({
        url: `${ENDPOINT.smeExamQuestions}${examCode}`,
        method: "GET",
  });
}

function getSmeTask(taskId) {
    return apiCall({
        url: `${ENDPOINT.smeTask}${taskId}`,
        method: "GET",
  });
}

const SmeService = {
    getSmeAssignedExams,
    getSmeExamQuestions,
    getSmeTask
};

export default SmeService;