import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {openAlert} from "store/slices/alert"
import {sebCheck, SebCheckResult} from "utils/sebCheck"

interface SafeExamBrowserState {
  loading: boolean
  hasErrors: boolean
  errorMessage: string | null
  isRunningSEB: boolean
  sebCheckResult: SebCheckResult | null
}

const initialState: SafeExamBrowserState = {
  loading: false,
  hasErrors: false,
  errorMessage: null,
  isRunningSEB: false,
  sebCheckResult: null
}

export const checkSafeExamBrowser = createAsyncThunk(
  "safeExamBrowser/checkSEB",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const sebCheckResult = await sebCheck()
      return {sebCheckResult}
    } catch (error) {
      dispatch(
        openAlert({
          severity: "error",
          message: "Safe Exam Browser verification failed"
        })
      )
      return rejectWithValue(error)
    }
  }
)

const safeExamBrowserSlice = createSlice({
  name: "safeExamBrowser",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    clearError: (state) => {
      state.hasErrors = false
      state.errorMessage = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkSafeExamBrowser.pending, (state) => {
        state.loading = true
        state.hasErrors = false
        state.errorMessage = null
      })
      .addCase(checkSafeExamBrowser.fulfilled, (state, {payload}) => {
        state.loading = false
        state.sebCheckResult = payload.sebCheckResult
        state.isRunningSEB = !!payload.sebCheckResult 
      })
      .addCase(checkSafeExamBrowser.rejected, (state, action) => {
        state.loading = false
        state.hasErrors = true
        state.errorMessage = action.payload as string
        state.sebCheckResult = null
        state.isRunningSEB = false
      })
  }
})

export const {
  startLoading,
  stopLoading,
  clearError
} = safeExamBrowserSlice.actions

export default safeExamBrowserSlice.reducer
