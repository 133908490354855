import React from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
    width: "100%"
  }
}));

const ExamVouchersSelect = ({
  examVouchers,
  voucherSelectValue,
  handleChooseVoucher
}) => {
  const { formControl } = useStyles();

  return (
    <Grid>
      <FormControl variant="outlined" className={formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Or Choose Voucher
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={voucherSelectValue}
          onChange={handleChooseVoucher}
          label="Or Choose Voucher">
          <MenuItem value={""}>
            <em>None</em>
          </MenuItem>
          {examVouchers.map((v) => {
            return (
              <MenuItem value={v.code}>
                {v.examCode} (
                {format(Date.parse(v.createDate), "dd  MMMM yyyy")})
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default ExamVouchersSelect;
