import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function initialize({ examCode, testingGroupCode }) {
  return apiCall({
    url: `${ENDPOINT.examsSessionInit}`,
    method: "POST",
    data: {
      examCode: examCode,
      testingGroupCode: testingGroupCode
    },
  });
}
function start(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/start`,
    method: "PATCH",
  });
}

function getDetails(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/details`,
    method: "GET",
  });
}

function getStatus(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/status`,
    method: "GET",
  });
}

function getScore(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/score`,
    method: "GET",
  });
}

function cuncel(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/cancel`,
    method: "PATCH",
  });
}

function submitTusk(id, taskCode) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/task/${taskCode}`,
    method: "POST",
  });
}

function submitTuskMultipleAnswers(id, taskCode, multiTasksAnswers = null, code = null) {
  const dataConfig = {};
  if (multiTasksAnswers) dataConfig.answers = multiTasksAnswers;
  if (code) dataConfig.code = code;
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/task/${taskCode}`,
    method: "POST",
    data: dataConfig,
  });
}

function skipTusk(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/skip-current-task`,
    method: "PATCH",
  });
}

function forceMoveNextState(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/force-move-to-next-state`,
    method: "PATCH",
  });
}

function forceFinish(id) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${id}/force-finish`,
    method: "PATCH",
  });
}

function moveToTusk(sessionId, taskId) {
  return apiCall({
    url: `${ENDPOINT.examSession}/${sessionId}/move-to-task`,
    method: "PATCH",
    data: {
      taskId: taskId,
    },
  });
}



function removeFlaggedTask(sessionId, taskId) {
  return apiCall({
    url: `/exam/sessions/${sessionId}/flag/${taskId}`,
    method: "DELETE",
  });
}

function addFlaggedTask(sessionId, taskId) {
  return apiCall({
    url: `/exam/sessions/${sessionId}/flag/${taskId}`,
    method: "POST",
  });
}

function getTasksAnswers(sessionId) {
  return apiCall({
    url: `/answers/${sessionId}`,
    method: "GET",
  });
}

function getSessionTasksStatus(sessionId) {
  return apiCall({
    url: `/session-tasks-status/exam/${sessionId}`,
    method: "GET",
  });
}

function getSessionData(sessionId) {
  return apiCall({
    url: `/exam/sessions/${sessionId}`,
    method: "GET",
  });
}

function getCurrentTaskData(sessionId, taskId) {
  return apiCall({
    url: `exam/sessions/${sessionId}/select-task/${taskId}`,
    method: "GET",
  });
}


const ExamSessionService = {
  start,
  initialize,
  //
  getScore,
  getDetails,
  getStatus,
  //
  cuncel,
  submitTusk,
  submitTuskMultipleAnswers,
  skipTusk,
  forceFinish,
  forceMoveNextState,
  moveToTusk,

  removeFlaggedTask,
  addFlaggedTask,

  getTasksAnswers,
  getSessionTasksStatus,
  getSessionData,
  getCurrentTaskData,
};

export default ExamSessionService;
