import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import TodayIcon from "@mui/icons-material/Today";
import Autocomplete from "@mui/lab/Autocomplete";
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { InputBaseProps } from "@mui/material/InputBase";
import { ICity, ICountry, IState } from "country-state-city";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";

interface Props {
  touched: FormikTouched<any>;
  values: any;
  errors: FormikErrors<any>;
  fieldName: string;
  label: string;
  type?: string;
  handleBlur?: InputBaseProps["onBlur"];
  handleChange?: InputBaseProps["onChange"];
  handleSelect?: (value: string) => void;
  options?: (ICountry | IState | ICity)[];
}

export default function FieldTemplate({
  touched,
  values,
  errors,
  fieldName,
  label,
  type = "text",
  handleBlur,
  handleChange,
  handleSelect,
  options,
}: Props) {
  const getTextFieldIcon = (fieldName: string) => {
    switch (fieldName) {
      case "firstName":
        return <PersonIcon />;
      case "lastName":
        return <PersonIcon />;
      case "email":
        return <EmailIcon />;
      case "birthdate":
        return <TodayIcon />;
      case "phone":
        return <PhoneIcon />;
      case "address":
        return <HomeIcon />;
      case "city":
        return <LocationCityIcon />;
      case "country":
        return <PublicIcon />;
      case "state":
        return <ApartmentIcon />;
      case "postcode":
        return <MarkunreadMailboxIcon />;
      default:
    }
  };

  return (
    <Grid item md={6} xs={12}>
      {options ? (
        <>
          <Autocomplete
            disableClearable
            forcePopupIcon={false}
            id="country-select-demo"
            options={options.map((a) => a?.name)}
            value={values[fieldName] || ""}
            onChange={(e, value) => handleSelect(value)}
            renderInput={(params) => (
              <TextField
                error={Boolean(touched[fieldName] && errors[fieldName])}
                {...params}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="start">
                      {fieldName !== "birthdate"
                        ? getTextFieldIcon(fieldName)
                        : ""}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {Boolean(touched[fieldName] && errors[fieldName]) && (
            <FormHelperText
              sx={{
                ml: "14px",
                mr: "14px",
              }}
              error
            >
              {touched[fieldName] && errors[fieldName]}
            </FormHelperText>
          )}
        </>
      ) : (
        <TextField
          error={Boolean(touched[fieldName] && errors[fieldName])}
          helperText={touched[fieldName] && errors[fieldName]}
          value={values[fieldName]}
          label={label}
          name={fieldName}
          onBlur={handleBlur}
          onChange={handleChange}
          type={type}
          fullWidth
          disabled={fieldName === "email"}
          variant="outlined"
          InputLabelProps={type === "date" ? { shrink: true } : {}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {fieldName !== "birthdate" ? getTextFieldIcon(fieldName) : ""}
              </InputAdornment>
            ),
          }}
        />
      )}
    </Grid>
  );
}
