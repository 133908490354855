import { gql } from "@apollo/client";

export default gql`
  query GetUser {
    me {
      _id
      cognitoUserId
      username
      schoolId
      studentId
      firstName
      lastName
      photoUrl
      proctorSignedAt
      email
      country
      state
      city
      postcode
      address
      birthdate
      phone
    }
  }
`;
