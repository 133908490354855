import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { kpEmail } from "../../constants/constants";

const requestCertificateUrl = `mailto:${kpEmail}`;

const useStyles = makeStyles((theme) => ({
  CertificateContainer: {
    padding: theme.spacing(4),
    margin: theme.spacing(1.5),
    minWidth: "650px"
  },
  certificationTitle: {
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  CertificationImage: {
    display: "flex",
    flexDirection: "flex-start",
    width: "170px",
    height: "170px"
  },
  CertificationInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  CertInfoContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3)
  },
  CertificationInfo: {
    color: theme.palette.secondary.main,
    fontWeight: "300"
  },
  ButtonContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const Certificate = ({ name, status, certification, date, score, imgSrc }) => {
  const {
    CertificateContainer,
    certificationTitle,
    DividerContainer,
    DividerBar,
    CertificationImage,
    CertificationInfoContainer,
    CertificationInfo,
    CertInfoContent,
    ButtonContainer
  } = useStyles();

  return (
    <Paper className={CertificateContainer}>
      <Typography
        className={certificationTitle}
        align="center"
        variant="h6"
        color="primary"
      >
        {name}
      </Typography>
      <Box className={DividerContainer}>
        <Divider className={DividerBar} />
      </Box>
      <Grid container>
        <Grid item xs={4}>
          <Avatar
            className={CertificationImage}
            src={imgSrc}
            alt={certification}
            variant="rounded"
          />
        </Grid>
        <Grid item xs={8} className={CertificationInfoContainer}>
          <Box className={CertInfoContent}>
            <Typography color="textPrimary">
              Certification:{" "}
              <span className={CertificationInfo}>{certification}</span>
            </Typography>
            <Typography color="textPrimary">
              Exam Status: <span className={CertificationInfo}>{status}</span>
            </Typography>
            <Typography color="textPrimary">
              Date: <span className={CertificationInfo}>{date}</span>
            </Typography>
            <Typography color="textPrimary">
              Score: <span className={CertificationInfo}>{score}</span>
            </Typography>
          </Box>
          <Box className={ButtonContainer}>
            <Button
              color="primary"
              variant="outlined"
              href={requestCertificateUrl}
            >
              Order Official Certificate
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Certificate;
