import { createSlice } from "@reduxjs/toolkit";

// severity: ["error" | "info" | "success" | "warning"]
// variant: ["filled" | "outlined" | "standard"]

export const initialState = {
  open: false,
  title: "",
  message: "",
  severity: "",
  variant: "standard",
  vertical: "bottom",
  horizontal: "center",
};

const alert = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert: (state, { payload }) => {
      state.open = true;
      if (payload.title) state.title = payload.title;
      if (payload.message) state.message = payload.message;
      if (payload.variant) state.variant = payload.variant;
      if (payload.severity) state.severity = payload.severity;
      if (payload.vertical) state.vertical = payload.vertical;
      if (payload.horizontal) state.horizontal = payload.horizontal;
    },
    closeAlert: () => initialState,
  },
});

export const { openAlert, closeAlert } = alert.actions;

export default alert.reducer;
