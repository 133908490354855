import React, { useCallback, memo } from "react";
import { CustomTable } from "components";
import { makeStyles } from "@mui/styles";
import { Grid, Button, CircularProgress } from "@mui/material";

const columns = [
  { id: "username", label: "Name", minWidth: 100, align: "left" },
  { id: "email", label: "Email", minWidth: 100, align: "left" },
  {
    id: "role",
    label: "Role",
    minWidth: 120,
    align: "center"
  },
  {
    id: "checkbox",
    label: "",
    minWidth: 80,
    align: "center"
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },

  rootTable: {
    height: "100%",
    overflow: "auto"
  },
  btnSection: {
    padding: "1rem 0"
  }
});

const AddRoles = (props) => {
  const { rowsModal, setRowsModal, handleAdd, handleClose, loading } = props;
  const classes = useStyles();

  const hadleClickCheckbox = useCallback(
    (value) => {
      const clickedArr = rowsModal.map((item, index) => {
        if (index === value) {
          return { ...item, checkbox: !item["checkbox"] };
        }
        return item;
      });
      setRowsModal(clickedArr);
    },
    [setRowsModal, rowsModal]
  );

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <CustomTable
          rows={rowsModal}
          columns={columns}
          hadleClickCheckbox={hadleClickCheckbox}
          tableClass={classes.rootTable}
          containerHeight={400}
        />
      )}
      <Grid
        item
        container
        justifyContent="space-between"
        className={classes.btnSection}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClose()}
        >
          CLOSE
        </Button>

        <Button variant="contained" color="primary" onClick={() => handleAdd()}>
          ADD SELECTED
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(AddRoles);
