import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PaymentsService from "../../services/payment.service";

export const initialState = {
  loading: false,
  hasErrors: false,
  paymentMethod: "",
  voucherCode: "",
};

export const addUserPayment = createAsyncThunk(
  "payment/addUserPayment",
  async (_, { getState, rejectWithValue }) => {
    const { paymentMethod, voucherCode } = getState().paymentSlice;

    const { currentSessionDetails } = getState().examsSessionSlice;

    const data = {
      examSessionId: currentSessionDetails._id,
      paymentMethod: paymentMethod,
    };

    if (paymentMethod === "voucher") {
      data["voucherCode"] = voucherCode.toString();
    }

    try {
      const res = await PaymentsService.addUserPayment(data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.data.error.message);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentMetod: (state, { payload }) => {
      state.paymentMethod = payload;
    },
    setVoucherCode: (state, { payload }) => {
      state.voucherCode = payload;
    },
    clearVoucherError: (state) => {
      state.hasErrors = false;
    },
  },
  extraReducers: {
    //pending
    [addUserPayment.pending]: (state) => {
      state.loading = true;
    },
    //fulfilled
    [addUserPayment.fulfilled]: (state) => {
      state.hasErrors = false;
      state.loading = false;
    },
    //rejected
    [addUserPayment.rejected]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = payload;
    },
  },
});

export const {
  setPaymentMetod,
  setVoucherCode,
  clearVoucherError,
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const paymentMethod = (method) => (dispatch) => {
  dispatch(setPaymentMetod(method));
};

export const voucherCode = (code) => (dispatch) => {
  dispatch(setVoucherCode(code));
};

export const addPaymentPromise = () => async (dispatch) => {
  try {
    return await dispatch(addUserPayment());
  } catch (err) {
    return console.error(err);
  }
};
