import React from "react";

import { groupModal } from "./styles";
import { TableList } from "components/TableList";

import { Button, Typography, Grid } from "@mui/material";

const GroupProctorsSection = ({
  handleOpenAddProctors,
  selectedProctorsForDeleting,
  setSelectedProctorsForDeleting,
  handleChange,
  allProctors,
  group
}) => {
  const classes = groupModal();
  return (
    <Grid item xs={12} sm={6} container>
      <Grid item xs={12} container alignContent="flex-start">
        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
          <Grid item xs={6}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ textAlign: "left" }}
              className={classes.label}
            >
              Proctors:
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenAddProctors()}
              style={{ margin: "1rem" }}
            >
              Add+
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableList
            mode="delete"
            title={"Proctors"}
            rows={allProctors.filter((item) =>
              group.proctorIds.includes(item.id)
            )}
            selected={selectedProctorsForDeleting}
            setSelected={setSelectedProctorsForDeleting}
            handleChange={() => {
              const newMembers = group.proctorIds.filter(
                (el) => !selectedProctorsForDeleting.includes(el)
              );
              return handleChange({
                target: { name: "proctorIds", value: newMembers }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupProctorsSection;
