export default function swapOneColumnElements(dragEndResult, startColumn, columns) {
  const { source, destination, draggableId } = dragEndResult;
  // move elements in the array
  const newCodeLineIdsArr = [...startColumn.codeLineIds];
  newCodeLineIdsArr.splice(source.index, 1);
  newCodeLineIdsArr.splice(destination.index, 0, draggableId);

  // create a new column with reordered array
  const newColumn = {
    ...startColumn,
    codeLineIds: newCodeLineIdsArr,
  };

  // create new state object to override old info
  return {
    ...columns,
    [newColumn.id]: newColumn,
  };
}