import * as Sentry from '@sentry/react';
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import programsSlice from "./slices/programs";
import examsSlice from "./slices/exams";
import examsSessionSlice from "./slices/examsSession";
import vouchers from "./slices/vouchers";
import user from "./slices/user";
import organization from "./slices/organization";
import license from "./slices/license";
import groupSlice from "./slices/groups";
import rolesSlice from "./slices/roles";
import paymentSlice from "./slices/payment";
import notifications from "./slices/notifications";
import alert from "./slices/alert";
import smeSlice from "./slices/sme";
import safeExamBrowser from './slices/safeExamBrowser';

const reducer = combineReducers({
  examsSessionSlice,
  programsSlice,
  examsSlice,
  user,
  vouchersData: vouchers,
  licenseData: license,
  organization,
  groupSlice,
  rolesSlice,
  paymentSlice,
  notifications,
  alert,
  smeSlice,
  safeExamBrowser
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  enhancers: [Sentry.createReduxEnhancer()]
});

export default store;
