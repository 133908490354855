import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { CustomTable } from "components";
import {
  fetchExamQuestions,
  fetchExams,
  setSelectedExam,
  setSelectedQuestion
} from "../../../store/slices/sme";
import { useNavigate, useParams } from "react-router-dom";
import { LINKS } from "../../../constants/links";
import { BackdropLoad } from "../../../components/Loaders";
import {
  reviewTableConfig,
  scoreTableConfig
} from "../styles/TasksList.styles";
import BackButton from "../../../components/Buttons/BackButton";

function TasksList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();

  // redux state
  const { loading, questions, selectedExam } = useSelector((state) => {
    const { loading, examQuestions, selectedExam } = state.smeSlice || {};
    return {
      loading,
      questions: examQuestions,
      selectedExam
    };
  });

  const reviewColumns = reviewTableConfig();
  const scoreColumns = scoreTableConfig();
  const column =
    urlParams.selectedOption === "ReviewQuestions"
      ? reviewColumns
      : scoreColumns;
  const actionTitle =
    urlParams.selectedOption === "ReviewQuestions"
      ? "Question Review"
      : "Standard Setting";

  const handleCLickRow = useCallback(
    (e, id) => {
      const selectedQuestion = questions.find((q) => q.id === id);
      dispatch(setSelectedQuestion(selectedQuestion));

      if (urlParams.selectedOption === "ReviewQuestions") {
        navigate(`${LINKS.smeReviewTasks}/${urlParams.examCode}`);
        return;
      }
      navigate(
        `/sme/tasks/score/${urlParams.examCode}/${selectedQuestion.id}`
      );
    },
    [dispatch, questions, urlParams.selectedOption, urlParams.examCode]
  );

  const handleBackClick = () => {
    navigate(LINKS.smeSelectAction, {replace: true});
  };

  // on component mounted actions
  useEffect(() => {
    if (!questions && !selectedExam && !loading) {
      // helper function if component has been reloaded
      const initComponent = async () => {
        await dispatch(fetchExams());
        await dispatch(setSelectedExam(urlParams.examCode));
      };
      initComponent();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedExam?.examCode) return;

    dispatch(fetchExamQuestions(selectedExam.examCode));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  return (
    <>
      {loading && <BackdropLoad open={loading} />}

      {selectedExam && questions && (
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <BackButton
            buttonLabel="Select Action"
            handleBackClick={handleBackClick}
          />
          <Grid item xs={10}>
            <Typography variant="h5">{`${selectedExam.examDisplayName} (${selectedExam.examDisplayCode}) - ${actionTitle}`}</Typography>
          </Grid>

          <Grid item xs={10}>
            {!loading && (
              <CustomTable
                rows={questions}
                columns={column}
                containerHeight={400}
                handleClickRow={handleCLickRow}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TasksList;
