import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Paper, Divider, Grid, Button, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  userInfoContainer: {
    padding: theme.spacing(5)
  },
  DividerContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  userInfoTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  messageContainer: {
    display: "flex"
  },
  successIcon: {
    marginRight: theme.spacing(1)
  },
  goBackButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
  }
}));

function SuccessMessage({
  text = "Your Account was successfully updated!",
  goBackSection,
  handleGoBack
}) {
  const {
    root,
    userInfoContainer,
    userInfoTitle,
    DividerContainer,
    DividerBar,
    messageContainer,
    successIcon,
    goBackButtonContainer
  } = useStyles();

  return (
    <Grid className={root} container justifyContent="center" alignItems="center">
      <Paper className={userInfoContainer}>
        <Typography
          className={userInfoTitle}
          align="center"
          variant="h6"
          color="primary"
        >
          Updated!
        </Typography>
        <Box className={DividerContainer}>
          <Divider className={DividerBar} />
        </Box>
        <Box className={messageContainer}>
          <CheckCircleOutlineIcon className={successIcon} />
          <Typography
            className={userInfoTitle}
            align="center"
            variant="subtitle1"
            color="textPrimary"
          >
            {text}
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} className={goBackButtonContainer}>
            {goBackSection && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoBack}
              >
                Go back
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default SuccessMessage;
