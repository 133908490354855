import React from "react";
import { TextField, MenuItem } from "@mui/material";

function TableSelect({
  value,
  handleChange,
  helperText,
  className,
  noValidate = true,
  id = "standard-select-currency",
  label = "",
  autoComplete = "off",
  align = "left",
  arrSelectOptions,
  variant = "standard",
  name = "customSelect",
  disabled = false,
  error
}) {
  return (
    <form
      className={className}
      noValidate={noValidate}
      autoComplete={autoComplete}
    >
      <div>
        <TextField
          className={className}
          variant={variant}
          id={id}
          select
          label={label}
          value={value}
          name={name}
          onChange={handleChange}
          helperText={helperText}
          error={error}
          disabled={disabled}
        >
          {arrSelectOptions.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              onClick={(e) => e.stopPropagation()}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </form>
  );
}

export default TableSelect;
