import React, { useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/material/styles";

import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { paymentMethod } from "store/slices/payment";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Box,
  Divider,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { CardMessage, PrevNextSection } from "components";
import PaymentIcon from "@mui/icons-material/Payment";
import { LINKS } from "constants/links";
import { initExamSession } from "store/slices/examsSession";

const SITE_LICENCE = "Use Site License";
const VOUCHER = "Redeem Voucher Code";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "80px",
    paddingBottom: "80px",
    displayl: "flex",
    justifyContent: "center"
  },
  paymentContainer: {
    padding: theme.spacing(5),
    minWidth: "40%",
    maxWidth: "60%"
  },
  paymentTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  DividerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  DividerBar: {
    height: "2px",
    margin: "auto",
    width: "50%",
    backgroundColor: theme.palette.kpYellowColors.main
  },
  paymentSelectTitle: {
    display: "flex",
    marginBottom: theme.spacing(1)
  },
  formContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    minWidth: "60%"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    padding: theme.spacing(4),
    backgroundColor: lighten(theme.palette.text.secondary, 0.98)
  },

  button: {
    margin: theme.spacing(1, 1, 0, 0)
  },
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    color: "#ffff"
  }
}));

const ExamsPaymentMethod = () => {
  const {
    root,
    paymentContainer,
    paymentTitle,
    DividerBar,
    DividerContainer,
    icon,
    formControl,
    formContainer,
    paymentSelectTitle,
    backdrop
  } = useStyles();
  // const classes = useStyles()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { exam } = useSelector((state) => state).examsSlice;
  const { loading: sessionLoading, hasErrors } = useSelector(
    (state) => state
  ).examsSessionSlice;
  const { loading: paymentsLoadin } = useSelector(
    (state) => state
  ).paymentSlice;

  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("Choose to continue");

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setHelperText(" ");
    setError(false);
  };

  const handleVoucher = useCallback(() => {
    dispatch(paymentMethod(value));

    navigate(LINKS.examsVoucher);
    setError(false);
  }, [ value, dispatch]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (value === "voucher") {
        handleVoucher();
      } else if (value === "license") {
        dispatch(paymentMethod(value));
        dispatch(initExamSession(exam.code)).then(({ error }) => {
          if (!error) {
            navigate(LINKS.examsInfo);
            return;
          }
        });
      } else {
        setHelperText("Please select an option.");
        setError(true);
      }
    },
    [value, exam.code] // eslint-disable-line
  );

  const handlePrev = useCallback(() => {
    navigate(LINKS.examsVerify);
  }, []);

  if (!exam?.code) {
    return (
      <Navigate to={LINKS.examsSelection}/>
    )
  }

  if (hasErrors) {
    return <CardMessage message={hasErrors} backTo />;
  }

  return (
    <>
      <Backdrop className={backdrop} open={sessionLoading || paymentsLoadin}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className={root} container justifyContent="center" alignItems="center">
        <Paper className={paymentContainer}>
          <Typography
            className={paymentTitle}
            align="center"
            variant="h5"
            color="primary"
          >
            Payment Method
          </Typography>

          <Box className={DividerContainer}>
            <Divider className={DividerBar} />
          </Box>
          <Typography align="center" variant="subtitle1" color="primary">
            <b>{exam.displayCode}:</b> {exam.displayName}
          </Typography>

          <Grid item>
            <form className={formContainer}>
              <FormControl
                component={Paper}
                error={error}
                className={formControl}
              >
                <Box className={paymentSelectTitle}>
                  <PaymentIcon color="secondary" className={icon} />
                  <Typography variant="subtitle1" color="secondary">
                    Select the payment method below:
                  </Typography>
                </Box>
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={value}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value={"license"}
                    control={<Radio />}
                    label={
                      <Typography variant="subtitle2" color="textSecondary">
                        {SITE_LICENCE}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={"voucher"}
                    control={<Radio />}
                    label={
                      <Typography variant="subtitle2" color="textSecondary">
                        {VOUCHER}
                      </Typography>
                    }
                  />
                </RadioGroup>
                <FormHelperText>{helperText}</FormHelperText>
              </FormControl>
            </form>
          </Grid>
          <PrevNextSection
            handlePrev={handlePrev}
            handleNext={handleSubmit}
            disabledPrev={sessionLoading || paymentsLoadin}
            disabledNext={sessionLoading || paymentsLoadin}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default ExamsPaymentMethod;
