import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button } from "@mui/material";
import { CustomTable } from "components";
import CustomModal from "components/Modals/Modal";
import { useModal } from "hooks/useModal";

import {
  clearMessage,
  getNotificationsByProctoringType
} from "store/slices/notifications";
import { openAlert } from "store/slices/alert";

import { checkSelect, groupsKeys } from "utils/validation";
import {
  clearAllFormFields,
  createGroup,
  getAdditionalDataForCreateExamGroup,
  getGroup,
  saveFormFields,
  updateGroup,
  updateGroupStatus
} from "store/slices/groups";

const selectOpt = [
  {
    value: "active",
    label: "Active"
  },
  {
    value: "inactive",
    label: "Inactive"
  }
];

const columns = [
  { id: "id", label: "Group ID", minWidth: 100, align: "center" },
  { id: "name", label: "Group Name", minWidth: 100, align: "center" },
  {
    id: "studentIds",
    label: "Nr. Of Students",
    minWidth: 60,
    align: "center"
  },
  {
    id: "select",
    label: "Status",
    minWidth: 120,
    align: "center"
  }
];

const useStyles = makeStyles({
  rootRow: {
    maxWidth: "100%"
  }
});

function Groups() {
  const { getModal, getModalClass, handleOpen, handleClose, open } = useModal();

  const classes = useStyles();
  const [mode, setMode] = useState("");
  const [attempt, setAttemt] = useState(false);

  const [examGroupStatusIdChanging, setExamGroupStatusIdChanging] =
    useState(null);
  const dispatch = useDispatch();
  const {
    allGroups,
    loading,
    group,
    allLicense,
    allTeachers,
    allStudents,
    allProctors,
    allExams,
    allPrograms
  } = useSelector((state) => state.groupSlice);

  const { userOrganizationId } = useSelector((state) => state.user);

  const selectOptions = () => {
    const optionsLicense = allLicense.map(({ id, description }) => ({
      value: id,
      label: description
    }));
    const optionsTeacher = allTeachers.map(({ id, email, username }) => ({
      value: id,
      label: `${username}, @: ${email}`
    }));
    const optionsExam = allExams.map(({ id, displayName }) => ({
      value: id,
      label: displayName
    }));
    const optionsProgram = allPrograms.map(({ id, displayName }) => ({
      value: id,
      label: displayName
    }));
    return {
      optionsLicense,
      optionsTeacher,
      optionsExam,
      optionsProgram
    };
  };

  useEffect(() => {
    dispatch(getGroup());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!open) {
      dispatch(clearAllFormFields());
    }
    return () => {
      setAttemt(false);
      dispatch(clearAllFormFields());
      dispatch(clearMessage());
    };
  }, [open]); // eslint-disable-line

  const handleChangeStatus = (e, id) => {
    const status = e.target.value;

    setExamGroupStatusIdChanging(id);
    dispatch(updateGroupStatus({ id, status }, status)).then(() => {
      dispatch(getGroup());
    });
  };

  const handleEditExamGroup = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setMode("edit");
    dispatch(getGroup(id));
    handleOpen();
  };

  const handleAddExamGroup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAdditionalDataForCreateExamGroup());
    setMode("add");
    handleOpen();
  };

  const handleFillFormData = ({ target }) => {
    const { name, value } = target;
    dispatch(saveFormFields({ name, value }));
  };

  useEffect(() => {
    if (mode === "add") {
      const name = "organizationId";
      const value = userOrganizationId;
      dispatch(saveFormFields({ name, value }));
    }
  }, [mode]); // eslint-disable-line

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      checkSelect(group[groupsKeys.GROUP_NAME.key]) ||
      checkSelect(group[groupsKeys.STATUS.key]) ||
      checkSelect(group[groupsKeys.PROGRAM.key]) ||
      checkSelect(group[groupsKeys.LICENSE.key]) ||
      checkSelect(group[groupsKeys.EXAM.key]) ||
      checkSelect(...group[groupsKeys.TEACHERS.key]) ||
      checkSelect(group[groupsKeys.PROCTORING.key])
    ) {
      setAttemt(true);
      return;
    }
    const body = {
      ...group,
      endTime: group.asyncProctoring
        ? ""
        : new Date(group.endTime).toISOString(),
      startTime: group.asyncProctoring
        ? ""
        : new Date(group.startTime).toISOString()
    };

    if (mode === "add") {
      const res = await dispatch(createGroup(body));
      dispatch(getGroup());
      handleClose();

      if (res.error) return;

      const notificationRes = await dispatch(
        getNotificationsByProctoringType(group.typeProctoring)
      );

      dispatch(
        openAlert({
          severity: "success",
          title: "Group was successfully created!",
          message: `
            ${notificationRes?.payload?.data?.message || ""}
          `
        })
      );
    } else {
      const res = await dispatch(updateGroup(body));
      dispatch(getGroup());
      handleClose();

      if (res.error) return;

      const notificationRes = await dispatch(
        getNotificationsByProctoringType(group.typeProctoring)
      );

      dispatch(
        openAlert({
          severity: "success",
          title: "Group was successfully updated!",
          message: `
            ${notificationRes?.payload?.data?.message || ""}
          `
        })
      );
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={7}
      className={classes.rootRow}
    >
      <Grid
        item
        container
        direction="column"
        md={12}
        sm={12}
        lg={8}
        alignItems="center"
      >
        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Exams Groups:</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddExamGroup}
            style={{ margin: "1rem 0" }}
          >
            Add+
          </Button>
        </Grid>

        <CustomModal
          handleSubmit={handleSubmit}
          optionsLicense={selectOptions().optionsLicense}
          optionsTeacher={selectOptions().optionsTeacher}
          optionsExam={selectOptions().optionsExam}
          optionsProgram={selectOptions().optionsProgram}
          allProctors={allProctors}
          allStudents={allStudents}
          loading={loading}
          group={group}
          handleChange={handleFillFormData}
          mode={mode}
          handleClose={handleClose}
          open={open}
          className={getModalClass("EXAM_GROUP")}
          attempt={attempt}
        >
          {(props) => getModal("EXAM_GROUP", props)}
        </CustomModal>

        <CustomTable
          disabledCheckBox={true}
          idChanging={examGroupStatusIdChanging}
          statusUpdating={loading}
          handleClickRow={handleEditExamGroup}
          rows={allGroups}
          columns={columns}
          className={classes.rootTable}
          selectOpt={selectOpt}
          handleChangeSelect={handleChangeStatus}
        />
      </Grid>
    </Grid>
  );
}

export default Groups;
