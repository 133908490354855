import apiCall from "../api/api";
import { ENDPOINT } from "../constants/endpoints";

function getAllUserRoles() {
  return apiCall({
    url: `${ENDPOINT.user}`,
    method: "GET",
  });
}

function getUsersByRole(role) {
  return apiCall({
    url: `${ENDPOINT.user}?role=${role}`,
    method: "GET",
  });
}

function addUserToOrganization(body) {
  return apiCall({
    url: `${ENDPOINT.userRole}`,
    method: "POST",
    data: {
      ...body,
    },
  });
}

function updateUserRole(data) {
  const { role, id, organizationId } = data;
  return apiCall({
    url: `${ENDPOINT.user}/${id}/role`,
    method: "PATCH",
    data: {
      role: role,
      organizationId: organizationId,
    },
  });
}

function getAllUsersGroupAdditionalInfoByRole(roles) {
  return apiCall({
    url: `/user/exam-group-info?usersId[]=${JSON.stringify(roles)}`,
    method: "GET",
  });
}

function validateProctorLogin(body) {
  return apiCall({
    url: `${ENDPOINT.user}/validate-login-proctor`,
    method: "GET",
    params: {
      ...body,
    },
  });
}

const RolesService = {
  getAllUserRoles,
  addUserToOrganization,
  updateUserRole,
  getUsersByRole,
  getAllUsersGroupAdditionalInfoByRole,
  validateProctorLogin,
};

export default RolesService;
