import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateProctor } from "store/slices/roles";
import { makeStyles } from "@mui/styles";

import { Button, TextField, Grid, Typography, Container } from "@mui/material";

import { proctorVerifyKeys, checkEmail, checkPassword } from "utils/validation";

import { LINKS } from "constants/links";

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center"
  },
  loginTitle: {
    padding: "1rem 0"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 2)
  },
  errMessage: {
    color: "red"
  },
  btn: {
    cursor: "pointer",
    color: "#3f51b5",
    marginLeft: 16,
    textDecoration: "underline"
  }
}));

function ExamsProctorVerifi() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { exam } = useSelector((state) => state).examsSlice;
  const { loading, validProctor } = useSelector((state) => state).rolesSlice;

  const [attempt, setAttemt] = useState(false);
  const [values, setValues] = useState({ email: "", password: "" });

  const handleChange = useCallback(
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value
      });
    },
    [values]
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (
        checkEmail(values[proctorVerifyKeys.EMAIL.key]) ||
        checkPassword(values[proctorVerifyKeys.PASSWORD.key])
      ) {
        setAttemt(true);
        return;
      }
      dispatch(validateProctor(values));
    },
    [dispatch]
  );

  const handleGoToExam = useCallback(() => {
    navigate(`${LINKS.examWindow}/${exam.code}`);
  }, [ exam.code]);

  useEffect(() => {
    if (validProctor) {
      handleGoToExam();
    }
  }, [validProctor]); // eslint-disable-line

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h4">
          Proctor login
        </Typography>
        <Typography component="h2" variant="h6" className={classes.loginTitle}>
          If you are the assigned proctor for this group please insert your
          credentials
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => handleChange(e)}
            helperText={
              attempt && checkEmail(values[proctorVerifyKeys.EMAIL.key])
                ? checkEmail(values[proctorVerifyKeys.EMAIL.key])
                : ""
            }
            error={attempt && checkEmail(values[proctorVerifyKeys.EMAIL.key])}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => handleChange(e)}
            helperText={
              attempt && checkPassword(values[proctorVerifyKeys.PASSWORD.key])
                ? checkPassword(values[proctorVerifyKeys.PASSWORD.key])
                : ""
            }
            error={
              attempt && checkPassword(values[proctorVerifyKeys.PASSWORD.key])
            }
          />

          {validProctor === false && (
            <Grid container>
              <Grid item className={classes.errMessage}>
                <span>{"Error verify proctor. Try again!"}</span>
              </Grid>
            </Grid>
          )}

          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
            onClick={() => handleSubmit(values)}
          >
            Activate exam
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default ExamsProctorVerifi;
