import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSmeSelectedOption } from "../../store/slices/sme";
import { Grid, Typography, Button, Box } from "@mui/material";
import { Code, RateReview } from "@mui/icons-material";
import { LINKS } from "../../constants/links";
import { useStyles } from "./styles/Sme.styles";
import BackButton from "../../components/Buttons/BackButton";

function SmeActions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useStyles();
  const selectedExamCode = useSelector(
    (state) => state.smeSlice.selectedExam?.examCode
  );

  const handleOptionPick = async (selectedOption) => {
    await dispatch(setSmeSelectedOption(selectedOption));
    navigate(
      `${LINKS.smeTasksList}/list/${selectedOption}/${selectedExamCode}`
    );
  };

  const handleBackClick = () => {
    navigate(LINKS.smeExams, {replace: true});
  };

  useEffect(() => {
    if (!selectedExamCode) {
      // if user decided to reload page after exam selection
      navigate(LINKS.smeExams, {replace: true});
    }
  }, [selectedExamCode]);

  return (
    <>
      <BackButton buttonLabel="Select Exam" handleBackClick={handleBackClick} />
      <Box className={styles.boxMiddle}>
        <Grid container align="center" justifyContent="center">
          <Grid className={styles.continer} item xs={12}>
            <Typography align="center" variant="h5">
              <Box fontWeight="fontWeightBold">CHOOSE ACTION</Box>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              className={styles.margin}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleOptionPick("ScoreQuestions")}
            >
              <Code className={styles.icon} />
              Score Questions
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleOptionPick("ReviewQuestions")}
            >
              <RateReview className={styles.icon} />
              Review Questions
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SmeActions;
