import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RolesService from "../../services/roles.service";

export const initialState = {
  loading: false,
  hasErrors: false,
  allUsers: { admin: [], teacher: [], student: [], proctor: [] },
  successResponse: {},
  validProctor: null,
};

export const getAllUserRoles = createAsyncThunk(
  "roles/getAllUserRoles",
  async (role) => {
    if (role) {
      const res = await RolesService.getUsersByRole(role);
      return res.data;
    } else {
      const res = await RolesService.getAllUserRoles();
      return res.data;
    }
  }
);

export const addUserToOrganization = createAsyncThunk(
  "roles/addUserToOrganization",
  async (body) => {
    const res = await RolesService.addUserToOrganization(body);
    return res.data;
  }
);

export const updateUserRole = createAsyncThunk(
  "roles/updateUserRole",
  async (data) => {
    const res = await RolesService.updateUserRole(data);
    // await RolesService.getAllUserRoles();
    return res.data;
  }
);

export const validateProctor = createAsyncThunk(
  "roles/validateProctor",
  async (data, { getState }) => {
    const { group } = getState().groupSlice;

    const res = await RolesService.validateProctorLogin({
      ...data,
      examGroupId: group.examId,
    });

    return res.data;
  }
);

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: {
    //pending
    [getAllUserRoles.pending ||
    addUserToOrganization.pending ||
    updateUserRole.pending ||
    validateProctor.pending]: (state) => {
      state.loading = true;
      state.allUsers = { admin: [], teacher: [], student: [], proctor: [] };
      state.validProctor = null;
      state.hasErrors = false;
    },
    //fulfilled
    [getAllUserRoles.fulfilled]: (state, { payload }) => {
      payload.forEach((item) => {
        if (state.allUsers[item.role]) {
          return state.allUsers[item.role].push(item);
        }
      });
      state.loading = false;
      state.hasErrors = false;
    },
    [addUserToOrganization.fulfilled || updateUserRole.fulfilled]: (
      state,
      { payload }
    ) => {
      state.successResponse = payload;
      state.loading = false;
    },
    [validateProctor.fulfilled]: (state) => {
      state.validProctor = true;
      state.loading = false;
    },
    //rejected
    [getAllUserRoles.rejected ||
    addUserToOrganization.rejected ||
    updateUserRole.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [validateProctor.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.validProctor = false;
    },
  },
});

export const updateRoleAndFetch = createAsyncThunk(
  `roles/updateRoleAndFetch`,
  (data, { dispatch }) => {
    dispatch(updateUserRole(data)).then(() => dispatch(getAllUserRoles()));
  }
);

export default rolesSlice.reducer;
